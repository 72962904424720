import { all, call, put, takeEvery } from "redux-saga/effects";
import { ssoApi } from "../../axios/interceptor";

import {
  OtpActionTypes,
  fetchOtpSuccess
} from "./otp-actions";

export function* FetchOtpAsync(action) {
  try {
    const response = yield call(
      ssoApi.get,
      `/account/verifyotp?code=${action.payload}`
    );
    yield put(fetchOtpSuccess(response));
  } catch (err) {
  }
}

export function* WatchFetchOtpflow() {
  yield takeEvery(
    OtpActionTypes.VALIDATE_OTP,
    FetchOtpAsync
  );
}

export default function* otpSagas() {
  yield all([call(WatchFetchOtpflow)]);
}
