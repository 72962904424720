import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import ViewContent from "../viewClient/viewContent/viewContent";
import { Colors } from "../../../utils/colors";
import Button from "../../shared/button/Button";
import { subscriber } from "../../../axios/interceptor";
import { ErrorHandler } from "../../../utils/errorHandler";
import "./previewClient.scss";

const PreviewClient = ({ openModal, closeModal, select, action, setModalShow }) => {
  console.log({ openModal, closeModal, select, action });
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClose = () => {
    setShow(false);
    setModalShow(false);
  };
  const handleShow = () => setShow(true);

  const client = useSelector((state) => {
    return {
      isLoading: state.clients && state.clients.isCreatLoading,
      status: state.clients && state.clients && state.clients.newClient && state.clients.newClient.status,
    };
  });

  const { isLoading, status } = client;

  subscriber.subscribe((val) => {
    const value = val ? ErrorHandler(val) : "";
    !errorMessage && errorMessage !== 0 && setErrorMessage(val);
    if (value !== "" && value !== message) {
      setMessage(value);
      handleClose();
    }
  });

  useEffect(() => {
    openModal && handleShow();
  }, [openModal]);

  useEffect(() => {
    if (!isLoading && status && openModal) {
      handleClose();
    }
  }, [isLoading]);
  return (
    <Modal centered show={show} size="lg" onHide={handleClose} className="modal-container">
      <Modal.Header closeButton>
        <div className="title">Preview Client Details</div>
      </Modal.Header>
      <Modal.Body>
        <ViewContent select={select} />
      </Modal.Body>
      <Modal.Footer className="">
        <Button size="sm" color={Colors.GREEN} handleClick={() => action()} loading={client.isLoading} disabled={client.isLoading}>
          Import Client
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewClient;
