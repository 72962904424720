import React from "react";
import { Spinner } from "reactstrap";
import "./button.scss";

/**
 *
 * @param {handleClick, children, loding, color, size, icon, ouline} props
 * @handleClick returns button event
 * @children: button text default undefined
 * @loading: boolean default undefined
 * @color: blue | green | purple | orange | destructive
 * outline: boolean default false
 * icon: Any supported fontawesome 4 icon refrence e.g. spinner defaule undefined
 * size: lg | md | sm. Default sm
 */

const Button = (props) => {
  const {
    type,
    handleClick,
    children,
    loading,
    color,
    size,
    ghost,
    icon,
    outline,
    disabled,
    className,
  } = props;

  return (
    <button
      type={type ? type : "button"}
      onClick={handleClick}
      className={`sp-button sp-button--${size ? size : "sm"} ${
        ghost && "sp-button--ghost-" + (color ? color : "green")
      }
        ${
          outline
            ? "sp-button--outline-" + (color ? color : "green")
            : "sp-button--primary-" + (color ? color : "green")
        } ${className}`}
      disabled={disabled}
    >
      {icon && <i className={`fa fa-${icon}`}></i>} &nbsp;
      {children} &nbsp;{" "}
      {loading && (
        <Spinner
          size="sm"
          color={`${outline ? (color ? color : "green") : "light"}`}
        />
      )}
    </button>
  );
};

export default Button;
