import React from "react";
import { Spinner, Table } from "reactstrap";
import "./userClearance.scss";

const UserClearance = ({ clearance, handleEnable, processing, itemId }) => {
  return (
    <div className="user-clearance-container">
      <div className="row">
        <div className="col-sm-12">
          <Table responsive borderless>
            <thead>
              <tr>
                <th width="50%">Client Name</th>
                <th width="20%">Client ID</th>
                <th width="20%">Enabled</th>
                <th width="10%">...</th>
              </tr>
            </thead>
            <tbody>
              {clearance && clearance.length ? (
                clearance.map((item, idx) => (
                  <tr key={idx}>
                    <td width="50%">{item.ClientName}</td>
                    <td width="20%">{item.ClientId}</td>
                    <td width="20%">{item.IsEnabled ? "YES" : "NO"}</td>
                    <td width="10%">
                      {/* <Button
                        size="sm"
                        color={item.IsEnabled ? "destructive" : "green"}
                        handleClick={(e) => handleEnable(e, item)}
                      >
                        {item.IsEnabled ? "Disable" : "Enable"}
                      </Button> */}
                      <a href="#/" className={item.IsEnabled ? "action-d" : "action-e"} onClick={e => handleEnable(e, item)}>
                        {processing && item.ClearanceId === itemId ? <Spinner size="sm" /> : item.IsEnabled ? "Disable" : "Enable"}
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="font-weight-bold text-center pt-4">
                    Not data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default UserClearance;
