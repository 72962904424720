import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useDispatch, useSelector } from 'react-redux';

import { createBusinessUnit, fetchBusinessUnit } from '../../../store/applications/applications-actions';
import { Colors } from '../../../utils/colors';
import Button from "../../shared/button/Button";
import './businessNameForm.scss';

const ApplicationForm = ({ openModal, closeModal }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [bUForm, setBUForm] = useState({});
   
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submitBUForm = (event, value) => {
        dispatch(createBusinessUnit(value));
    }

    const select = useSelector((state) => {
        return {
            status: state.application && state.application.newBusinessUnit && state.application.newBusinessUnit.status,
            isLoading: state.application && state.application.isBULoading,
        }
    })

    useEffect(() => {
        if (select.status && select.status === 200) {
            dispatch(fetchBusinessUnit());
            bUForm.reset && bUForm.reset();
            closeModal();
           }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [select.status])

    useEffect(() => {
        openModal ? handleShow() : handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal])


    return (
        <>
            <Modal
                centered
                show={show}
                onHide={closeModal}
                className="sbu-modal-container">
                <Modal.Header closeButton>
                    New Business Unit
                </Modal.Header>
                <Modal.Body>
                    <AvForm onValidSubmit={submitBUForm} ref={c => (setBUForm(c))}>
                        <div className="row">
                            <div className="form-group col-12">
                            <label htmlFor="BusinessUnit">Businesss Unit Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="SbuName"
                                    id="BusinessUnit"
                                    placeholder="New Business Unit"
                                    validate={{
                                        required: { value: true, errorMessage: 'Enter a Business Unit' },
                                    }} />
                            </div>
                            </div>
                     </AvForm>
                     <div className="d-flex justify-content-end pr-3">
                      <Button
                            size="sm"
                            outline={true}
                            color={Colors.GREEN}
                            handleClick={() => {closeModal()}}
                            className="mr-2"
                        >
                           Cancel
                         </Button>
                        <Button size="sm" color={Colors.GREEN} handleClick={() => bUForm.submit()} loading={select.isLoading}  disabled={select.isLoading}>
                            Create
                        </Button>
                        </div>
                </Modal.Body>
            
            </Modal>
        </>
    );
}

export default ApplicationForm;