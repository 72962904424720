import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import clientIdentity from "../../utils/user-manager";
import "./logging-out.scss";

const LoggingOut = () => {
  const _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current) {
      clientIdentity.signoutRedirectCallback().then(() => {
        clientIdentity.removeUser();
        localStorage.removeItem("user");
        localStorage.removeItem("user_claim");
        localStorage.removeItem("user_profile");
        localStorage.removeItem("initialTime");
      });
    }

    return () => {
      _isMounted.current = false;
    };
  });

  return (
    <div className="d-flex justify-content-center align-items-center auth-callback">
      <div className="main">
        <div className="container text-center pt-5">
          <p className="mb-3">Signing you out...</p>
          <div className="container-item">
            <BeatLoader loading color="#5fbeaa" size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggingOut;
