import React from 'react';

import './scopeDetails.scss';
const ScopeDetails = ({ details }) => {
    const { Name, DisplayName, Description, ClaimsRule, Emphasize, Enabled, IncludeAllClaimsForUser, Required, ShowInDiscoveryDocument } = details;
    return (
        <div className='scope-details-container'>
            <table className="table">
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>{Name}</td>
                    </tr>
                    <tr>
                        <td>Display Name</td>
                        <td>{DisplayName}</td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>{Description}</td>
                    </tr>
                    <tr>
                        <td>Claims Rule</td>
                        <td>{ClaimsRule}</td>
                    </tr>
                    <tr>
                        <td>Emphasize</td>
                        <td>{Emphasize && Emphasize.toString()}</td>
                    </tr>
                    <tr>
                        <td>Enabled</td>
                        <td>{Enabled && Enabled.toString()}</td>
                    </tr>
                    <tr>
                        <td>Include All Claims</td>
                        <td>{IncludeAllClaimsForUser && IncludeAllClaimsForUser.toString()}</td>
                    </tr>
                    <tr>
                        <td>Required</td>
                        <td>{Required && Required.toString()}</td>
                    </tr>
                    <tr>
                        <td>Show In Discovery Document</td>
                        <td>{ShowInDiscoveryDocument && ShowInDiscoveryDocument.toString()}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>Identity</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ScopeDetails;