import { all, call, put, takeEvery } from "redux-saga/effects";
import { ssoApi } from "../../axios/interceptor";
import ShowToast from '../../utils/showToast';

import {
  ScopesActionTypes,
  fetchScopesSuccess,
  createScopeSuccess,
  updateScopeSuccess
} from "./scopes-actions";

export function* FetchScopesAsync() {
  try {
    const response = yield call(
      ssoApi.get,
      "/scope/list"
    );
    yield put(fetchScopesSuccess(response));
  } catch (err) {
  }
}

export function* WatchFetchScopesflow() {
  yield takeEvery(
    ScopesActionTypes.FETCHING_DATA,
    FetchScopesAsync
  );
}

export function* CreateScopeAsync(action) {
  try {
    const response = yield call(
      ssoApi.post,
      "/scope/create",
      action.payload
    );
    if (!response.data.isError) {
      yield ShowToast({
        type: 'success',
        message: 'Record saved successfully',
      });
    }
    yield put(createScopeSuccess(response));
  } catch (err) {
  }
}

export function* WatchCreateScopeflow() {
  yield takeEvery(
    ScopesActionTypes.CREATE_SCOPE,
    CreateScopeAsync
  );
}

export function* UpdateScopeAsync(action) {
  try {
    const response = yield call(
      ssoApi.post,
      `/scope/update?id=${action.payload.id}`,
      action.payload.payload
    );
    if (!response.data.isError) {
      yield ShowToast({
        type: 'success',
        message: 'Record updated successfully',
      });
    }
    yield put(updateScopeSuccess(response));
  } catch (err) {
  }
}

export function* WatchUpdateScopeflow() {
  yield takeEvery(
    ScopesActionTypes.UPDATE_SCOPE,
    UpdateScopeAsync
  );
}


export default function* scopeSagas() {
  yield all([call(WatchFetchScopesflow), call(WatchCreateScopeflow), call(WatchUpdateScopeflow)]);
}
