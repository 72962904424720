import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import _debounce from 'lodash.debounce';
import { Button, Paginator, ShimmerTableRowLoader } from '../shared';
import { FetchAllUsersData, FetchUserDetailsSuccess } from '../../store/user/user-actions';
import Alphabet from '../../constants/alphabet';
import { Urls, UserViewUrl } from '../../utils/urls';
import { useTitle } from '../../utils/useTitle';
import './users.scss';
import { Input } from 'antd';

const { Search } = Input;

const Users = () => {
  useTitle('Users');
  const history = useHistory();
  const dispatch = useDispatch();
  const { fetchingData, allUsers, pagingData } = useSelector(state => state.user);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [letter, setLetter] = useState('');

  useEffect(() => {
    dispatch(FetchAllUsersData({ perPage, pageNumber, letter, searchTerm }));
  }, [pageNumber, perPage, letter, searchTerm]);

  const handleCreateUser = e => {
    e.preventDefault();
    history.push(Urls.NewUser);
  };

  const handleViewUser = (e, details) => {
    e.preventDefault();
    history.push(UserViewUrl(details.Id));
    dispatch(FetchUserDetailsSuccess(details));
  };

  const loadOptions = _debounce((event, ctx, input, cb) => {
    setSearchTerm(event.target.value);
    // cb(true);
  }, 1000);
  // const loadOptions = value => {
  //   console.log(value);
  // };
  const handleItemsPerPage = e => {
    e.preventDefault();
    const { value } = e.target;
    setPageNumber(1);
    setPerPage(value);
  };

  const handlePageClick = data => {
    let selected = data.selected + 1;
    setPageNumber(selected);
  };

  const handleLetterClick = (e, value) => {
    e.preventDefault();
    setLetter(value);
  };
  const search = rows => {
    const columns = ['FullName', 'UserName', 'Email', 'PhoneNumber'];
    if (!selectedRow) {
      return rows.filter(row =>
        columns.some(column => {
          return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
        })
      );
    }
    return rows.filter(row => row[`${selectedRow}`].toString().toLowerCase().indexOf(searchTerm) > -1);
  };

  return (
    <section className='row users-container'>
      <div className='col'>
        <div className='d-flex flex-column justify-content-center align-items-start  flex-md-row justify-content-md-between'>
          <div className='sp-disp-heading mb-3 mb-md-0 font-weight-bold'>User Management</div>
          <div className='align-self-end'>
            <Button size='sm' color='green' handleClick={handleCreateUser}>
              New User
            </Button>
          </div>
        </div>
        <div className='card card-content'>
          <div className='card-body'>
            <div className='d-flex flex-column align-items-end justify-conten-center flex-md-row justify-content-md-between align-items-md-center mb-3'>
              <div className='mb-3 mb-md-0'>
                <Search placeholder='Search by Email' allowClear onChange={loadOptions} style={{ maxWidth: 320 }} />
                {/* <div className='input mb-2 mb-md-0'>
                  <input className='input__text input__search' type='text' placeholder='Search' onChange={loadOptions} />
                  <button className='input__btn'>
                    <i className='ri-search-line'></i>
                  </button>
                </div> */}
              </div>
              <div className='d-flex align-items-center'>
                <span className='mr-2'>showing:</span>
                <select className='input__text input__select p-0' value={perPage} onChange={handleItemsPerPage}>
                  <option value={5}>5 Entries</option>
                  <option value={10}>10 Entries</option>
                  <option value={20}>20 Entries</option>
                  <option value={30}>30 Entries</option>
                  <option value={40}>40 Entries</option>
                </select>
              </div>
            </div>
            <div className='list-group list-group-horizontal mb-3 w-100 no-radius'>
              {Alphabet &&
                Alphabet.map((item, idx) => (
                  <a href='#' className={`list-group-item list-group-item-action ${letter === item.value ? 'active-item' : ''}`} key={idx} onClick={e => handleLetterClick(e, item.value)}>
                    <span>{item.label}</span>
                  </a>
                ))}
            </div>
            <div className='row table-container'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {fetchingData ? (
                  <ShimmerTableRowLoader rows={perPage} columnWidths={['40%', '15%', '15%', '15%', '15%']} />
                ) : (
                  <tbody>
                    {search(allUsers).length ? (
                      search(allUsers).map((value, i) => {
                        return (
                          <tr key={value.Id}>
                            <td width='40%'>{value.FullName}</td>
                            <td width='15%'>{value.UserName}</td>
                            <td width='15%'>{value.Email}</td>
                            <td width='15%'>{value.PhoneNumber}</td>
                            <td width='15%'>
                              <a href='#/' className='action' onClick={e => handleViewUser(e, value)}>
                                View
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan='5' className='font-weight-bold text-center pt-4'>
                          Not data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </Table>
            </div>
            <Paginator currentPage={pagingData.PageNo} perPage={pagingData.PageSize} pageCount={pagingData.PageCount} totalItems={pagingData.TotalRecordCount} handlePageClick={handlePageClick} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Users;
