import React, { useState, useEffect } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';

const ClientClaim = ({ title, claims, singleResult }) => {
    const [clientClaim, setClientClaim] = useState([]);
    const [form, setForm] = useState([]);
    const [myClaim, setMyClaim] = useState({});

    const submitClaim = (event, values) => {
        const result = [...clientClaim, values]
        setClientClaim(result);
        singleResult(title, result);
        setMyClaim({});
        form.reset();
    }

    const remove = (claim) => {
        const index = clientClaim.indexOf(claim);
        clientClaim.splice(index, 1);
        setClientClaim(clientClaim);
    }

    const edit = (claim) => {
        setTimeout(() => form.reset(), 30);
        setMyClaim(claim);
        remove(claim)
    }

    useEffect(() => {
        setClientClaim(claims);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <AvForm className="row" onValidSubmit={submitClaim} ref={c => (setForm(c))} model={myClaim}>
                <div className="form-group col">
                    <label htmlFor="name">Type</label>
                    <AvField
                        type="text"
                        className="form-control"
                        name="Type"
                        id="name"
                        placeholder="Enter Type"
                        validate={{
                            required: { value: true, errorMessage: 'Please enter Type' },
                        }} />
                </div>
                <div className="form-group col">
                    <label htmlFor="name">Value</label>
                    <AvField
                        type="text"
                        className="form-control"
                        name="Value"
                        id="Value"
                        placeholder="Enter Value"
                        validate={{
                            required: { value: true, errorMessage: 'Please enter Value' },
                        }} />
                </div>
                <div className="col-auto d-flex align-items-center">
                    <button type="submit"
                        className="btn btn-sm btn-success">
                        Add Claim
                                                    </button>
                </div>
            </AvForm>
            {clientClaim && clientClaim.length > 0 && <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Value</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientClaim.map((value, i) => {
                            return (
                                <tr key={i}>
                                    <td>{value.Type}</td>
                                    <td>{value.Value}</td>
                                    <td>
                                        <button type="button"
                                            className="btn btn-sm btn-success mr-2"
                                            onClick={() => edit(value)}>
                                            <i className="fa fa-pencil"></i>
                                        </button>
                                        <button type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={() => remove(value)}>
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>}

        </>
    )
}

export default ClientClaim;