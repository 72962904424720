const { OnboardingActionTypes } = require("./onboarding-actions");
const initialState = {
  isLoading: false,
  verifyingEmail: false,
  disableButton: true,
  changeButton: false,
  emailVerificationFailed: false,
  userDetails: [],
  allApplicationsUsingRole: [],
  onboardResponse: null,
  allApplications: [],
  paginationData: [],
  successMsg: false,
  test: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OnboardingActionTypes.FETCHING_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case OnboardingActionTypes.FETCHING_APPS_USING_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allApplicationsUsingRole: payload,
      };

    case OnboardingActionTypes.FETCHING_ALL_APPLICATIONS_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case OnboardingActionTypes.FETCHING_ALL_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paginationData: payload,
        allApplications: payload.Data.Response.map((v) => ({
          clientName: v.ClientName,
          clientId: v.ClientId,
        })),
      };

    case OnboardingActionTypes.VERIFYING_EMAIL:
      return {
        ...state,
        verifyingEmail: true,
        disableButton: true,
        changeButton: false,
      };

    case OnboardingActionTypes.VERIFYING_EMAIL_SUCCESS:
      return {
        ...state,
        verifyingEmail: false,
        disableButton: false,
        changeButton: false,
        userDetails: payload,
      };

    case OnboardingActionTypes.VERIFYING_EMAIL_ERROR:
      return {
        ...state,
        verifyingEmail: false,
        disableButton: false,
        changeButton: true,
      };

    case OnboardingActionTypes.ONBOARD_TO_APPLICATION:
      return {
        ...state,
        isLoading: true,
        showSuccess: false,
      };

    case OnboardingActionTypes.ONBOARD_TO_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onboardResponse: payload,
        showSuccess: true,
      };

    case OnboardingActionTypes.ONBOARD_TO_APPLICATION_ERROR:
      return {
        ...state,
        isLoading: false,
        onboardErrorResponse: payload,
        showSuccess: false,
      };

    case OnboardingActionTypes.RESET_BUTTON:
      return {
        ...state,
        disableButton: true,
        changeButton: false,
      };

    default:
      return state;
  }
};

export default reducer;
