import React from 'react';
import { Table } from 'reactstrap';
import './claimApplications.scss';

const ClaimApplications = ({ applications }) => {
  return (
    <div className='user-application-container'>
      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <Table responsive borderless>
            <tbody>
              {applications.length ? (
                applications.map((application) => (
                  <tr key={`${application.Type}${application.Value}`}>
                    <td width='30%' className='user-label'>
                      {application.Type}
                    </td>
                    <td width='70%' className='user-value'>
                      {application.Value || '---'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='2' className='font-weight-bold text-center pt-4'>
                    Not data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ClaimApplications;
