import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import NavLinkWrapper from "./navLink/navLink";
import { Urls } from "../../../utils/urls";
import { validateRole, ROLENAMES } from "../../shared/roleClaim";
import sidebarIcons from "./sidebarIcons";
import "./sideBar.scss";

const Sidebar = ({ collapse, setOnSideBarHover }) => {
  const history = useHistory();
  const [menu, setMenu] = useState(history.location.pathname.substr(1));

  const [subNavItem, setSubNavItem] = useState({ item: "", openSubNav: false });

  useEffect(() => {
    const pathname = history.location.pathname.substr(1).split("/");
    setMenu(pathname[0]);
  }, []);

  const openMenu = (event) => {
    if (event === subNavItem.item && subNavItem.openSubNav) {
      setSubNavItem({ item: event, openSubNav: false });
    } else {
      setSubNavItem({ item: event, openSubNav: true });
    }
  };

  // define roles that can be created based on logged in user
  const defineOnboardSidebar = (input) => {
    const { SSOADMIN, VGGSUPERADMIN } = ROLENAMES;
    switch (input) {
      case SSOADMIN:
        return "Onboard Superadmin";
      case VGGSUPERADMIN:
        return "Onboard Vgg Admin";
      default:
        return "Onboard";
    }
  };

  const pathname = history.location.pathname;

  const { SSOADMIN, VGGSUPERADMIN, VGGADMIN, CLIENTADMIN, CLIENTUSER } = ROLENAMES;
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const absoluteOnCollapse = { position: collapse ? "absolute" : "relative" };
  return (
    <nav className="sidebar-container" style={{ width: collapse ? "45px" : "100%" }}>
      <div className="nav-bar">
        <ul>
          {validateRole(SSOADMIN, VGGSUPERADMIN, VGGADMIN) && (
            <NavLinkWrapper item="Dashboard" to={Urls.Dashboard} icon={sidebarIcons.dashboard(menu)} collapse={collapse} openMenu={openMenu} setOnSideBarHover={setOnSideBarHover} />
          )}
          {validateRole(VGGSUPERADMIN, SSOADMIN) && (
            <NavLinkWrapper
              item="Onboarding"
              icon={sidebarIcons.onboarding(menu)}
              collapse={collapse}
              openMenu={openMenu}
              setOnSideBarHover={setOnSideBarHover}
              dropdown={true}
              subNavInfo={subNavItem}
              className={pathname.indexOf("onboarding") > -1 ? "active" : ""}
            >
              <ul className="nav-sub-item animated fadeIn" style={absoluteOnCollapse}>
                <li onClick={() => history.push(Urls.Onboarding)}>{defineOnboardSidebar(userDetails.profile && userDetails.profile["ssoui.role"])}</li>
                <li onClick={() => history.push(Urls.Authorization)}>Client Authorization</li>
              </ul>
            </NavLinkWrapper>
          )}

          {validateRole(SSOADMIN, VGGSUPERADMIN, VGGADMIN) && (
            <NavLinkWrapper
              item="Maker Checker"
              icon={sidebarIcons.makerChecker(menu)}
              collapse={collapse}
              openMenu={openMenu}
              setOnSideBarHover={setOnSideBarHover}
              dropdown={true}
              subNavInfo={subNavItem}
              className={pathname.indexOf("maker-checker") > -1 || pathname.indexOf("workflows/transactions") > -1 ? "active" : ""}
            >
              <ul className="nav-sub-item animated fadeIn" style={absoluteOnCollapse}>
                <li onClick={() => history.push(Urls.MakerCheckerApplications)}>Applications</li>
                <li onClick={() => history.push(Urls.MakerChecker)}>Workflows</li>
                <li onClick={() => history.push(Urls.WorkflowTransactions)}>Transactions</li>
              </ul>
            </NavLinkWrapper>
          )}

          {validateRole(SSOADMIN, VGGSUPERADMIN, VGGADMIN) && (
            <NavLinkWrapper item="Applications" icon={sidebarIcons.applications(menu)} to={Urls.Applications} collapse={collapse} openMenu={openMenu} setOnSideBarHover={setOnSideBarHover} />
          )}
          {validateRole(SSOADMIN, VGGSUPERADMIN, VGGADMIN) && (
            <NavLinkWrapper item="Users" icon={sidebarIcons.users(menu)} to={Urls.Users} collapse={collapse} openMenu={openMenu} setOnSideBarHover={setOnSideBarHover} />
          )}
          {validateRole(SSOADMIN, VGGSUPERADMIN, VGGADMIN) && (
            <NavLinkWrapper item="Clients" icon={sidebarIcons.clients(menu)} to={Urls.Clients} collapse={collapse} openMenu={openMenu} setOnSideBarHover={setOnSideBarHover} />
          )}
          {validateRole(SSOADMIN, VGGSUPERADMIN, VGGADMIN) && (
            <NavLinkWrapper item="Scopes" icon={sidebarIcons.scopes(menu)} to={Urls.Scopes} collapse={collapse} openMenu={openMenu} setOnSideBarHover={setOnSideBarHover} />
          )}
          {validateRole(SSOADMIN, VGGSUPERADMIN, VGGADMIN, CLIENTADMIN, CLIENTUSER) && (
            <NavLinkWrapper item="Audit" icon={sidebarIcons.audit(menu)} to={Urls.Audit} collapse={collapse} openMenu={openMenu} setOnSideBarHover={setOnSideBarHover} />
          )}
          {validateRole(SSOADMIN, VGGSUPERADMIN, VGGADMIN, CLIENTADMIN, CLIENTUSER) && (
            <NavLinkWrapper item="Access logs" icon={sidebarIcons.accessLog(menu)} to={Urls.AccessLogs} collapse={collapse} openMenu={openMenu} setOnSideBarHover={setOnSideBarHover} />
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
