import React from 'react';
import './makerChip.scss';

const MakerChip = ({ children, onRemove }) => {
  return (
    <div className='custom-chip-container d-flex justify-content-center align-items-center mr-2 my-1'>
      <span className='value'>{children} </span>
      <span
        className='d-flex justify-content-center align-items-center ml-2 remove'
        onClick={onRemove}
      >
        <i className='ri-close-line'></i>
      </span>
    </div>
  );
};

export default MakerChip;
