import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import './claimEditorModal.scss';
import ClaimEditor from './claimEditor/claimEditor';
import { Button } from '../../../../shared';
import { AddUserClaim, FetchApplicationList, SetActionFor } from '../../../../../store/user/user-actions';
import OtpModal from '../../../../shared/otpModal/otpModal';

const ClaimEditorModal = ({ openModal, closeModal, UserId }) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});

  const [show, setShow] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const [showOtpModal, setShowOtpModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { applicationList, fetchingApplicationList, processing, actionFor } = useSelector(state => state.user);

  const singleResult = (title, myValue) => {
    setFormValues({ ...formValues, [title]: myValue });
  };

  const onValidateOtp = () => {
    if (actionFor === 'addClaim') {
      dispatch(AddUserClaim({ formValues, closeModal, selectedClient }));
    }
  };

  const handleApplicaitonSelect = e => {
    const { value } = e.target;
    const selectedClient = applicationList.find(v => v.ClientName === value);
    setSelectedClient(selectedClient);
  };

  const handleAddClaimSubmit = () => {
    dispatch(SetActionFor('addClaim'));
    setShowOtpModal(true);
  };

  useEffect(() => {
    dispatch(FetchApplicationList());
    setFormValues({ ...formValues, UserId });
  }, []);

  useEffect(() => {
    openModal ? handleShow() : handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <OtpModal top openModal={showOtpModal} closeModal={() => setShowOtpModal(false)} onValidateOtp={onValidateOtp} />
      <Modal show={show} onHide={closeModal} className='application-modal-container'>
        <Modal.Header closeButton>New Claim</Modal.Header>
        <Modal.Body>
          <ClaimEditor singleResult={singleResult} applicationList={applicationList} closeModal={closeModal} loading={fetchingApplicationList} handleApplicaitonSelect={handleApplicaitonSelect} />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button outline={true} color='orange' handleClick={closeModal} className='mr-2'>
              Cancel
            </Button>
            <Button
              color='green'
              handleClick={handleAddClaimSubmit}
              loading={processing}
              // disabled={!(formValues.ClientIds && formValues.ClientIds.length)}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClaimEditorModal;
