import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { Table } from "reactstrap";
import _debounce from "lodash.debounce";

import { createClient } from "../../store/clients/clients-actions";
import { fetchClients, clientDetails } from "../../store/clients/clients-actions";
import { LoadingTableContent } from "../shared/loader/Loader";
import { Colors } from "../../utils/colors";
import Button from "../shared/button/Button";
import OtpModal from "../shared/otpModal/otpModal";
import { Urls, ViewClientPage, EditClientPage } from "../../utils/urls";
import PreviewClient from "./previewClient/previewClient";
import { Paginator } from "../shared";
import ShowToast from "../../utils/showToast";
import { useTitle } from "../../utils/useTitle";
import TimeConversion from "../shared/timeConvertion";
import "./clients.scss";

const Clients = () => {
  useTitle("Clients");
  const history = useHistory();
  const dispatch = useDispatch();
  const [otpShow, setOtpShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [preview, setPreview] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const inputFileEl = useRef(null);

  const clientIds = ["Authorization Code", "Implicit", "Hybrid", "Client Credentials", "Resource Owner", "Custom Grant", "Authorization Code With Proof Key", "Hybrid With Proof Key"];

  const select = useSelector((state) => {
    return {
      clientList: state.clients,
      clients: state.clients && state.clients.clients.data,
      isLoading: state.clients && state.clients.isLoading,
    };
  });

  const viewClient = (details) => {
    history.push(ViewClientPage(details.Id));
    dispatch(clientDetails(details));
  };

  const editClient = (details) => {
    history.push(EditClientPage(details.Id));
    dispatch(clientDetails(details));
  };

  const searchByClientName = _debounce((value, ctx, input, cb) => {
    dispatch(fetchClients(pageNo, pageSize, value.target.value));
  }, 1000);

  const getClientData = () => {
    var file = document.querySelector("input[type=file]").files[0];
    var reader = new FileReader(); // File reader to read the file

    // This event listener will happen when the reader has read the file
    reader.addEventListener("load", function () {
      var result = JSON.parse(reader.result); // Parse the result into an object
      console.log({ result });
      if (result && result["Claims"] && result["ClientName"] && result["ClientId"]) {
        setPreview({ clients: result });
        setModalShow(true);
      } else {
        ShowToast({
          type: "error",
          message: "This is not a client file format",
        });
      }
    });
    file && reader.readAsText(file); // Read the uploaded file
  };

  const newClient = () => {
    history.push(Urls.CreateClient);
    dispatch(clientDetails(null));
  };

  const onSelectFile = () => {
    inputFileEl.current.click();
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    dispatch(fetchClients(selected + 1, pageSize));
  };

  useEffect(() => {
    dispatch(fetchClients(pageNo, pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (select.clients && select.clients.Data && select.clients.Data.Response.length > 0) {
      setPageNo(select.clients.PageNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.clients && select.clients.Data && select.clients.Data.Response]);

  return (
    <section className="row client-container ">
      {preview && <PreviewClient openModal={modalShow} closeModal={() => setModalShow(false)} select={preview} action={() => setOtpShow(true)} setModalShow={setModalShow} />}
      <OtpModal openModal={otpShow} closeModal={() => setOtpShow(false)} onValidateOtp={() => dispatch(createClient(preview && preview.clients))} />
      <div className="col">
        <div className="content-header d-flex justify-content-between">
          <span>Clients</span>
          <div className="sub-top-btn">
            <input type="file" ref={inputFileEl} accept="application/JSON" className="custom-file-input" onChange={getClientData} id="customFileLang" />

            <Button
              size="sm"
              outline={true}
              color={Colors.GREEN}
              handleClick={() => {
                onSelectFile();
              }}
              className="mr-2 button-action"
            >
              Import Client
            </Button>
            <Button size="sm" color={Colors.GREEN} handleClick={newClient} className="button-action">
              Add New Client
            </Button>
          </div>
        </div>
        <div className="card card-content">
          <div className="card-body">
            <div className="sp-pt--48 mb-2">
              <div className="input mb-md-0">
                <input className="input__text input__search" type="text" placeholder="Search by Client Name" onChange={searchByClientName} />
                <button className="input__btn">
                  <i className="ri-search-line"></i>
                </button>
              </div>
            </div>
            <div className="row table-container">
              <Table responsive>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Client Name</th>
                    <th scope="col">Client ID</th>
                    <th scope="col">Client Flow</th>
                    <th scope="col">Identity Token</th>
                    <th scope="col">Access Token</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {select.isLoading && <LoadingTableContent colSpan={7} />}
                <tbody>
                  {select.clients &&
                    !select.isLoading &&
                    select.clients.Data &&
                    select.clients.Data.Response.map((value, i) => {
                      return (
                        <tr key={value.Id}>
                          <th>{(pageNo - 1) * 10 + i + 1}</th>
                          <td>{value.ClientName}</td>
                          <td>{value.ClientId}</td>
                          <td>{clientIds[value.ClientFlow]}</td>
                          <td>{TimeConversion.secondsToMins(value.IdentityTokenLifetime)}</td>
                          <td>{TimeConversion.secondsToHrs(value.AccessTokenLifetime)}</td>
                          <td>
                            <span className="green-color cursor mr-2" onClick={() => viewClient(value)}>
                              {" "}
                              View
                            </span>
                            <span className="green-color cursor" onClick={() => editClient(value)}>
                              {" "}
                              Edit
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  {select.clients && select.clients.Data && select.clients.Data.Response.length === 0 && !select.isLoading && (
                    <tr>
                      <td style={{ display: "table-cell" }} colSpan={7} className="font-weight-bold text-center pt-4">
                        No Content Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {select.clients && select.clients.Data && (
              <Paginator currentPage={pageNo} perPage={pageSize} pageCount={select.clients.PageCount} totalItems={select.clients.TotalRecordCount} handlePageClick={handlePageClick} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
