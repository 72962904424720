import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ErrorHandler } from "../../../../utils/errorHandler";
import { subscriber } from "../../../../axios/interceptor";
import { Notification } from "../../../shared/notifications/notifications";
import { getToken } from "../../../../utils/userDetails";
import "./mainNavTop.scss";
import { HOST_URL, SSO_URL } from "../../../../constants/app-constant";

const MainNavTop = ({ handleSignout, userProfile, isCollapse }) => {
  const [collapse, setCollapse] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [lastNotiTime, setLastNotiTime] = useState("");
  const [screenWidth, setScreenWidth] = useState(0);

  const resize = () => {
    setScreenWidth(window.innerWidth);
  };
  window.addEventListener("resize", resize);

  // const hostName = window.location.hostname;
  // const envType = hostName.indexOf('localhost') > -1 || hostName.indexOf('test') > -1 ? 'test' : hostName.indexOf('staging') > -1 ? 'staging' : '';
  const accessToken = getToken();
  const actionUrl = `${SSO_URL}/googleauth`;
  // const hostURL = `https://identity.${envType}.vggdev.com`;

  isCollapse(collapse);

  subscriber.subscribe((val) => {
    const value = val ? ErrorHandler(val) : "";
    if (value !== "" && value !== message) {
      setLastNotiTime(new Date().getSeconds());
      setMessage(value);
      setType("error");
    }
  });

  useEffect(() => {
    if (message !== "") {
      toast.error(message, {
        position: "top-left",
        autoClose: 180000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastNotiTime]);

  useEffect(() => {
    window.innerWidth < 768 ? setCollapse(true) : setCollapse(false);
  }, [screenWidth]);

  const newHostUrl = HOST_URL.split("http").length > 1 ? HOST_URL : `https://${HOST_URL}`;
  return (
    <div className="main-nav-container">
      <nav className="navbar navbar-expand-sm top-navigation d-flex justify-content-between">
        <div>
          <i className="ri-menu-line pr-3 cursor" onClick={() => setCollapse(!collapse)}></i>
          <Link to="/" className="identity-server">
            Identity Server
          </Link>
        </div>
        <div>
          <span className="col error-notification">
            <Notification message={message} type={type} />
          </span>
          <span className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon">
              <i className="fa fa-user-circle fa-lg"></i>
            </span>
          </span>
          <div id="navbarCollapse" className="collapse navbar-collapse col-auto">
            <div className="navbar-nav ml-auto">
              <div className="nav-item dropdown">
                <Link to="/" onClick={(e) => e.preventDefault()} data-toggle="dropdown" className="nav-link dropdown-toggle">
                  <div className="user-profile">
                    <div className="user-name">{`${userProfile?.given_name} ${userProfile?.family_name}`}</div>
                    <div className="user-role">{userProfile && userProfile["ssoui.role"]}</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="fa fa-user-circle fa-lg"></i>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-position">
                  <Link to="/profile" className="dropdown-item">
                    <i className="fa fa-user-o"></i> Profile
                  </Link>
                  <a
                    target="_blank"
                    onClick={() => {
                      document.getElementById("googleAuthForm").submit();
                    }}
                    className="dropdown-item"
                  >
                    <i className="fa fa-calendar-o"></i> Google Authentication
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="/" onClick={handleSignout} className="dropdown-item">
                    <i className="fa fa-power-off"></i> Logout
                  </a>
                </div>

                <form id="googleAuthForm" action={actionUrl} method="post">
                  <input name="access_token" value={accessToken} type="hidden" className="form-control" />
                  <input name="clientUrl" value={newHostUrl} type="hidden" className="form-control" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MainNavTop;
