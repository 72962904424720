import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingTableContent } from "../../shared/loader/Loader";

import { useHistory } from "react-router-dom";
import { fetchAllApplications } from "../../../store/onboarding/onboarding-actions";
import { Paginator } from "../../shared";
import { Table } from "reactstrap";
import OnboardModal from "../onboardModal/onboardModal";
import _debounce from "lodash.debounce";

const SsoAdminOnboardView = (props) => {
  const { onboardRole } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [modalShow, setModalShow] = React.useState(false);
  const [onboardAppName, setonboardAppName] = useState({});

  const select = useSelector((state) => {
    return {
      allApplications: state.onboarding && state.onboarding.allApplications,
      paginationData: state.onboarding && state.onboarding.paginationData,
      isLoading: state.onboarding && state.onboarding.isLoading,
    };
  });

  const handlePageClick = (data) => {
    let pageNo = data.selected + 1;
    dispatch(fetchAllApplications({ pageNo, pageSize }));
  };

  // search table
  const searchByClientName = _debounce((event, ctx, input, cb) => {
    let clientName = event.target.value;

    clientName === "" && setPageNo(1);
    dispatch(fetchAllApplications({ pageNo: "1", pageSize, clientName }));
  }, 1000);

  const handleItemsPerPage = (e) => {
    e.preventDefault();
    setPageSize(e.target.value);
  };

  const handleOnboarding = (details) => {
    setonboardAppName(details);
    setModalShow(true);
  };

  useEffect(() => {
    dispatch(fetchAllApplications({ pageNo, pageSize }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      select.paginationData &&
      select.paginationData.Data &&
      select.paginationData.Data.Response.length > 0
    ) {
      setPageNo(select.paginationData.PageNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    select.paginationData &&
      select.paginationData.Data &&
      select.paginationData.Data.Response,
  ]);

  return (
    <section className="row scope-container">
      <div className="col">
        <div className="content-header d-flex justify-content-between">
          <span>All manageable applications</span>
        </div>
        <OnboardModal
          openModal={modalShow}
          closeModal={() => setModalShow(false)}
          onboardAppName={onboardAppName}
          onboardRole={onboardRole}
          history={history}
        />

        <div className="card card-content">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              {/* <div className="col-7"> */}
              <div className="sp-pt--48">
                <div className="input">
                  <input
                    className="input__text"
                    type="text"
                    placeholder="Search Clients"
                    onChange={searchByClientName}
                  />
                  <button className="input__btn">
                    <i className="ri-search-line"></i>
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="mr-2">showing:</span>
                <select
                  className="input__text input__select p-0"
                  value={pageSize}
                  onChange={handleItemsPerPage}
                >
                  <option value={5}>5 Entries</option>
                  <option value={10}>10 Entries</option>
                  <option value={15}>15 Entries</option>
                  <option value={20}>20 Entries</option>
                </select>
              </div>
            </div>

            <div className="row table-container">
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    {/* <th>Client</th> */}
                    <th>Applications</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {select.isLoading && <LoadingTableContent colSpan={3} />}
                <tbody>
                  {select.allApplications &&
                    !select.isLoading &&
                    select.allApplications.length > 0 &&
                    select.allApplications.map((value, i) => {
                      // format string to capitalize first letter

                      let application =
                        value.clientId[0].toUpperCase() +
                        value.clientId.substr(1).toLowerCase();

                      return (
                        <tr key={i + 1}>
                          <td>{(pageNo - 1) * 10 + (i + 1)}</td>
                          {/* <td>{value.clientName}</td> */}
                          <td>{application}</td>
                          <th>
                            <span
                              className="green-color cursor mr-2"
                              onClick={() =>
                                handleOnboarding(application.toLowerCase())
                              }
                            >
                              Onboard
                            </span>
                          </th>
                        </tr>
                      );
                    })}
                  {select.paginationData &&
                    select.paginationData.Data &&
                    select.paginationData.Data.Response.length === 0 &&
                    !select.isLoading && (
                      <tr>
                        <td
                          style={{ display: "table-cell" }}
                          colSpan={3}
                          className="font-weight-bold text-center pt-4"
                        >
                          No Content Found
                        </td>
                      </tr>
                    )}
                </tbody>
              </Table>
            </div>
            {select.paginationData && select.paginationData.Data && (
              <Paginator
                currentPage={pageNo}
                perPage={pageSize}
                pageCount={select.paginationData.PageCount}
                totalItems={select.paginationData.TotalRecordCount}
                handlePageClick={handlePageClick}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SsoAdminOnboardView;
