import React from "react";

const Claim = ({ details }) => {
  const { Claims } = details;
  return (
    <div className="client-details-container">
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <td>Type</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody>
          {Claims &&
            Claims?.map((val) => {
              return (
                <tr key={val.Id}>
                  <td>{val.Type}</td>
                  <td>{val.Value}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Claim;
