import React, { useEffect, useState } from "react";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import BreadCrumb from "../../shared/breadCrumb/breadCrumb";
import ScopeClaim from "../../shared/scopeClaim/scopeClaim";
import ScopeSecret from "../../shared/secretForm/secretForm";
import Accordion from "../../shared/accordion/accordion";
import { PageTitle } from "../../shared/titlePage/titlePage";
import { createScope, updateScope, resetNewCreatedScope } from "../../../store/scopes/scopes-actions";
import OtpModal from "../../shared/otpModal/otpModal";
import { Urls } from "../../../utils/urls";
import { Colors } from "../../../utils/colors";
import Button from "../../shared/button/Button";
import { useTitle } from "../../../utils/useTitle";
import InfoTip from "../../shared/infoTip/infoTip";
import InfoTipText from "../../shared/infoTip/infoTipText";

import "./newScope.scss";

const NewScope = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form, setForm] = useState([]);
  const [subPage, setSubPage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [payload, setPayload] = useState({});

  const params = useParams();

  const scopeDetails = useSelector((state) => {
    return {
      scopes: state.scopes && state.scopes.details,
      isCreateLoading: state.scopes && state.scopes.isCreateLoading,
    };
  });
  let details = scopeDetails.scopes || {};

  const toBool = (val) => (val === "true" ? true : false);

  const select = useSelector((state) => {
    return { status: state.scopes && state.scopes.newScope && state.scopes.newScope.status };
  });
  useTitle(subPage);
  if (select.status && (select.status === 201 || select.status === 200)) {
    history.push(Urls.Scopes);
    dispatch(resetNewCreatedScope());
  }

  if (details && details["Name"]) {
    details["Emphasize"] = details["Emphasize"].toString();
    details["Enabled"] = details["Enabled"].toString();
    details["Required"] = details["Required"].toString();
    details["ShowInDiscoveryDocument"] = details["ShowInDiscoveryDocument"].toString();
    details["IncludeAllClaimsForUser"] = details["IncludeAllClaimsForUser"].toString();
  }

  // setting the default value of the radio button
  if (!params.id) {
    const boolValues = ["Emphasize", "IncludeAllClaimsForUser", "Required"];
    boolValues.forEach((val) => (details[val] = "false"));
    ["ShowInDiscoveryDocument", "Enabled"].forEach((val) => (details[val] = "true"));
    details["Type"] = 1;
  }

  // setting the default value of the radio button
  if (!params.id) {
    const boolValues = ["Emphasize", "IncludeAllClaimsForUser", "Required"];
    boolValues.forEach((val) => (details[val] = "false"));
    ["ShowInDiscoveryDocument", "Enabled"].forEach((val) => (details[val] = "true"));
    details["Type"] = 1;
  }

  const submitScope = (event, value) => {
    let result = null;
    if (params.id) {
      result = { ...details, ...value, ...formValues };
      result["Id"] = parseInt(params.id);
    } else {
      result = { ...formValues, ...value };
    }
    setFormValues(result);
    setPayload(result);
    setModalShow(true);
  };

  const onValidateOtp = () => {
    params.id ? dispatch(updateScope(params.id, payload)) : dispatch(createScope(payload));
  };

  const singleResult = (title, myValue) => {
    setFormValues({ ...formValues, [title]: myValue });
  };

  useEffect(() => {
    if (params.id) {
      if (details && details.Name) {
        setSubPage("Edit a Scope");
        setFormValues(details);
      } else {
        history.push(Urls.Scopes);
      }
    } else {
      form.reset && form.reset();
      setSubPage("Create a Scope");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      // form.reset && form.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="row new-scope-container">
      <OtpModal openModal={modalShow} closeModal={() => setModalShow(false)} onValidateOtp={onValidateOtp} />

      <div className="col">
        <BreadCrumb title="Scopes" titleLink={Urls.Scopes} sub1={subPage} />
        <div className="content-header">
          <PageTitle url={Urls.Scopes} title="Scopes" showArrow={true} />
        </div>

        <div className="card card-content">
          <div className="card-body">
            <AvForm className="row" onValidSubmit={submitScope} ref={(c) => setForm(c)} model={details}>
              <div className="form-group col-md-6">
                <label htmlFor="name">
                  Name <InfoTip infoText={InfoTipText.scopes.Name} />
                </label>
                <AvField
                  type="text"
                  className="form-control"
                  id="name"
                  name="Name"
                  placeholder="Enter Name"
                  validate={{
                    required: { value: true, errorMessage: "Please enter a name" },
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="display-name">
                  Display Name <InfoTip infoText={InfoTipText.scopes.DisplayName} />
                </label>
                <AvField
                  type="text"
                  className="form-control"
                  id="display-name"
                  name="DisplayName"
                  placeholder="Enter Display Name"
                  validate={{
                    required: { value: true, errorMessage: "Please enter a display name" },
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="description">
                  Description <InfoTip infoText={InfoTipText.scopes.Description} />
                </label>
                <AvField
                  type="textarea"
                  className="form-control"
                  id="description"
                  name="Description"
                  placeholder="Enter Description"
                  validate={{
                    required: { value: true, errorMessage: "Please enter a description" },
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="claims">
                  Claims Rule <InfoTip infoText={InfoTipText.scopes.ClaimsRule} />
                </label>
                <AvField
                  type="text"
                  className="form-control"
                  id="claims"
                  name="ClaimsRule"
                  placeholder="Enter Claims"
                  validate={{
                    required: { value: true, errorMessage: "Please enter a description" },
                  }}
                />
              </div>
              <AvRadioGroup className="form-group col-md-6" name="Emphasize" required errorMessage="Please select Emphasize">
                <div className="radio-label">
                  Emphasize <InfoTip infoText={InfoTipText.scopes.Emphasize} />
                </div>
                <div className="radio-content">
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="Yes" name="emphasize" id="emphasize-yes" value="true" />
                  </div>
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="No" name="emphasize" id="emphasize-no" value="false" />
                  </div>
                </div>
              </AvRadioGroup>

              <AvRadioGroup className="form-group col-md-6" name="Enabled" required errorMessage="Please select Enabled">
                <div className="radio-label">
                  Enabled <InfoTip infoText={InfoTipText.scopes.Enabled} />
                </div>
                <div className="radio-content">
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="Yes" name="enabled" id="enabled-yes" value="true" />
                  </div>
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="No" name="enabled" id="enabled-no" value="false" />
                  </div>
                </div>
              </AvRadioGroup>

              <AvRadioGroup className="form-group col-md-6" name="IncludeAllClaimsForUser" required errorMessage="Please select Claims">
                <div className="radio-label">
                  Include All Claims for User <InfoTip infoText={InfoTipText.scopes.IncludeAllClaimsForUser} />
                </div>
                <div className="radio-content">
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="Yes" type="radio" name="claims" id="claims-yes" value="true" />
                  </div>
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="No" type="radio" name="claims" id="claims-no" value="false" />
                  </div>
                </div>
              </AvRadioGroup>

              <AvRadioGroup className="form-group col-md-6" name="Required" required errorMessage="Please select Required">
                <div className="radio-label">
                  Required <InfoTip infoText={InfoTipText.scopes.Required} />
                </div>
                <div className="radio-content">
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="Yes" type="radio" name="required" id="required-yes" value="true" />
                  </div>
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="No" type="radio" name="required" id="required-no" value="false" />
                  </div>
                </div>
              </AvRadioGroup>
              <AvRadioGroup className="form-group col-md-6" name="ShowInDiscoveryDocument" required errorMessage="Please select Discovery Document">
                <div className="radio-label">
                  Show in Discovery Document <InfoTip infoText={InfoTipText.scopes.ShowInDiscoveryDocument} />
                </div>
                <div className="radio-content">
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="Yes" type="radio" name="discovery" id="discovery-yes" value="true" />
                  </div>
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="No" type="radio" name="discovery" id="discovery-no" value="false" />
                  </div>
                </div>
              </AvRadioGroup>

              <AvRadioGroup className="form-group col-md-6" name="Type" required errorMessage="Please select Type">
                <div className="radio-label">
                  Type <InfoTip infoText={InfoTipText.scopes.Type} />
                </div>
                <div className="radio-content">
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="Identity" type="radio" name="type" id="type-yes" value={0} />
                  </div>
                  <div className="form-check">
                    <AvRadio className="form-check-input" label="Resource" type="radio" name="type" id="type-no" value={1} />
                  </div>
                </div>
              </AvRadioGroup>
            </AvForm>
            <Accordion accordionLable="Scope Claims">
              <ScopeClaim tipText={InfoTipText.scopes.ScopeClaim} title="ScopeClaims" claims={details.ScopeClaims} singleResult={singleResult} />
            </Accordion>
            <Accordion tipText={InfoTipText.scopes.ScopeSecret} accordionLable="Scope Secrets">
              <ScopeSecret title="ScopeSecrets" secrets={details.ScopeSecrets} singleResult={singleResult} />
            </Accordion>

            <div className="row">
              <div className="col-12 text-center mt-4">
                <Button size="sm" color={Colors.GREEN} handleClick={() => form.submit()} loading={scopeDetails.isCreateLoading} disabled={scopeDetails.isCreateLoading}>
                  {params.id ? "Update Scope" : "Create Scope"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewScope;
