export const OnboardingActionTypes = {
  FETCHING_DATA: "FETCHING_APPS_WITH_ROLE",
  FETCHING_APPS_USING_ROLE_SUCCESS: "FETCHING_APPS_USING_ROLE_SUCCESS",
  FETCHING_ONBOARDING_ERROR: "FETCHING_ONBOARDING_ERROR",
  FETCHING_ALL_APPLICATIONS_DATA: "FETCHING_ALL_APPLICATIONS_DATA",
  FETCHING_ALL_APPLICATIONS_SUCCESS: "FETCHING_ALL_APPLICATIONS_SUCCESS",
  ONBOARD_TO_APPLICATION: "ONBOARD_TO_APPLICATION",
  ONBOARD_TO_APPLICATION_SUCCESS: "ONBOARD_TO_APPLICATION_SUCCESS",
  ONBOARD_TO_APPLICATION_ERROR: "ONBOARD_TO_APPLICATION_ERROR",
  VERIFYING_EMAIL: "VERIFYING_EMAIL",
  VERIFYING_EMAIL_SUCCESS: "VERIFYING_EMAIL_SUCCESS",
  VERIFYING_EMAIL_ERROR: "VERIFYING_EMAIL_ERROR",
  RESET_BUTTON: "RESET_BUTTON",
};

export const fetchAllApplicationsUsingRole = (payload) => ({
  type: OnboardingActionTypes.FETCHING_DATA,
  payload: payload,
});

export const fetchAllApplicationsUsingRoleSuccess = (payload) => ({
  type: OnboardingActionTypes.FETCHING_APPS_USING_ROLE_SUCCESS,
  payload: payload,
});

export const fetchAllApplications = (payload) => ({
  type: OnboardingActionTypes.FETCHING_ALL_APPLICATIONS_DATA,
  payload: payload,
});

export const fetchAllApplicationsSuccess = (payload) => ({
  type: OnboardingActionTypes.FETCHING_ALL_APPLICATIONS_SUCCESS,
  payload: payload,
});

export const verifyEmail = (payload) => ({
  type: OnboardingActionTypes.VERIFYING_EMAIL,
  payload: payload,
});

export const verifyEmailSuccess = (payload) => ({
  type: OnboardingActionTypes.VERIFYING_EMAIL_SUCCESS,
  payload: payload,
});

export const verifyEmailError = (payload) => ({
  type: OnboardingActionTypes.VERIFYING_EMAIL_ERROR,
  payload: payload,
});

export const onboardToApplication = (payload) => ({
  type: OnboardingActionTypes.ONBOARD_TO_APPLICATION,
  payload: payload,
});

export const onboardToApplicationSuccess = (payload) => ({
  type: OnboardingActionTypes.ONBOARD_TO_APPLICATION_SUCCESS,
  payload: payload,
});

export const onboardToApplicationError = (payload) => ({
  type: OnboardingActionTypes.ONBOARD_TO_APPLICATION_ERROR,
  payload: payload,
});

export const resetButton = () => ({
  type: OnboardingActionTypes.RESET_BUTTON,
});
