export const OtpActionTypes = {
  VALIDATE_OTP: "VALIDATE_OTP",
  VALIDATE_OTP_SUCCESS: "VALIDATE_OTP_SUCCESS",
  VALIDATE_OTP_ERROR: "VALIDATE_OTP_ERROR",
  CLEAR_OTP: "CLEAR_OTP"
};

export const fetchOtp = (code) => ({
  type: OtpActionTypes.VALIDATE_OTP,
  payload: code
});

export const fetchOtpSuccess = (payload) => ({
  type: OtpActionTypes.VALIDATE_OTP_SUCCESS,
  payload: payload,
});

export const clearOtp = () => ({
  type: OtpActionTypes.CLEAR_OTP
});
