const { toast } = require("react-toastify");

const toastConfig = {
  position: "top-left",
  autoClose: 180000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const ShowToast = ({ type, message }) => {
  switch (type.toLowerCase()) {
    case "success":
      return toast.success(message, toastConfig);
    case "error":
      return toast.error(message, toastConfig);
    case "info":
      return toast.info(message, toastConfig);
    case "warning":
      return toast.warning(message, toastConfig);
    default:
      break;
  }
};

export default ShowToast;
