import React, { useEffect, useState } from "react";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TimePicker } from "antd";
import moment from "moment";
import "antd/dist/antd.css";

// import ClientSecret from '../../shared/clientSecret/clientSecret';
import ClientClaim from "../../shared/clientClaim/clientClaim";
import ClientSecret from "../../shared/secretForm/secretForm";
import ClientForm from "../../shared/singleClientForm/singleClientForm";
import Accordion from "../../shared/accordion/accordion";
import OtpModal from "../../shared/otpModal/otpModal";
import { createClient, resetNewCreatedClient, updateClient } from "../../../store/clients/clients-actions";
import BreadCrumb from "../../shared/breadCrumb/breadCrumb";
import { Urls } from "../../../utils/urls";
import { PageTitle } from "../../shared/titlePage/titlePage";
import { Colors } from "../../../utils/colors";
import Button from "../../shared/button/Button";
import InfoTip from "../../shared/infoTip/infoTip";
import InfoTipText from "../../shared/infoTip/infoTipText";

import "./newClient.scss";

const NewClient = () => {
  const [form, setForm] = useState([]);
  const [subPage, setSubPage] = useState("");
  const [formValues, setFormValues] = useState({
    RedirectUris: [],
    PostLogoutRedirectUris: [],
    IdentityProviderRestrictions: [],
    ScopeClaims: [],
    ClientSecrets: [],
    AllowedScopes: [],
    AllowedCustomGrantTypes: [],
    AllowedCorsOrigins: [],
    CallBackNotificationUrl: [],
  });
  const [updateFormValues, setUpdateFormValues] = useState({});
  const [timeVal, setTimeVal] = useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [payload, setPayload] = React.useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const format = "HH:mm";

  const timeValues = ["AbsoluteRefreshTokenLifetime", "AccessTokenLifetime", "AuthorizationCodeLifetime", "IdentityTokenLifetime", "SlidingRefreshTokenLifetime"];

  const boolValues = [
    "EnableLocalLogin",
    "AllowAccessToAllGrantTypes",
    "AllowAccessToAllScopes",
    "AllowAccessTokensViaBrowser",
    "AllowClientCredentialsOnly",
    "AllowRememberConsent",
    "AlwaysSendClientClaims",
    "IncludeJwtId",
    "LogoutSessionRequired",
    "RequireConsent",
    "PrefixClientClaims",
    "RequireSignOutPrompt",
    "UpdateAccessTokenOnRefresh",
    "AllowAccessWithUnknownIP",
    "Enabled",
    "UpdateAccessTokenOnRefresh",
  ];

  const clientDetails = useSelector((state) => {
    return {
      clients: state.clients && state.clients.details,
      isLoading: state.clients && state.clients.isCreatLoading,
    };
  });

  let details = clientDetails.clients || {};

  //Convert seconds to HH:MM time format
  const secondsToHHMM = (SECONDS) => {
    var date = new Date(null);
    date.setSeconds(SECONDS); // specify value for SECONDS here
    var result = date.toISOString().substr(11, 8);
    return result;
  };

  if (!params.id) {
    const initialVal = {};
    boolValues.forEach((val) => (initialVal[val] = "false"));
    initialVal["EnableLocalLogin"] = "true";
    initialVal["AllowRememberConsent"] = "true";
    initialVal["PrefixClientClaims"] = "true";
    initialVal["ClientAccessTokenType"] = 0;
    initialVal["AllowAccessTokensViaBrowser"] = "true";
    initialVal["LogoutSessionRequired"] = "true";
    initialVal["Enabled"] = "true";
    initialVal["ClientTokenExpiration"] = 1;
    initialVal["ClientTokenUsage"] = 1;
    details = initialVal;
  }

  const select = useSelector((state) => {
    return { clients: state.clients && state.clients };
  });

  const status = select.clients && select.clients.newClient && select.clients.newClient.status;
  if (status && (status === 201 || status === 200)) {
    history.push(Urls.Clients);
    dispatch(resetNewCreatedClient());
  }

  const toBool = (val) => (val === "true" ? true : false);

  if (details && details["ClientName"]) {
    boolValues.forEach((val) => (details[val] = details[val].toString()));
  }

  const appTime = (val) => {
    if (val && new Date(val)) {
      const hours = new Date(val).getHours();
      const minute = new Date(val).getMinutes();
      const calTime = hours * 60 * 60 + minute * 60;
      return calTime;
    } else {
      return 0;
    }
  };

  const submitForm = (event, value) => {
    setModalShow(true);
    boolValues.forEach((val) => (value[val] = toBool(value[val])));
    timeValues.forEach((val) => (value[val] = appTime(timeVal[val])));
    let result = null;
    if (params.id) {
      result = { ...details, ...value, ...updateFormValues };
      result["Id"] = parseInt(params.id);
    } else {
      console.log({ formValues, value });

      result = { ...formValues, ...value };
    }
    setPayload(result);
    console.log({ result });
  };

  const onValidateOtp = () => {
    params.id ? dispatch(updateClient(params.id, payload)) : dispatch(createClient(payload));
  };

  const singleResult = (title, myValue) => {
    console.log({ title, myValue });
    params.id ? setUpdateFormValues({ ...updateFormValues, [title]: myValue }) : setFormValues({ ...formValues, [title]: myValue });
  };

  const onSelectTime = (time, type) => {
    setTimeVal({ ...timeVal, [type]: time });
  };

  useEffect(() => {
    if (params.id) {
      if (details && details.ClientName) {
        //Initializing the time input with details value
        timeValues.forEach((val) => (timeVal[val] = moment(secondsToHHMM(details[val]), format)));
        setTimeVal(timeVal);
        setSubPage("Edit a Client");
      } else {
        history.push(Urls.Clients);
      }
    } else {
      form.reset && form.reset();
      setSubPage("Create a Client");
      //Initializing the time input with default value of 1 hour
      timeVal["IdentityTokenLifetime"] = moment("00:05", format);
      timeVal["AccessTokenLifetime"] = moment("01:00", format);
      timeVal["AbsoluteRefreshTokenLifetime"] = moment("23:00", format);
      timeVal["AuthorizationCodeLifetime"] = moment("00:05", format);
      timeVal["SlidingRefreshTokenLifetime"] = moment("23:00", format);
      setTimeVal(timeVal);
    }
    console.log("reseting");
    setFormValues();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="row new-client-container">
      <OtpModal openModal={modalShow} closeModal={() => setModalShow(false)} onValidateOtp={onValidateOtp} />

      <div className="col">
        <BreadCrumb title="Clients" titleLink={Urls.Clients} sub1={subPage} />
        <div className="content-header">
          <PageTitle url={Urls.Clients} title="Clients" showArrow={true} />
        </div>

        <div className="card card-content">
          <div className="card-body">
            <AvForm onValidSubmit={submitForm} ref={(c) => setForm(c)} model={details}>
              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="ClientName">
                    Client Name <InfoTip infoText={InfoTipText.clients.ClientName} />
                  </label>
                  <AvField
                    type="text"
                    className="form-control"
                    id="ClientName"
                    name="ClientName"
                    placeholder="Enter Client Name"
                    validate={{
                      required: { value: true, errorMessage: "Please enter Client Name" },
                    }}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="clientId">
                    Client ID <InfoTip infoText={InfoTipText.clients.ClientId} />
                  </label>
                  <AvField
                    type="text"
                    className="form-control"
                    id="clientId"
                    name="ClientId"
                    placeholder="Enter Client ID"
                    validate={{
                      required: { value: true, errorMessage: "Please enter Client ID" },
                    }}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="ClientFlow">
                    Client Flow <InfoTip infoText={InfoTipText.clients.Flow} />
                  </label>
                  <AvField
                    type="select"
                    className="form-control"
                    name="ClientFlow"
                    id="ClientFlow"
                    validate={{
                      required: { value: true, errorMessage: "Please select one" },
                    }}
                  >
                    <option></option>
                    <option value={0}>Authorization Code</option>
                    <option value={1}>Implicit</option>
                    <option value={2}>Hybrid</option>
                    <option value={3}>Client Credentials</option>
                    <option value={4}>Resource Owner</option>
                    <option value={5}>Custom Grant</option>
                    <option value={6}>Authorization Code With Proof Key</option>
                    <option value={7}>Hybrid With Proof Key</option>
                  </AvField>
                </div>
                <div className="form-group col-md-4">
                  <label className="input__label" htmlFor="LogoUri">
                    Log Uri
                    <InfoTip infoText={InfoTipText.clients.LogoUri} />
                  </label>
                  <AvField className="form-control" id="LogoUri" name="LogoUri" placeholder="Enter Log Uri" />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="LogoutUri">
                    Logout Uri <InfoTip infoText={InfoTipText.clients.LogoutUri} />
                  </label>
                  <AvField className="form-control" id="LogoutUri" name="LogoutUri" placeholder="Enter Logout Uri" />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="ClientUri">
                    Client Uri <InfoTip infoText={InfoTipText.clients.ClientUri} />
                  </label>
                  <AvField type="text" className="form-control" id="ClientUri" name="ClientUri" placeholder="Enter Claims" />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="CallBackNotificationUrl">Callback Notification Url</label>
                  <AvField type="text" className="form-control" id="CallBackNotificationUrl" name="CallBackNotificationUrl" placeholder="Enter Claims" />
                </div>
              </div>
              <div className="row">
                <div class="form-group col-6">
                  <label htmlFor="IdentityTokenLifetime">
                    Identity Token Lifetime <InfoTip infoText={InfoTipText.clients.IdentityTokenLifetime} />
                  </label>
                  <TimePicker
                    onSelect={(time) => onSelectTime(time, "IdentityTokenLifetime")}
                    allowClear={false}
                    value={timeVal && timeVal.IdentityTokenLifetime}
                    format={format}
                    id="IdentityTokenLifetime"
                    className="form-control col-12"
                  />
                </div>
                <div class="form-group col-6">
                  <label htmlFor="AccessTokenLifetime">
                    Access Token Life-time <InfoTip infoText={InfoTipText.clients.AccessTokenLifetime} />
                  </label>
                  <TimePicker
                    onSelect={(time) => onSelectTime(time, "AccessTokenLifetime")}
                    allowClear={false}
                    value={timeVal && timeVal.AccessTokenLifetime}
                    format={format}
                    id="AccessTokenLifetime"
                    className="form-control col-12"
                  />
                </div>
                <div class="form-group col-6">
                  <label htmlFor="AbsoluteRefreshTokenLifetime">
                    Absolute Refresh Token Life-time <InfoTip infoText={InfoTipText.clients.AbsoluteRefreshTokenLifetime} />
                  </label>
                  <TimePicker
                    onSelect={(time) => onSelectTime(time, "AbsoluteRefreshTokenLifetime")}
                    allowClear={false}
                    value={timeVal && timeVal.AbsoluteRefreshTokenLifetime}
                    format={format}
                    id="AbsoluteRefreshTokenLifetime"
                    className="form-control col-12"
                  />
                </div>
                <div class="form-group col-6">
                  <label htmlFor="AuthorizationCodeLifetime">
                    Authorization Code Life-time <InfoTip infoText={InfoTipText.clients.AuthorizationCodeLifetime} />
                  </label>
                  <TimePicker
                    onSelect={(time) => onSelectTime(time, "AuthorizationCodeLifetime")}
                    allowClear={false}
                    value={timeVal && timeVal.AuthorizationCodeLifetime}
                    format={format}
                    id="AuthorizationCodeLifetime"
                    className="form-control col-12"
                  />
                </div>
                <div class="col-6">
                  <label htmlFor="SlidingRefreshTokenLifetime">
                    Sliding Refresh Token Life-time <InfoTip infoText={InfoTipText.clients.SlidingRefreshTokenLifetime} />
                  </label>
                  <TimePicker
                    onSelect={(time) => onSelectTime(time, "SlidingRefreshTokenLifetime")}
                    allowClear={false}
                    value={timeVal && timeVal.SlidingRefreshTokenLifetime}
                    format={format}
                    id="SlidingRefreshTokenLifetime"
                    className="form-control col-12"
                  />
                </div>
              </div>
              <div className="row">
                <AvRadioGroup className="form-group col-md-6" name="EnableLocalLogin" required errorMessage="Please select one">
                  <div className="radio-label">
                    Enable Local Login <InfoTip infoText={InfoTipText.clients.EnableLocalLogin} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" name="EnableLocalLogin" id="EnableLocalLogin-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" name="EnableLocalLogin" id="EnableLocalLogin-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>
                <AvRadioGroup className="form-group col-md-6" name="ClientAccessTokenType" required errorMessage="Please select one">
                  <div className="radio-label">
                    Client Access Token Type <InfoTip infoText={InfoTipText.clients.AccessTokenType} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" type="radio" label="Jwt" name="ClientAccessTokenType" id="ClientAccessTokenType-yes" value={0} />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Reference" type="radio" name="ClientAccessTokenType" id="ClientAccessTokenType-no" value={1} />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="AllowAccessToAllGrantTypes" required errorMessage="Please select one">
                  <div className="radio-label">
                    Allow Access To All Grant Types <InfoTip infoText={InfoTipText.clients.AllowedCustomGrantTypes} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="AllowAccessToAllGrantTypes" id="AllowAccessToAllGrantTypes-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="AllowAccessToAllGrantTypes" id="AllowAccessToAllGrantTypes-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="AllowAccessToAllScopes" required errorMessage="Please select one">
                  <div className="radio-label">
                    Allow Access To All Scopes <InfoTip infoText={InfoTipText.clients.AllowAllScopes} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="AllowAccessToAllScopes" id="AllowAccessToAllScopes-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="AllowAccessToAllScopes" id="AllowAccessToAllScopes-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="AllowAccessTokensViaBrowser" required errorMessage="Please select one">
                  <div className="radio-label">
                    Allow Access Tokens Via Browse <InfoTip infoText={InfoTipText.clients.AllowAccessTokensViaBrowser} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" name="AllowAccessTokensViaBrowser" id="AllowAccessTokensViaBrowse-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" name="AllowAccessTokensViaBrowser" id="AllowAccessTokensViaBrowse-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="AllowClientCredentialsOnly" required errorMessage="Please select one">
                  <div className="radio-label">
                    Allow Client Credentials Only <InfoTip infoText={InfoTipText.clients.AllowClientCredentialsOnly} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="AllowClientCredentialsOnly" id="AllowClientCredentialsOnly-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="AllowClientCredentialsOnly" id="AllowClientCredentialsOnly-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="AllowRememberConsent" required errorMessage="Please select one">
                  <div className="radio-label">
                    Allow Remember Consent <InfoTip infoText={InfoTipText.clients.AllowRememberConsent} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="AllowRememberConsent" id="AllowRememberConsent-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="AllowRememberConsent" id="AllowRememberConsent-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="AlwaysSendClientClaims" required errorMessage="Please select one">
                  <div className="radio-label">
                    Always Send Client Claims <InfoTip infoText={InfoTipText.clients.AlwaysSendClientClaims} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="AlwaysSendClientClaims" id="AlwaysSendClientClaims-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="AlwaysSendClientClaims" id="AlwaysSendClientClaims-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="Enabled" required errorMessage="Please select one">
                  <div className="radio-label">
                    Enabled <InfoTip infoText={InfoTipText.clients.Enabled} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="Enabled" id="Enabled-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="Enabled" id="Enabled-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="IncludeJwtId" required errorMessage="Please select one">
                  <div className="radio-label">
                    Include Jwt Id <InfoTip infoText={InfoTipText.clients.IncludeJwtId} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="IncludeJwtId" id="IncludeJwtId-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="IncludeJwtId" id="IncludeJwtId-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="LogoutSessionRequired" required errorMessage="Please select one">
                  <div className="radio-label">
                    Logout Session Required <InfoTip infoText={InfoTipText.clients.LogoutSessionRequired} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="LogoutSessionRequired" id="LogoutSessionRequired-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="LogoutSessionRequired" id="LogoutSessionRequired-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="RequireConsent" required errorMessage="Please select one">
                  <div className="radio-label">
                    Require Consent <InfoTip infoText={InfoTipText.clients.RequireConsent} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="RequireConsent" id="RequireConsent-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="RequireConsent" id="RequireConsent-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="PrefixClientClaims" required errorMessage="Please select one">
                  <div className="radio-label">
                    Prefix Client Claims <InfoTip infoText={InfoTipText.clients.PrefixClientClaims} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="PrefixClientClaims" id="PrefixClientClaims-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="PrefixClientClaims" id="PrefixClientClaims-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>

                <AvRadioGroup className="form-group col-md-6" name="RequireSignOutPrompt" required errorMessage="Please select one">
                  <div className="radio-label">
                    Require Sign Out Prompt <InfoTip infoText={InfoTipText.clients.RequireSignOutPrompt} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="RequireSignOutPrompt" id="RequireSignOutPrompt-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="RequireSignOutPrompt" id="RequireSignOutPrompt-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>
                <AvRadioGroup className="form-group col-md-6" name="UpdateAccessTokenOnRefresh" required errorMessage="Please select one">
                  <div className="radio-label">
                    Update Access Token On Refresh <InfoTip infoText={InfoTipText.clients.UpdateAccessTokenClaimsOnRefresh} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="UpdateAccessTokenOnRefresh" id="UpdateAccessTokenOnRefresh-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="UpdateAccessTokenOnRefresh" id="UpdateAccessTokenOnRefresh-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>
                <AvRadioGroup className="form-group col-md-6" name="ClientTokenExpiration" required errorMessage="Please select one">
                  <div className="radio-label">
                    Client Token Expiration <InfoTip infoText={InfoTipText.clients.ClientTokenExpiration} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Sliding" type="radio" name="ClientTokenExpiration" id="ClientTokenExpiration-yes" value={0} />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Absolute" type="radio" name="ClientTokenExpiration" id="ClientTokenExpiration-no" value={1} />
                    </div>
                  </div>
                </AvRadioGroup>
                <AvRadioGroup className="form-group col-md-6" name="ClientTokenUsage" required errorMessage="Please select one">
                  <div className="radio-label">
                    Client Token Usage <InfoTip infoText={InfoTipText.clients.ClientTokenUsage} />
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Reuse" type="radio" name="ClientTokenUsage" id="ClientTokenUsage-yes" value={0} />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="One Time Only" type="radio" name="ClientTokenUsage" id="ClientTokenUsage-no" value={1} />
                    </div>
                  </div>
                </AvRadioGroup>
                <AvRadioGroup className="form-group col-md-6" name="AllowAccessWithUnknownIP" required errorMessage="Please select one">
                  <div className="radio-label">
                    Allow Access With Unknown IP <InfoTip infoText={InfoTipText.clients.AllowAccessWithUnknownIP} />{" "}
                  </div>
                  <div className="radio-content">
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="Yes" type="radio" name="AllowAccessWithUnknownIP" id="AllowAccessWithUnknownIP-yes" value="true" />
                    </div>
                    <div className="form-check">
                      <AvRadio className="form-check-input" label="No" type="radio" name="AllowAccessWithUnknownIP" id="AllowAccessWithUnknownIP-no" value="false" />
                    </div>
                  </div>
                </AvRadioGroup>
              </div>
            </AvForm>

            <Accordion tipText={InfoTipText.clients.RedirectUris} accordionLable="Redirect Uris" id="RedirectUris">
              <ClientForm title="RedirectUris" fieldTitle="Uri" label="Redirect Uris" singleResult={singleResult} value={details.RedirectUris} />
            </Accordion>
            <Accordion tipText={InfoTipText.clients.PostLogoutRedirectUris} accordionLable="Post Logout Redirect Uris" id="PostLogoutRedirectUris">
              <ClientForm title="PostLogoutRedirectUris" fieldTitle="Uri" label="Post Logout Redirect Uris" singleResult={singleResult} value={details.PostLogoutRedirectUris} />
            </Accordion>
            <Accordion tipText={InfoTipText.clients.IdentityProviderRestrictions} accordionLable="Identity Provider Restrictions" id="IdentityProviderRestrictions">
              <ClientForm title="IdentityProviderRestrictions" fieldTitle="Provider" label="Identity Provider Restrictions" singleResult={singleResult} value={details.IdentityProviderRestrictions} />
            </Accordion>
            <Accordion tipText={InfoTipText.clients.ScopeClaims} accordionLable="Scope Claims" id="ScopeClaims">
              <ClientClaim title="Claims" singleResult={singleResult} claims={details.Claims || []} />
            </Accordion>
            <Accordion tipText={InfoTipText.clients.ClientSecrets} accordionLable="Client Secrets" id="ClientSecrets">
              <ClientSecret title="ClientSecrets" secrets={details.ClientSecrets || []} singleResult={singleResult} />
            </Accordion>
            <Accordion tipText={InfoTipText.clients.AllowAllScopes} accordionLable="Allowed Scopes" id="AllowedScopes">
              <ClientForm title="AllowedScopes" fieldTitle="Scope" label="Allowed Scopes" singleResult={singleResult} value={details.AllowedScopes || []} />
            </Accordion>
            <Accordion tipText={InfoTipText.clients.AllowedCustomGrantTypes} accordionLable="Allowed Custom Grant Types" id="AllowedCustomGrantTypes">
              <ClientForm title="AllowedCustomGrantTypes" fieldTitle="GrantType" label="Notification Url" singleResult={singleResult} value={details.AllowedCustomGrantTypes || []} />
            </Accordion>

            <Accordion tipText={InfoTipText.clients.AllowedCorsOrigins} accordionLable="Allowed Cors Origins" id="AllowedCorsOrigins">
              <ClientForm title="AllowedCorsOrigins" fieldTitle="Origin" label="Allowed Cors Origins" singleResult={singleResult} value={details.AllowedCorsOrigins || []} />
            </Accordion>

            <div className="row">
              <div className="col-12 text-center mt-4">
                <Button size="sm" color={Colors.GREEN} handleClick={() => form.submit()} loading={clientDetails.isLoading} disabled={clientDetails.isLoading}>
                  {params.id ? "Update Client" : "Create Client"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewClient;
