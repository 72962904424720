import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from "reactstrap";

import { fetchClients } from "../../../store/clients/clients-actions";
import { fetchBusinessUnit, fetchApplicationList, createMapApplication } from '../../../store/applications/applications-actions'
import { Urls } from '../../../utils/urls';
import { Colors } from '../../../utils/colors';
import Button from "../../shared/button/Button";

import './mapApplicationForm.scss';

const MapApplication = ({ openModal, closeModal }) => {
    const [form, setForm] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [createdStatus, setCreatedStatus] = useState(false);

    const [modalShow, setModalShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const select = useSelector((state) => {
        return {
            status: state.clients && state.clients.newClient && select.clients.newClient.status,
            clientList: state.clients && state.clients.clients.data && state.clients.clients.data.Response,
            clientLoading: state.clients && state.clients.isLoading, 
            applicationLoading: state.application && state.application.isLoading, 
            applicationList: state.application && state.application.applications && state.application.applicationList && state.application.applicationList.data && state.application.applicationList.data.Response,
            isMapApplicationLoading: state.application && state.application.isMapApplicationLoading,
            newMapApplicationStatus: state.application && state.application.newMapApplication && state.application.newMapApplication.status
        };
    });

    const { status, clientList, clientLoading, applicationList, newMapApplicationStatus, applicationLoading } = select;
    console.log({clientList})
    if (status && (status === 201 || status === 200)) {
        history.push(Urls.Clients);
        // dispatch(resetNewCreatedClient());
    }

    const submitForm = (event, value) => {
        value['ClientId'] = [value['ClientId']];
        setCreatedStatus(true);
        dispatch(createMapApplication(value));
    }

    useEffect(() => {
        dispatch(fetchClients());
        dispatch(fetchBusinessUnit());
        dispatch(fetchApplicationList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        openModal ? handleShow() : handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal])

    useEffect(() => {
        if (createdStatus && newMapApplicationStatus && newMapApplicationStatus === 200) {
            form.reset();
            closeModal();
            setCreatedStatus(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMapApplicationStatus])
    return (
        <Modal
        centered
        show={show}
        onHide={closeModal}
        className="map-application-modal-container">
        <Modal.Header closeButton>
            Map Application
        </Modal.Header>
        <Modal.Body>
        <AvForm onValidSubmit={submitForm} ref={c => (setForm(c))}>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="Application">Select an Application &nbsp; {applicationLoading &&
                                        <Spinner
                                            size="sm"
                                            color={"green"}
                                        />
                                        }</label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="ApplicationName"
                                        id="Application"
                                        validate={{
                                            required: { value: true, errorMessage: 'Please select one' },
                                        }}>
                                        <option></option>
                                        {applicationList && applicationList.map(val => {
                                            return <option key={val.Id}>{val.ApplicationName}</option>
                                        })}
                                    </AvField>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="clientId">Select a Client Id &nbsp; {clientLoading &&
                                        <Spinner
                                            size="sm"
                                            color={"green"}
                                        />
                                        }</label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="ClientId"
                                        id="clientId"
                                        validate={{
                                            required: { value: true, errorMessage: 'Please select one' },
                                        }}>
                                        <option></option>
                                        {clientList && clientList.map(val => {
                                            return <option key={val.ClientId} value={val.ClientId}>{val.ClientName}</option>
                                        })}
                                    </AvField>
                                </div>
                            </div>
                        </AvForm>
                        <div className="d-flex justify-content-end pr-3">
                      <Button
                            size="sm"
                            outline={true}
                            color={Colors.GREEN}
                            handleClick={() => {closeModal()}}
                            className="mr-2"
                        >
                           Cancel
                         </Button>
                        <Button size="sm" color={Colors.GREEN} handleClick={() => form.submit()} loading={select.isMapApplicationLoading}  disabled={select.isMapApplicationLoading}>
                            Create
                        </Button>
                        </div>
        </Modal.Body>
    </Modal>
    )
}

export default MapApplication;