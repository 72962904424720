const getRoleList = (loggedInUserRole) => {
  switch (loggedInUserRole) {
    case "sso_admin":
      return [
        {
          label: "Super Admin",
          Type: "role",
          Value: "vgg_superadmin",
        },
      ];
    case "vgg_superadmin":
      return [
        {
          label: "Admin",
          Type: "role",
          Value: "vgg_admin",
        },
      ];
    case "vgg_admin":
      return [
        {
          label: "Admin",
          Type: "role",
          Value: "vgg_admin",
        },
        {
          label: "User",
          Type: "role",
          Value: "vgg_user",
        },
        {
          label: "Client Admin",
          Type: "role",
          Value: "clientadmin",
        },
      ];
    default:
      return [];
  }
};

export default getRoleList;
