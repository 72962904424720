import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import BreadCrumb from "../../shared/breadCrumb/breadCrumb";
import ClientDetails from "./clientDetails/clientDetails";
import ListSingleValue from "../../shared/listSingleValue/listSingleValue";
import Claim from "./claims/claims";
// import ClientClaims from './clientClaims/clientClaims';
import ClientSecrets from "../../shared/secretDetail/secretDetail";
import { PageTitle } from "../../shared/titlePage/titlePage";
import { Urls, EditClientPage } from "../../../utils/urls";
import { Colors } from "../../../utils/colors";
import Button from "../../shared/button/Button";
import { clientDetails } from "../../../store/clients/clients-actions";
import OtpModal from "../../shared/otpModal/otpModal";
import { useTitle } from "../../../utils/useTitle";

import "./viewClient.scss";

const ViewClient = () => {
  useTitle("Client Details");
  const dispatch = useDispatch();
  const [otpShow, setOtpShow] = useState(false);

  const select = useSelector((state) => {
    return { clients: state.clients && state.clients.details };
  });

  const [key, setKey] = useState("Details");
  const history = useHistory();
  if (!select.clients) {
    history.push(Urls.Clients);
    return null;
  }

  const editClient = (details) => {
    history.push(EditClientPage(details.Id));
    dispatch(clientDetails(details));
  };

  const exportToJsonFile = (jsonData) => {
    let dataStr = JSON.stringify(jsonData);
    let dataUri = "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

    let exportFileDefaultName = `${jsonData.ClientName}.json`;

    let linkElement = document.createElement("a");
    linkElement.setAttribute("href", dataUri);
    linkElement.setAttribute("download", exportFileDefaultName);
    linkElement.click();
  };

  return (
    <section className="row client-detail-container">
      <OtpModal openModal={otpShow} closeModal={() => setOtpShow(false)} onValidateOtp={() => exportToJsonFile(select.clients)} />
      <div className="col">
        <BreadCrumb title="Clients" titleLink={Urls.Clients} sub1="Client Details" />
        <div className="content-header d-flex justify-content-between">
          <PageTitle url={Urls.Clients} title="Clients" showArrow={true} />
          <div className="sub-top-btn">
            <Button size="sm" outline={true} color={Colors.GREEN} handleClick={() => setOtpShow(true)} className="mr-2 cursor">
              Export Client
            </Button>
            <Button size="sm" color={Colors.GREEN} handleClick={() => editClient(select.clients)}>
              Edit Client
            </Button>
          </div>
        </div>

        <div className="card card-content">
          <div className="card-body tab-content">
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
              <Tab eventKey="Details" title="Client Details">
                <ClientDetails details={select.clients} />
              </Tab>
              <Tab eventKey="Scopes" title="Allowed Scopes">
                <ListSingleValue details={select.clients.AllowedScopes} title="Scopes" />
              </Tab>
              <Tab eventKey="Redirect" title="Redirect URIS">
                <ListSingleValue details={select.clients.RedirectUris} title="URI" />
              </Tab>
              <Tab eventKey="PostLogout" title="Post Logout Redirect URLS">
                <ListSingleValue details={[]} title="Origin" />
              </Tab>
              <Tab eventKey="AllowedCORS" title="Allowed CORS Origins">
                <ListSingleValue details={select.clients.PostLogoutRedirectUris} title="URI" />
              </Tab>
              <Tab eventKey="ClientSecrets" title="Client Secrets">
                <ClientSecrets details={select.clients.ClientSecrets} />
              </Tab>
              <Tab eventKey="Claims" title="Claims">
                <Claim details={select.clients} />
              </Tab>
              <Tab eventKey="IdentityProvider" title="Identity Provider Restrictions">
                <ListSingleValue details={select.clients.IdentityProviderRestrictions} title="Provider" />
              </Tab>
              <Tab eventKey="AllowedCustom" title="Allowed Custom Grant Types">
                <ListSingleValue details={[]} title="Grant Types" />
              </Tab>
              <Tab eventKey="CallbackNotification" title="Callback Notification URL">
                <ListSingleValue details={select.clients.CallBackNotificationUrl} title="Callback Url" />
              </Tab>
              <Tab eventKey="RemoveClaim" title="Remove Claim Notification URL">
                <ListSingleValue details={select.clients.RemoveClaimNotificationUrl} title="Remove Claim Notification Url" />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewClient;
