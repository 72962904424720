import { all, call } from "redux-saga/effects";
import onboardingSaga from "../onboarding/onboarding-saga";
import makerCheckerSagas from "../maker-checker/maker-checker-saga";
import scopeSaga from "../scopes/scopes-saga";
import userSaga from "../user/user-saga";
import clientSaga from "../clients/clients-saga";
import applicationSaga from "../applications/applications-saga";
import otpSaga from "../otp/otp-saga";
import auditSaga from "../audit/audit-saga";
import dashboardSaga from "../dashboard/dashboard-saga";
import accessLogSaga from "../access-logs/access-logs-saga";
import profileSaga from "../profile/profile-saga";

export default function* rootSaga() {
  yield all([call(onboardingSaga), call(makerCheckerSagas), call(userSaga), call(scopeSaga),
  call(clientSaga), call(applicationSaga), call(otpSaga), call(auditSaga), 
  call(accessLogSaga), call(dashboardSaga), call(profileSaga)]);
}
