import React from 'react';
import TimeConversion from '../../../shared/timeConvertion';


import './clientDetails.scss';

const ScopeDetails = ({ details }) => {
    const { ClientName, ClientId, ClientFlow, IdentityTokenLifetime, AccessTokenLifetime, EnableLocalLogin,
        AbsoluteRefreshTokenLifetime, ClientAccessTokenType, AllowAccessToAllGrantTypes, AllowAccessToAllScopes,
        AllowAccessTokensViaBrowser, AllowClientCredentialsOnly, AllowRememberConsent, AlwaysSendClientClaims,
        AuthorizationCodeLifetime, Enabled, IncludeJwtId, LogoUri, LogoutUri, LogoutSessionRequired,
        RequireConsent, ClientUri, PrefixClientClaims, RequireSignOutPrompt, SlidingRefreshTokenLifetime,
        UpdateAccessTokenOnRefresh, ClientTokenExpiration, ClientTokenUsage } = details;

        const clientIds = ['Authorization Code', 'Implicit', 'Hybrid', 'Client Credentials', 'Resource Owner', 'Custom Grant', 'Authorization Code With Proof Key', 'Hybrid With Proof Key'];
  
    return (
        <div className='client-details-container'>
            <table className="table">
                <tbody>
                    <tr>
                        <td>Client Name</td>
                        <td>{ClientName}</td>
                    </tr>
                    <tr>
                        <td>Client ID</td>
                        <td>{ClientId}</td>
                    </tr>
                    <tr>
                        <td>Client Flow</td>
                        <td>{clientIds[ClientFlow]}</td>
                    </tr>
                    <tr>
                        <td>Identity Token</td>
                        <td>{IdentityTokenLifetime > 0 ?TimeConversion.secondsToMins(IdentityTokenLifetime): IdentityTokenLifetime}</td>
                    </tr>
                    <tr>
                        <td>Access Token</td>
                        <td>{AccessTokenLifetime && AccessTokenLifetime > 0? TimeConversion.secondsToHrs(AccessTokenLifetime): AccessTokenLifetime}</td>
                    </tr>
                    <tr>
                        <td>Enable Local Login</td>
                        <td>{EnableLocalLogin && EnableLocalLogin.toString()}</td>
                    </tr>
                    <tr>
                        <td>Absolute Refresh Token Life-time</td>
                        <td>{TimeConversion.secondsToHrs(AbsoluteRefreshTokenLifetime)}</td>
                    </tr>
                    <tr>
                        <td>Client Access Token Type</td>
                        <td>{ClientAccessTokenType}</td>
                    </tr>
                    <tr>
                        <td>Allow Access To All Grant Types</td>
                        <td>{AllowAccessToAllGrantTypes?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Allow Access To All Scopes</td>
                        <td>{AllowAccessToAllScopes?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Allow Access Tokens Via Browser</td>
                        <td>{AllowAccessTokensViaBrowser?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Allow Client Credentials Only</td>
                        <td>{AllowClientCredentialsOnly?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Allow Remember Consent</td>
                        <td>{AllowRememberConsent?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Always Send Client Claims</td>
                        <td>{AlwaysSendClientClaims?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Authorization Code Life-time</td>
                        <td>{AuthorizationCodeLifetime > 0 ? TimeConversion.secondsToMins(AuthorizationCodeLifetime): AuthorizationCodeLifetime}</td>
                    </tr>
                    <tr>
                        <td>Enabled</td>
                        <td>{Enabled?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Include Jwt Id</td>
                        <td>{IncludeJwtId?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Logo Uri</td>
                        <td>{LogoUri}</td>
                    </tr>
                    <tr>
                        <td>Logout Session Required</td>
                        <td>{LogoutSessionRequired?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Logout Uri</td>
                        <td>{LogoutUri}</td>
                    </tr>
                    <tr>
                        <td>Require Consent</td>
                        <td>{RequireConsent?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Client Uri</td>
                        <td>{ClientUri?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Prefix Client Claims</td>
                        <td>{PrefixClientClaims?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Require Sign-Out Prompt</td>
                        <td>{RequireSignOutPrompt?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Sliding Refresh Token Lifetime</td>
                        <td>{SlidingRefreshTokenLifetime > 0? TimeConversion.secondsToHrs(SlidingRefreshTokenLifetime): SlidingRefreshTokenLifetime}</td>
                    </tr>
                    <tr>
                        <td>Update Access Token On Refresh</td>
                        <td>{UpdateAccessTokenOnRefresh?'Yes':'No'}</td>
                    </tr>
                    <tr>
                        <td>Client Token Expiration</td>
                        <td>{`${ClientTokenExpiration} ${ClientTokenExpiration > 1? 'seconds':'second'}`}</td>
                    </tr>
                    <tr>
                        <td>Client Token Usage</td>
                        <td>{`${ClientTokenUsage} ${ClientTokenUsage > 1? 'seconds':'second'}`}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    )
}

export default ScopeDetails;