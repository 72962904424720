import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "./clearanceEditor.scss";
import { Table } from "react-bootstrap";
import { Button } from "../../../../../shared";
import { LoadingDropdownItems } from "../../../../../shared/loader/Loader";
import { Spinner } from "reactstrap";

const ClearanceEditor = ({ title, fieldTitle, label, handleFormValues, value, list, loading }) => {
  const [selectedClients, setSelectedClients] = useState([]);
  const [form, setForm] = useState([]);
  const [myValue, setMyValue] = useState({});

  const checkForDuplicates = (array, keyName) => {
    return new Set(array.map(item => item[keyName])).size !== array.length;
  };

  const handleSubmit = (e, values) => {
    const selectedVal = list.find(v => v.ClientId === values.ClientIds);
    setSelectedClients(prevState => [...prevState, selectedVal]);
    form.reset();
  };

  const handleDelete = value => {
    console.log(value);
    setSelectedClients(prevState => prevState.filter(v => v.Id !== value.Id));
  };

  useEffect(() => {
    const clientKeys = selectedClients.map(v => v.Id);
    const clientIds = selectedClients.map(v => v.ClientId);

    handleFormValues(["ClientIds", "ClientKeys"], [clientIds, clientKeys]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients]);

  return (
    <>
      <AvForm className="row clearance-form" onValidSubmit={handleSubmit} ref={c => setForm(c)} model={myValue}>
        <div className="form-group col">
          <label htmlFor="clientId">
            {loading && !list ? (
              <span>
                Loading Clients...
                <Spinner size="sm" />
              </span>
            ) : (
              label
            )}
          </label>
          <AvField
            type="select"
            className="form-control"
            name={fieldTitle}
            validate={{
              required: { value: true, errorMessage: "Please select one" },
            }}
          >
            <option></option>
            {list &&
              list.map(val => {
                return (
                  <option key={val.Id} value={val.ClientId}>
                    {val.ClientName}
                  </option>
                );
              })}
          </AvField>
        </div>
        <div className="col-auto d-flex align-items-center justify-content-end">
          <Button type="submit" color="green" disabled={loading && !list} disabled={selectedClients.length === 1}>
            {loading && !list ? <Spinner size="sm" /> : "Add"}
          </Button>
        </div>
      </AvForm>
      {selectedClients && selectedClients.length ? (
        <div className="selected-clients">
          <Table responsive>
            <thead>
              <tr>
                <th scope="col">Clients</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {selectedClients.map((value, i) => {
                return (
                  <tr key={i}>
                    <td width="98%">{value.ClientName}</td>
                    <td width="2%">
                      <span className="delete ml-auto" onClick={() => handleDelete(value)}>
                        <i className="ri-delete-bin-2-line text-danger"></i>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="text-center w-100">No Client Selected</div>
      )}
    </>
  );
};

export default ClearanceEditor;
