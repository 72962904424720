import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import ViewContent from "../viewScope/viewContent/viewContent";
import { Colors } from "../../../utils/colors";
import Button from "../../shared/button/Button";
import { subscriber } from "../../../axios/interceptor";
import { ErrorHandler } from "../../../utils/errorHandler";

import "./previewScope.scss";

const PreviewClient = ({ openModal, closeModal, select, action, setModalShow }) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClose = () => {
    setShow(false);
    setModalShow(false);
  };
  const handleShow = () => setShow(true);

  const scope = useSelector((state) => {
    return {
      isLoading: state.scopes && state.scopes.isCreateLoading,
      status: state.scopes && state.scopes.newScope && state.scopes.newScope.status,
    };
  });

  const { isLoading, status } = scope;
  subscriber.subscribe((val) => {
    const value = val ? ErrorHandler(val) : "";
    !errorMessage && errorMessage !== 0 && setErrorMessage(val);
    if (value !== "" && value !== message) {
      setMessage(value);
      handleClose();
    }
  });

  useEffect(() => {
    openModal && handleShow();
  }, [openModal]);

  useEffect(() => {
    if (!isLoading && status && openModal) {
      handleClose();
    }
  }, [isLoading]);

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose()} className="modal-container">
      <Modal.Header closeButton>
        <div className="title">Preview</div>
      </Modal.Header>
      <Modal.Body>
        <ViewContent select={select} />
      </Modal.Body>
      <Modal.Footer className="">
        <Button
          size="sm"
          color={Colors.GREEN}
          handleClick={() => {
            action();
            setErrorMessage(null);
          }}
          loading={scope.isLoading && !errorMessage}
          disabled={scope.isLoading && !errorMessage}
        >
          Import Scope
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewClient;
