import React, { useEffect, useState } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";

import TopNav from "./top-nav/topNavBar";
import SideBar from "./sideBar/sideBar";
import clientIdentity from "../../utils/user-manager";
import RouteLists from "../../routes/route-list";
import { Urls } from "../../utils/urls";

import "./layout.scss";

const Layout = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const [onSideBarHover, setOnSideBarHover] = useState(false);

  const { url } = useRouteMatch();

  const handleShowModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleSignout = (e) => {
    e.preventDefault();
    clientIdentity.signoutRedirect().then((res) => {
      props.history.push(Urls.LoggingOut);
    });
    // window.location.href = SSO_URL + '/connect/endsession?id_token_hint=' + userDetails.details.id_token + '&post_logout_redirect_uri=' + POST_LOGOUT_REDIRECT_URI;
  };

  const getUserProfile = async () => {
    const profile = await JSON.parse(localStorage.getItem("user_profile"));
    setUserProfile(profile);
  };

  const isCollapse = (collapse) => {
    setCollapse(collapse);
  };
  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <TopNav handleSignout={handleSignout} handleShowModal={handleShowModal} showModal={showModal} setShowModal={setShowModal} userProfile={userProfile} isCollapse={isCollapse} />
      <div className="row m-margin" onClick={() => setOnSideBarHover(false)}>
        <div className="col-auto p-0 sidebar-fixed">
          <SideBar collapse={collapse} setOnSideBarHover={setOnSideBarHover} />
        </div>
        <div className="col routes" style={{ minWidth: "10vh", marginLeft: collapse ? "50px" : "220px", zIndex: onSideBarHover ? -1 : 0 }}>
          <RouteLists baseUrl={url} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Layout);
