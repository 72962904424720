import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  FetchAllUsersDataSuccess,
  FetchApplicationListSuccess,
  FetchingData,
  FetchingUserDetails,
  FetchPagingData,
  FetchUserClearance,
  FetchUserClearanceSuccess,
  FetchUserDetails,
  FetchUserDetailsSuccess,
  FetchUserSignedInCallbackSuccess,
  ProcessingUser,
  UserActionTypes,
  FetchSuperAdminSuccess,
  FetchAdminSuccess,
  FetchVggUserSuccess,
  FetchClientAdminSuccess,
  FetchClientUserSuccess,
  SetActionFor,
  SetUserTabKey,
} from "./user-actions";
import { createBrowserHistory } from "history";
import clientIdentity from "../../utils/user-manager";
import { ssoApi, requestWithCustomClientId } from "../../axios/interceptor";
import ShowToast from "../../utils/showToast";
import { Urls } from "../../utils/urls";
import { SSO_API_URL } from "../../constants/app-constant";

const history = createBrowserHistory();

export function* FetchUserSignedInCallBackAsync() {
  try {
    const response = yield clientIdentity.signinRedirectCallback();
    yield localStorage.setItem("user", JSON.stringify(response));
    yield localStorage.setItem("user_profile", JSON.stringify(response.profile));
    yield localStorage.setItem("initialTime", Date.now());
    yield put(FetchUserSignedInCallbackSuccess(response));
  } catch (err) {
    yield put(history.replace("/"));
    yield put(
      ShowToast({
        type: "error",
        message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : "Data Could not be loaded"}`,
      })
    );
  }
}

export function* WatchUserSignedInCallBack() {
  yield takeEvery(UserActionTypes.FETCH_USER_SIGNEDIN_CALLBACK, FetchUserSignedInCallBackAsync);
}

export function* FetchCurrentUserAsync({ payload }) {
  try {
    const res = yield call(ssoApi.get, `/account/getuserbyemail?email=${payload}`);
    console.log(res);
    // yield put(FetchCurrentUserSuccess(res.data.Data[0]));
  } catch (err) {
    yield put(
      ShowToast({
        type: "error",
        message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : "Data Could not be loaded"}`,
      })
    );
  }
}

export function* WatchCurrentUserFetch() {
  yield takeEvery(UserActionTypes.FETCH_CURRENT_USER, FetchCurrentUserAsync);
}

export function* FetchAllUsersAsync({ payload: { perPage, pageNumber, letter, searchTerm } }) {
  try {
    yield put(FetchingData(true));
    if (!searchTerm) {
      if (!letter) {
        const res = yield call(ssoApi.get, `/account/getusers?pageSize=${perPage}&pageNo=${pageNumber}`);
        yield put(FetchAllUsersDataSuccess(res.data.Data));
        yield put(FetchPagingData(res.data.Paging));
        yield put(FetchingData(false));
      } else {
        const res = yield call(ssoApi.get, `/account/getusers?letter=${letter}&pageSize=${perPage}&pageNo=${pageNumber}`);
        yield put(FetchAllUsersDataSuccess(res.data.Data));
        yield put(FetchPagingData(res.data.Paging));
        yield put(FetchingData(false));
      }
    } else {
      const res = yield call(ssoApi.get, `/account/getuserbyemail?email=${searchTerm}`);
      yield put(FetchAllUsersDataSuccess(res.data.Data));
      yield put(FetchPagingData(res.data.Paging));
      yield put(FetchingData(false));
    }
  } catch (err) {
    yield put(FetchingData(false));
    if (err.response) {
      if (err.response.data.responseException) {
        yield put(
          ShowToast({
            type: "error",
            message: err.response.data.responseException.exceptionMessage,
          })
        );
      } else if (err.response.data.Message) {
        yield ShowToast({
          type: "error",
          message: err.response.data.Message,
        });
      }
    }
  }
}

export function* WatchAllUsersFetch() {
  yield takeEvery(UserActionTypes.FETCH_ALL_USERS, FetchAllUsersAsync);
}

export function* FetchUserDetailsAsync({ payload }) {
  try {
    yield put(FetchingUserDetails(true));
    const res = yield call(ssoApi.get, `/account/getuser?userId=${payload}`);
    if (res.status === 200) {
      yield put(FetchUserDetailsSuccess(res.data.Response));
      yield put(FetchingUserDetails(false));
    } else {
      yield put(FetchingUserDetails(false));
    }
  } catch (err) {
    yield put(FetchingUserDetails(false));
    if (err.response) {
      if (err.response.data.responseException) {
        yield put(
          ShowToast({
            type: "error",
            message: err.response.data.responseException.exceptionMessage,
          })
        );
      } else if (err.response.data.Message) {
        yield ShowToast({
          type: "error",
          message: err.response.data.Message,
        });
      }
    }
  }
}

export function* WatchUserDetailsFetch() {
  yield takeEvery(UserActionTypes.FETCH_USER_DETAILS, FetchUserDetailsAsync);
}

export function* FetchUserClearanceAsync({ payload }) {
  try {
    yield put(FetchingUserDetails(true));
    const res = yield call(ssoApi.get, `account/clearance?userId=${payload}`);
    yield put(FetchUserClearanceSuccess(res.data));
    yield put(FetchingUserDetails(false));
  } catch (err) {
    // yield ShowToast({
    //   type: "error",
    //   message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`
    // });
    yield put(FetchingUserDetails(false));
  }
}

export function* WatchUserClearanceFetch() {
  yield takeEvery(UserActionTypes.FETCH_USER_CLEARANCE, FetchUserClearanceAsync);
}

export function* FetchApplicationListAsync() {
  try {
    const res = yield call(ssoApi.get, `/client/list?type=all`);
    yield put(FetchApplicationListSuccess(res.data.Response));
  } catch (err) {
    // yield ShowToast({
    //   type: "error",
    //   message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`
    // });
  }
}

export function* WatchApplicationListFetch() {
  yield takeEvery(UserActionTypes.FETCH_APPLICATION_LIST, FetchApplicationListAsync);
}

export function* FetchClientByIdAsync({ payload: { filteredItem, userType } }) {
  try {
    const res = yield call(ssoApi.get, `client/getnamebyclientid?clientId=${filteredItem.toLowerCase()}`);
    if (res.status === 200) {
      switch (userType) {
        case "vgg_superadmin":
          yield put(FetchSuperAdminSuccess([res.data]));
          break;
        case "vgg_admin":
          yield put(FetchAdminSuccess([res.data]));
          break;
        case "vgg_user":
          yield put(FetchVggUserSuccess([res.data]));
          break;
        case "clientadmin":
          yield put(FetchClientAdminSuccess([res.data]));
          break;
        case "clientuser":
          yield put(FetchClientUserSuccess([res.data]));
          break;
      }
    }
  } catch (err) {
    // yield ShowToast({
    //   type: "error",
    //   message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`
    // });
  }
}

export function* WatchClientByIdFetch() {
  yield takeEvery(UserActionTypes.FETCH_CLIENT_BY_ID, FetchClientByIdAsync);
}

export function* RegisterUserAsync({ payload: { formData, history } }) {
  try {
    yield put(ProcessingUser(true));
    const res = yield call(ssoApi.post, `/account/register`, JSON.stringify(formData));
    yield put(ProcessingUser(false));
    if (res.status && (res.status === 201 || res.status === 200)) {
      yield put(SetActionFor(""));
      yield ShowToast({ type: "success", message: "Record saved successfully" });
      yield history.push(Urls.Users);
    }
  } catch (err) {
    yield put(ProcessingUser(false));
    yield put(
      ShowToast({
        type: "error",
        message: err.response?.data.responseException.exceptionMessage,
      })
    );
  }
}

export function* WatchUserRegistration() {
  yield takeEvery(UserActionTypes.SAVE_USER, RegisterUserAsync);
}

export function* AddClaimAsync({ payload: { formValues, closeModal, selectedClient } }) {
  try {
    yield put(ProcessingUser(true));
    const req = yield requestWithCustomClientId(SSO_API_URL, selectedClient.ClientId);
    const res = yield call(req.post, `/account/addclaims`, JSON.stringify(formValues));
    if (res.status === 200 || res.status === 201) {
      yield put(ProcessingUser(false));
      yield put(SetActionFor(""));
      yield closeModal();
      yield put(SetUserTabKey("Details"));
      yield put(FetchUserDetails(formValues.UserId));
      yield put(
        ShowToast({
          type: "success",
          message: "User claims added successfully",
        })
      );
    } else {
      yield put(ProcessingUser(false));
      console.log(res);
      yield put(
        ShowToast({
          type: "error",
          message: res.message ? res.message : "Something went wrong. Data could not be saved",
        })
      );
    }
  } catch (err) {
    yield put(ProcessingUser(false));
    if (err.response && err.response.data) {
      yield ShowToast({
        type: "error",
        message: err.response.data.Message,
      });
    }
  }
}

export function* WatchAddClaim() {
  yield takeEvery(UserActionTypes.ADD_USER_CLAIM, AddClaimAsync);
}

export function* RemoveClaimAsync({ payload: { formValues, closeModal } }) {
  try {
    const res = yield call(ssoApi.post, `/account/removeclaims`, JSON.stringify(formValues));
    if (res.status === 200 || res.status === 201) {
      yield put(ProcessingUser(false));
      yield put(SetActionFor(""));

      yield closeModal();
      yield put(SetUserTabKey("Details"));
      yield put(FetchUserDetails(formValues.UserId));

      yield put(
        ShowToast({
          type: "success",
          message: "User claims removed successfully",
        })
      );
    } else {
      yield put(ProcessingUser(false));
    }
  } catch (err) {
    yield put(ProcessingUser(false));
    if (err.response) {
      if (err.response.data.responseException) {
        yield put(
          ShowToast({
            type: "error",
            message: err.response.data.responseException.exceptionMessage,
          })
        );
      } else if (err.response.data.Message) {
        yield ShowToast({
          type: "error",
          message: err.response.data.Message,
        });
      }
    }
  }
}

export function* WatchRemoveClaim() {
  yield takeEvery(UserActionTypes.REMOVE_USER_CLAIMS, RemoveClaimAsync);
}

export function* AddUserClearanceAsync({ payload: { formValues, closeModal } }) {
  try {
    const res = yield call(ssoApi.post, `/clearance/add`, JSON.stringify(formValues));
    yield console.log(res);
    if (res.status === 200 || res.status === 201) {
      yield put(ProcessingUser(false));
      yield put(SetActionFor(""));
      yield put(SetUserTabKey("Details"));
      yield closeModal();
      yield put(FetchUserDetails(formValues.Userid));
      yield put(
        ShowToast({
          type: "success",
          message: "User clearance added successfully",
        })
      );
    } else {
      yield put(ProcessingUser(false));
    }
  } catch (err) {
    yield put(ProcessingUser(false));
    if (err.response) {
      if (err.response.data.responseException) {
        yield put(
          ShowToast({
            type: "error",
            message: err.response.data.responseException.exceptionMessage,
          })
        );
      } else if (err.response.data.Message) {
        yield ShowToast({
          type: "error",
          message: err.response.data.Message,
        });
      }
    }
  }
}

export function* WatchAddClearance() {
  yield takeEvery(UserActionTypes.ADD_USER_CLEARANCE, AddUserClearanceAsync);
}

export function* EnableClientAsync({ payload: { formValues, isEnabled } }) {
  try {
    const res = isEnabled ? yield call(ssoApi.post, `/clearance/disable`, JSON.stringify(formValues)) : yield call(ssoApi.post, `/clearance/enable`, JSON.stringify(formValues));
    if (res.status === 200 || res.status === 201) {
      yield put(ProcessingUser(false));
      yield put(SetActionFor(""));
      yield put(FetchUserClearance(formValues.Userid));
      yield put(SetUserTabKey("Details"));
      yield put(
        ShowToast({
          type: "success",
          message: `Client ${isEnabled ? "disabled" : "enabled"} successfully`,
        })
      );
    } else {
      yield put(ProcessingUser(false));
    }
  } catch (err) {
    yield put(ProcessingUser(false));
    if (err.response) {
      if (err.response.data.responseException) {
        yield put(
          ShowToast({
            type: "error",
            message: err.response.data.responseException.exceptionMessage,
          })
        );
      } else if (err.response.data.Message) {
        yield ShowToast({
          type: "error",
          message: err.response.data.Message,
        });
      }
    }
  }
}

export function* WatchClientEnable() {
  yield takeEvery(UserActionTypes.ENABLE_CLIENT, EnableClientAsync);
}

export function* LockUserAsync({ payload: { locked, UserId } }) {
  try {
    const res = locked() ? yield call(ssoApi.get, `/account/unlock?userId=${UserId}`) : yield call(ssoApi.get, `/account/lock?userId=${UserId}`);
    if (res.status === 200 || res.status === 201) {
      yield put(ProcessingUser(false));
      yield put(SetActionFor(""));
      yield put(SetUserTabKey("Details"));
      yield put(FetchUserDetails(UserId));
      yield put(
        ShowToast({
          type: "success",
          message: `User ${locked() ? "unlocked" : "locked"} successfully`,
        })
      );
    } else {
      yield put(ProcessingUser(false));
    }
  } catch (err) {
    yield put(ProcessingUser(false));
    if (err.response) {
      if (err.response.data.responseException) {
        yield put(
          ShowToast({
            type: "error",
            message: err.response.data.responseException.exceptionMessage,
          })
        );
      } else if (err.response.data.Message) {
        yield ShowToast({
          type: "error",
          message: err.response.data.Message,
        });
      }
    }
  }
}

export function* WatchUserLock() {
  yield takeEvery(UserActionTypes.LOCK_USER, LockUserAsync);
}

export default function* userSaga() {
  yield all([
    call(WatchUserSignedInCallBack),
    call(WatchCurrentUserFetch),
    call(WatchAllUsersFetch),
    call(WatchUserDetailsFetch),
    call(WatchUserRegistration),
    call(WatchUserClearanceFetch),
    call(WatchAddClaim),
    call(WatchApplicationListFetch),
    call(WatchRemoveClaim),
    call(WatchAddClearance),
    call(WatchClientEnable),
    call(WatchUserLock),
    call(WatchClientByIdFetch),
  ]);
}
