export const AuditActionTypes = {
    FETCHING_DATA: "FETCHING_AUDIT",
    FETCHING_AUDIT_SUCCESS: "FETCHING_AUDIT_SUCCESS",
    FETCHING_AUDIT_ERROR: "FETCHING_AUDIT_ERROR",
    AUDIT_DETAILS: "AUDIT_DETAILS",

}

export const fetchAudit = (payload) => ({
    type: AuditActionTypes.FETCHING_DATA,
    payload: payload,
});

export const fetchAuditSuccess = (payload) => ({
    type: AuditActionTypes.FETCHING_AUDIT_SUCCESS,
    payload: payload,
});

export const auditDetails = (payload) => ({
    type: AuditActionTypes.AUDIT_DETAILS,
    payload: payload,
});