import React, { useState, useEffect } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button } from '../../../../../shared';

import './claimEditor.scss';
import { Spinner, Table } from 'reactstrap';

const ClaimEditor = ({ singleResult, applicationList, handleApplicaitonSelect, loading }) => {
  const [clientForm, setClientForm] = useState([]);
  const [form, setForm] = useState([]);
  const [myValue, setMyValue] = useState({});

  const submitForm = (event, values) => {
    delete values.ApplicationName;
    const claim = clientForm.find(item => item.Value === values.Value && item.Type === values.Type);
    if (!claim) {
      setClientForm(prevState => [...prevState, values]);
    }
    setMyValue({});
  };

  const remove = claim => {
    setClientForm(prevState => prevState.filter(item => item.Value !== claim.Value));
  };

  const handleDelete = value => {
    setMyValue(value);
    remove(value);
  };

  useEffect(() => {
    singleResult('Claims', clientForm);
    if (form._inputs) {
      form._inputs.Type.reset();
      form._inputs.Value.reset();
    }
  }, [clientForm]);

  return (
    <>
      <AvForm className='row claim-editor-form' onValidSubmit={submitForm} ref={c => setForm(c)} model={myValue}>
        <div className='col'>
          <div className='row'>
            <div className='form-group col'>
              <label htmlFor='clientId'>Application {loading && !applicationList && <Spinner size='sm' color='green' />}</label>
              <AvField
                type='select'
                className='form-control'
                name='ApplicationName'
                id='Application'
                onChange={handleApplicaitonSelect}
                validate={{
                  required: { value: true, errorMessage: 'Please select one' },
                }}
              >
                <option></option>
                {applicationList &&
                  applicationList.map(val => {
                    return <option key={val.Id}>{val.ClientName}</option>;
                  })}
              </AvField>
            </div>
          </div>
          <div className='row'>
            <div className='form-group col-sm-12 col-md-6 col-lg-5 pr-1'>
              <label htmlFor='type'>Type</label>
              <AvField
                type='select'
                className='form-control'
                name='Type'
                id='type'
                validate={{
                  required: { value: true, errorMessage: 'Please select one' },
                }}
              >
                <option></option>
                <option value='permission'>Permission</option>
                <option value='role'>Role</option>
              </AvField>
            </div>
            <div className='form-group col-sm-12 col-md-6 col-lg-4 pr-0 pl-1'>
              <label htmlFor='value'>Value</label>
              <AvField
                type='text'
                className='form-control'
                name='Value'
                id='value'
                placeholder='Enter Value'
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select Value',
                  },
                }}
              ></AvField>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-3 d-flex justify-content-end align-items-center'>
              <Button size='sm' color='green' type='submit'>
                Add
              </Button>
            </div>
          </div>
        </div>
      </AvForm>
      {clientForm && clientForm.length ? (
        <div className='selected-clients'>
          <Table responsive>
            <thead>
              <tr>
                <th scope='col'>Type</th>
                <th scope='col'>Value</th>
                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {clientForm.map((value, idx) => {
                return (
                  <tr key={idx}>
                    <td width='45%'>{value['Type']}</td>
                    <td width='45%'>{value['Value']}</td>
                    <td width='10%' className='d-flex justify-content-end align-items-center ml-auto'>
                      <span className='delete ml-auto' onClick={() => handleDelete(value)}>
                        <i className='ri-delete-bin-2-line text-danger'></i>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className='text-center w-100'>No Client Selected</div>
      )}
    </>
  );
};

export default ClaimEditor;
