export const UserActionTypes = {
  SET_ACTION_FOR: "[ USER - STORE ] Set Action For",

  SET_CURRENT_USER: "[ USER - STORE ] Set Current User",
  GETTING_CURRENT_USER: "[ USER - STORE ] Getting Current User",
  FETCHING_DATA: "[ USER - STORE ] Fetching Data",

  PROCESSING_USER: "[ USER - STORE ] PROCESSING USER",

  FETCH_USER_SIGNEDIN_CALLBACK: "[ USER - STORE ] FETCH USER SIGNEDIN CALLBACK",
  FETCH_USER_SIGNEDIN_CALLBACK_SUCCESS: "[ USER - STORE ] FETCH USER SIGNEDIN CALLBACK SUCCESS",

  FETCH_CURRENT_USER: "[ USER - STORE ] FETCH CURRENT USER",
  FETCH_CURRENT_USER_SUCCESS: "[ USER - STORE ] FETCH CURRENT USER SUCCESS",

  FETCH_USER_CLEARANCE: "[ USER - STORE ] FETCH USER CLEARANCE",
  FETCH_USER_CLEARANCE_SUCCESS: "[ USER - STORE ] FETCH USER CLEARANCE SUCCESS",

  FETCH_ALL_USERS: "[ USER - STORE ] FETCH ALL USERS",
  FETCH_ALL_USERS_SUCCESS: "[ USER - STORE ] FETCH ALL USERS SUCCESS",
  FETCH_PAGING: "[ USER - STORE ] FETCH PAGING",

  FETCH_USER_DETAILS: "[ USER - STORE ] FETCH USER DETAILS",
  FETCH_USER_DETAILS_SUCCESS: "[ USER - STORE ] FETCH USER DETAILS SUCCESS",
  FETCHING_USER_DETAILS: "[ USER - STORE ] FETCHING USER DETAILS",

  FETCH_APPLICATION_LIST: "[ USER - STORE ] FETCH APPLICATION LIST",
  FETCH_APPLICATION_LIST_SUCCESS: "[ USER - STORE ] FETCH APPLICATION LIST SUCCESS",

  FETCH_CLIENT_BY_ID: "[ USER - STORE ] FETCH CLIENT BY ID",
  FETCH_SUPER_ADMIN_SUCCESS: "[ USER - STORE ] FETCH SUPER ADMIN SUCCESS",
  FETCH_ADMIN_SUCCESS: "[ USER - STORE ] FETCH ADMIN SUCCESS",
  FETCH_VGG_USER_SUCCESS: "[ USER - STORE ] FETCH VGG USER SUCCESS",
  FETCH_CLIENT_ADMIN_SUCCESS: "[ USER - STORE ] FETCH CLIENT ADMIN SUCCESS",
  FETCH_CLIENT_USER_SUCCESS: "[ USER - STORE ] FETCH CLIENT USER SUCCESS",

  SAVE_USER: "[ USER - STORE ] SAVE USER",
  ADD_USER_CLAIM: "[ USER - STORE ] ADD USER CLAIM",
  REMOVE_USER_CLAIMS: "[ USER - STORE ] REMOVE USER CLAIMS",
  ADD_USER_CLEARANCE: "[ USER - STORE ] ADD USER CLEARANCE",
  ENABLE_CLIENT: "[ USER - STORE ] ADD ENABLE CLIENT",
  LOCK_USER: "[ USER - STORE ] LOCK USER",

  SET_USER_TAB_KEY: "[ USER - STORE ] SET TAB KEY",
};

export const SetActionFor = payload => ({
  type: UserActionTypes.SET_ACTION_FOR,
  payload,
});

export const gettingCurrentUser = isGetting => ({
  type: UserActionTypes.GETTING_CURRENT_USER,
  payload: isGetting,
});

export const FetchCurrentUser = email => ({
  type: UserActionTypes.FETCH_CURRENT_USER,
  payload: email,
});

export const ProcessingUser = payload => ({
  type: UserActionTypes.PROCESSING_USER,
  payload,
});

export const SaveUser = payload => ({
  type: UserActionTypes.SAVE_USER,
  payload,
});

export const FetchCurrentUserSuccess = user => ({
  type: UserActionTypes.FETCH_CURRENT_USER_SUCCESS,
  payload: user,
});

export const FetchUserDetails = payload => ({
  type: UserActionTypes.FETCH_USER_DETAILS,
  payload,
});

export const FetchUserDetailsSuccess = payload => ({
  type: UserActionTypes.FETCH_USER_DETAILS_SUCCESS,
  payload,
});

export const FetchingUserDetails = payload => ({
  type: UserActionTypes.FETCHING_USER_DETAILS,
  payload,
});

export const FetchingData = payload => ({
  type: UserActionTypes.FETCHING_DATA,
  payload,
});

export const FetchAllUsersData = payload => ({
  type: UserActionTypes.FETCH_ALL_USERS,
  payload,
});

export const FetchPagingData = payload => ({
  type: UserActionTypes.FETCH_PAGING,
  payload,
});

export const FetchAllUsersDataSuccess = payload => ({
  type: UserActionTypes.FETCH_ALL_USERS_SUCCESS,
  payload,
});

export const FetchUserSignedInCallback = () => ({
  type: UserActionTypes.GETTING_CURRENT_USER,
});

export const FetchUserSignedInCallbackSuccess = user => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const FetchUserClearance = payload => ({
  type: UserActionTypes.FETCH_USER_CLEARANCE,
  payload,
});

export const FetchUserClearanceSuccess = payload => ({
  type: UserActionTypes.FETCH_USER_CLEARANCE_SUCCESS,
  payload,
});

export const AddUserClaim = payload => ({
  type: UserActionTypes.ADD_USER_CLAIM,
  payload,
});

export const FetchApplicationList = () => ({
  type: UserActionTypes.FETCH_APPLICATION_LIST,
});

export const FetchApplicationListSuccess = payload => ({
  type: UserActionTypes.FETCH_APPLICATION_LIST_SUCCESS,
  payload,
});

export const RemoveUserClaims = payload => ({
  type: UserActionTypes.REMOVE_USER_CLAIMS,
  payload,
});

export const AddUserClearance = payload => ({
  type: UserActionTypes.ADD_USER_CLEARANCE,
  payload,
});

export const EnableClient = payload => ({
  type: UserActionTypes.ENABLE_CLIENT,
  payload,
});

export const LockUser = payload => ({
  type: UserActionTypes.LOCK_USER,
  payload,
});

export const FetchClientById = payload => ({
  type: UserActionTypes.FETCH_CLIENT_BY_ID,
  payload,
});

export const FetchSuperAdminSuccess = payload => ({
  type: UserActionTypes.FETCH_SUPER_ADMIN_SUCCESS,
  payload,
});

export const FetchAdminSuccess = payload => ({
  type: UserActionTypes.FETCH_ADMIN_SUCCESS,
  payload,
});

export const FetchVggUserSuccess = payload => ({
  type: UserActionTypes.FETCH_VGG_USER_SUCCESS,
  payload,
});

export const FetchClientAdminSuccess = payload => ({
  type: UserActionTypes.FETCH_CLIENT_ADMIN_SUCCESS,
  payload,
});

export const FetchClientUserSuccess = payload => ({
  type: UserActionTypes.FETCH_CLIENT_USER_SUCCESS,
  payload,
});

export const SetUserTabKey = payload => ({
  type: UserActionTypes.SET_USER_TAB_KEY,
  payload,
});
