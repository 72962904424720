import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { Select } from 'antd';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';

import { Button } from '../../../shared';
import { SaveNewApplication } from '../../../../store/maker-checker/maker-checker-actions';
import './addApplicationModal.scss';
import OtpModal from '../../../shared/otpModal/otpModal';

const AddApplicationModal = ({ openModal, closeModal, fetchingApplications, processing, applicationsList }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [formPayload, setFormPayload] = useState({
    name: null,
    code: null,
    description: null,
  });
  const [show, setShow] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [comment, setComment] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClientSelect = e => {
    const { value, label } = applicationsList.find(val => val.value === e);
    setFormPayload({ ...formPayload, name: label, code: value });
  };

  const handleDescriptionChange = e => {
    const { value } = e.target;
    setFormPayload({ ...formPayload, description: value });
  };

  const handleFilter = (input, option) => {
    console.log(input);
    // const lowerInput = input.toLowerCase();
    return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const handleSubmit = () => {
    console.log(formPayload);
    setShowOtp(true);
  };

  const submitForm = e => {
    dispatch(
      SaveNewApplication({
        formPayload,
        closeModal,
      })
    );
  };

  const onValidateOtp = () => {
    form.submit();
    // dispatch(SaveNewApplication({ formPayload, closeModal }));
  };

  useEffect(() => {
    openModal ? handleShow() : handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <OtpModal openModal={showOtp} closeModal={() => setShowOtp(false)} onValidateOtp={() => onValidateOtp()} />
      <Modal show={show} onHide={closeModal} className='application-modal-container'>
        <Modal.Header closeButton>New Application</Modal.Header>
        <hr className='header-border' />
        <Modal.Body>
          <AvForm className='comment-form' onValidSubmit={submitForm} ref={c => setForm(c)}>
            <div className='mb-3'>
              <label className='form-label'>Name</label>
              <Select
                showSearch
                style={{ width: '100%' }}
                loading={fetchingApplications}
                placeholder={fetchingApplications ? 'Loading Applications...' : 'Select Application'}
                optionFilterProp='children'
                onChange={handleClientSelect}
                filterOption={handleFilter}
              >
                {applicationsList &&
                  applicationsList.map((client, idx) => (
                    <Option key={idx} value={client.value}>
                      {client.label}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className='mb-3'>
              <label className='form-label'>Code</label>
              <AvInput type='text' name='code' placeholder='Selected Application Code' value={formPayload.code} disabled={true} />
            </div>
            <div>
              <label className='form-label'>Description</label>
              <AvInput
                type='text'
                name='description'
                placeholder='Enter Description'
                onChange={handleDescriptionChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'This field is required',
                  },
                }}
              />
            </div>
          </AvForm>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button outline={true} color='orange' handleClick={closeModal} className='mr-2'>
              Cancel
            </Button>
            <Button color='green' handleClick={handleSubmit} disabled={false}>
              {processing ? (
                <span>
                  Creating... <Spinner size='sm' />
                </span>
              ) : (
                'Create'
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddApplicationModal;
