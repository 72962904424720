import React from "react";
import { Table } from "reactstrap";

import TableLoader from './tableLoader';
import DashboardLoader from './dashboardLoader/dashboardLoader';
import DetailLoader from './detailLoader/detailLoader';
import { ShimmerTableRowLoader } from "../../shared";
import './loader.scss';

export default function Loader() {
  return <div>Retriving Authentication State.....</div>;
}

export const LoadingTable = () => {
  return <div className="table-loader-container"><TableLoader /></div>
}

export const LoadingTableContent = ({ colSpan }) => {
  return (
        <ShimmerTableRowLoader
          rows={10}
          columnWidths={new Array(parseInt(colSpan)).fill(0)}
          // columnWidths={new Array(parseInt(colSpan)).fill(0).map(val => "100%")}
        />
    )
}

export const LoadingDashboard = ({ coLSpan }) => {
  return <div className="table-loader-container"><DashboardLoader /></div>
}

export const LoadingDetails = ({ coLSpan }) => {
  return <div className="table-loader-container detail-loader"><DetailLoader /></div>
}

export const LoadingDropdownItems =({name}) => {
  return <span className="loading-dropdown">Loading {name} <i className="fa fa-spin fa-spinner"></i></span>
}