import React from 'react';
import { ListGroup } from 'react-bootstrap';
import AccordionComponent from '../../../shared/accordion/accordion';
import './userGroups.scss';

const UserGroups = (props) => {
  const {
    data: {
      vggSuperAdminClaims,
      vggAdminClaims,
      vggUserClaims,
      clientAdminClaims,
      clientUserClaims,
    },
  } = props;
  return (
    <div className='user-groups-container'>
      <AccordionComponent
        accordionLable='Vgg Super Admin'
        id='vgg_super_adm'
        clickHeader={true}
      >
        <ListGroup>
          {vggSuperAdminClaims.length ? (
            vggSuperAdminClaims.map((item) => (
              <ListGroup.Item key={item}>{item}</ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No claim available</ListGroup.Item>
          )}
        </ListGroup>
      </AccordionComponent>
      <AccordionComponent
        accordionLable='Vgg Admin'
        id='vgg_adm'
        clickHeader={true}
      >
        <ListGroup>
          {vggAdminClaims.length ? (
            vggAdminClaims.map((item) => (
              <ListGroup.Item key={item}>{item}</ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No claim available</ListGroup.Item>
          )}
        </ListGroup>
      </AccordionComponent>
      <AccordionComponent
        accordionLable='Vgg User'
        id='vgg_user'
        clickHeader={true}
      >
        <ListGroup>
          {vggUserClaims.length ? (
            vggUserClaims.map((item) => (
              <ListGroup.Item key={item}>{item}</ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No claim available</ListGroup.Item>
          )}
        </ListGroup>
      </AccordionComponent>
      <AccordionComponent
        accordionLable='Client Admin'
        id='client_admin'
        clickHeader={true}
      >
        <ListGroup>
          {clientAdminClaims.length ? (
            clientAdminClaims.map((item) => (
              <ListGroup.Item key={item}>{item}</ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No claim available</ListGroup.Item>
          )}
        </ListGroup>
      </AccordionComponent>
      <AccordionComponent
        accordionLable='Client User'
        id='client_user'
        clickHeader={true}
      >
        <ListGroup>
          {clientUserClaims.length ? (
            clientUserClaims.map((item) => (
              <ListGroup.Item key={item}>{item}</ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No claim available</ListGroup.Item>
          )}
        </ListGroup>
      </AccordionComponent>
    </div>
  );
};

export default UserGroups;
