import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ClaimApplications from "./claimApplications/claimApplications";
import ClaimGroups from "./claimGroups/claimGroups";
import ClaimPermissions from "./claimPermissions/claimPermissions";
import ClaimProfile from "./claimProfiles/claimProfile";
import ClaimRoles from "./claimRoles/claimRoles";
import "./userClaims.scss";

const UserClaims = ({ data }) => {
  const [key, setKey] = useState("Applications");

  const filterClaims = (pattern) => {
    var filtered = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i].Type.toLowerCase().match(pattern)) {
        filtered.push(data[i]);
      }
    }

    return filtered;
  };

  return (
    <div className="user-info-container">
      <div className="row">
        <div className="col">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="Applications" title="Applications">
              <ClaimApplications applications={filterClaims(/application/)} />
            </Tab>
            <Tab eventKey="Profile" title="Profile">
              <ClaimProfile
                profiles={filterClaims(
                  /(?=^(?:(?!application).)*$)(?=^(?:(?!role).)*$)(?=^(?:(?!permission).)*$)/
                )}
              />
            </Tab>
            <Tab eventKey="Roles" title="Roles">
              <ClaimRoles roles={filterClaims(/role/)} />
            </Tab>
            <Tab eventKey="Permissions" title="Permissions">
              <ClaimPermissions permissions={filterClaims(/permission/)} />
            </Tab>
            <Tab eventKey="Groups" title="Groups">
              <ClaimGroups groups={filterClaims(/group/)} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default UserClaims;
