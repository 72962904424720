import { all, call, put, takeEvery } from "redux-saga/effects";
import { ssoApi } from "../../axios/interceptor";

import {
  DashboardActionTypes,
  fetchDailySuccess,
  fetchHistorySuccess,
  fetchHourlySuccess,
  fetchSummarySuccess
} from "./dashboard-actions";

export function* FetchHourAsync(action) {
  try {
    const response = yield call(
      ssoApi.get,
      `/apilog/piechartdata?hours=${action.payload}`
    );
    yield put(fetchHourlySuccess(response));
  } catch (err) {
  }
}

export function* FetchDailyAsync() {
  try {
    const response = yield call(
      ssoApi.get,
      `/apilog/piechartdata/today`
    );
    yield put(fetchDailySuccess(response));
  } catch (err) {
  }
}

export function* FetchHistoryAsync() {
  try {
    const response = yield call(
      ssoApi.get,
      `/apilog/piechartdata/history`
    );
    yield put(fetchHistorySuccess(response));
  } catch (err) {
  }
}

export function* FetchSummaryAsync() {
  try {
    const response = yield call(
      ssoApi.get,
      `/dashboard/get`
    );
    yield put(fetchSummarySuccess(response));
  } catch (err) {
  }
}

export function* WatchFetchSummaryflow() {
  yield takeEvery(
    DashboardActionTypes.FETCH_SUMMARY_DATA,
    FetchSummaryAsync
  );
}


export function* WatchFetchDailyflow() {
  yield takeEvery(
    DashboardActionTypes.FETCH_DAILY_DATA,
    FetchDailyAsync
  );
}

export function* WatchFetchHistoryflow() {
  yield takeEvery(
    DashboardActionTypes.FETCH_HISTORY_DATA,
    FetchHistoryAsync
  );
}


export function* WatchFetchHourlyflow() {
  yield takeEvery(
    DashboardActionTypes.FETCH_HOURLY_DATA,
    FetchHourAsync
  );
}


export default function* dashboardSagas() {
  yield all([
    call(WatchFetchHourlyflow),
    call(WatchFetchDailyflow),
    call(WatchFetchHistoryflow),
    call(WatchFetchSummaryflow)
  ]);
}

