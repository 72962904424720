import { all, call, put, takeEvery } from "redux-saga/effects";
import { ssoApi } from "../../axios/interceptor";

import { ProfileActionTypes, fetchProfileSuccess, passwordChangeSuccess, updateProfileSuccess } from "./profile-action";

export function* FetchProfileAsync(action) {
  try {
    const response = yield call(ssoApi.get, `/account/getuser?userId=${action.payload}`);

    if (response.status === 200) {
      yield put(fetchProfileSuccess(response.data.Response));
    }
  } catch (err) {}
}

export function* WatchFetchProfileflow() {
  yield takeEvery(ProfileActionTypes.FETCH_PROFILE, FetchProfileAsync);
}

export function* UpdateProfileAsync(action) {
  try {
    const response = yield call(ssoApi.post, `/account/update`, action.payload);
    yield put(updateProfileSuccess(response));
  } catch (err) {}
}

export function* WatchUpdateProfileflow() {
  yield takeEvery(ProfileActionTypes.UPDATE_PROFILE, UpdateProfileAsync);
}

export function* PasswordChangeAsync(action) {
  try {
    const response = yield call(ssoApi.post, `/account/changepassword`, action.payload);
    yield put(passwordChangeSuccess(response));
  } catch (err) {}
}

export function* WatchPasswordChangeflow() {
  yield takeEvery(ProfileActionTypes.PASSWORD_CHANGE, PasswordChangeAsync);
}

export default function* profileSagas() {
  yield all([call(WatchFetchProfileflow), call(WatchUpdateProfileflow), call(WatchPasswordChangeflow)]);
}
