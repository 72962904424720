export const ApplicationsActionTypes = {
  FETCHING_BUSINESS_UNIT: "FETCHING_BUSINESS_UNIT",
  FETCHING_BUSINESS_UNIT_SUCCESS: "FETCHING_BUSINESS_UNIT_SUCCESS",
  FETCHING_APPLICATION_LIST: "FETCHING_APPLICATION_LIST",
  FETCHING_APPLICATION_LIST_SUCCESS: "FETCHING_APPLICATION_LIST_SUCCESS",
  FETCHING_DATA: "FETCHING_APPLICATIONS",
  FETCHING_APPLICATION_SUCCESS: "FETCHING_APPLICATION_SUCCESS",
  FETCHING_APPLICATION_ERROR: "FETCHING_APPLICATION_ERROR",
  APPLICATION_DETAILS: "APPLICATION_DETAILS",
  FETCH_APPLICATION_REPORT: "FETCH_APPLICATION_REPORT",
  FETCH_APPLICATION_USER: "FETCH_APPLICATION_USER",
  FETCH_APPLICATION_USERS_ACCOUNT: "FETCH_APPLICATION_USERS_ACCOUNT",
  FETCH_APPLICATION_REPORT_SUCCESS: "FETCH_APPLICATION_REPORT_SUCCESS",
  FETCH_APPLICATION_USER_SUCCESS: "FETCH_APPLICATION_USER_SUCCESS",
  FETCH_APPLICATION_USER_ACCOUNT_SUCCESS: "FETCH_APPLICATION_USER_ACCOUNT_SUCCESS",
  FETCH_APPLICATION_USER_CLEARANCE_SUCCESS: "FETCH_APPLICATION_USER_CLEARANCE_SUCCESS",
  FETCH_APPLICATION_USER_CLEARANCE: "FETCH_APPLICATION_USERS_ACCOUNT",
  CREATE_BUSINESS_UNIT: "CREATE_BUSINESS_UNIT",
  CREATE_BUSINESS_UNIT_SUCCESS: "CREATE_BUSINESS_UNIT_SUCCESS",
  CREATE_APPLICATION: "CREATE_APPLICATION",
  CREATE_APPLICATION_SUCCESS: "CREATE_APPLICATION_SUCCESS",
  CREATE_MAP_APPLICATION: "CREATE_MAP_APPLICATION",
  CREATE_MAP_APPLICATION_SUCCESS: "CREATE_MAP_APPLICATION_SUCCESS"
};

export const fetchBusinessUnit = () => ({
  type: ApplicationsActionTypes.FETCHING_BUSINESS_UNIT,
});

export const fetchApplicationList = () => ({
  type: ApplicationsActionTypes.FETCHING_APPLICATION_LIST,
});

export const fetchBusinessUnitSuccess = (payload) => ({
  type: ApplicationsActionTypes.FETCHING_BUSINESS_UNIT_SUCCESS,
  payload: payload,
});

export const fetchApplicationListSuccess = (payload) => ({
  type: ApplicationsActionTypes.FETCHING_APPLICATION_LIST_SUCCESS,
  payload: payload,
});

export const fetchApplications = () => ({
  type: ApplicationsActionTypes.FETCHING_DATA,
});

export const fetchApplicationsSuccess = (payload) => ({
  type: ApplicationsActionTypes.FETCHING_APPLICATION_SUCCESS,
  payload: payload,
});

export const fetchApplicationUserSuccess = (payload) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_USER_SUCCESS,
  payload: payload,
});

export const fetchApplicationReportSuccess = (payload) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_REPORT_SUCCESS,
  payload: payload,
});

export const fetchApplicationUserAccount = (payload) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_USERS_ACCOUNT,
  payload: payload,
});

export const fetchApplicationUserAccountSuccess = (payload) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_USER_ACCOUNT_SUCCESS,
  payload: payload,
});


export const fetchApplicationUserClearance = (payload) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_USER_CLEARANCE,
  payload: payload,
});

export const fetchApplicationUserClearanceSuccess = (payload) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_USER_CLEARANCE_SUCCESS,
  payload: payload,
});


export const applicationDetails = (payload) => ({
  type: ApplicationsActionTypes.APPLICATION_DETAILS,
  payload: payload,
});

export const fetchApplicationUser = (payload) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_USER,
  payload
});

export const fetchApplicationReport = (payload) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_REPORT,
  payload
});

export const createBusinessUnit = (payload) => ({
  type: ApplicationsActionTypes.CREATE_BUSINESS_UNIT,
  payload: payload
});

export const createBusinessUnitSuccess = (payload) => ({
  type: ApplicationsActionTypes.CREATE_BUSINESS_UNIT_SUCCESS,
  payload: payload,
});

export const createApplication = (payload) => ({
  type: ApplicationsActionTypes.CREATE_APPLICATION,
  payload: payload
});

export const createApplicationSuccess = (payload) => ({
  type: ApplicationsActionTypes.CREATE_APPLICATION_SUCCESS,
  payload: payload,
});

export const createMapApplication = (payload) => ({
  type: ApplicationsActionTypes.CREATE_MAP_APPLICATION,
  payload: payload
});

export const createMapApplicationSuccess = (payload) => ({
  type: ApplicationsActionTypes.CREATE_MAP_APPLICATION_SUCCESS,
  payload: payload,
});
