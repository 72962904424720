const { MakerCheckerActionTypes } = require('./maker-checker-actions');
const initialState = {
  fetchingData: false,
  processing: false,
  creatingClaim: false,
  claimCreated: false,
  creatingLevels: false,
  levelsCreated: false,
  workflowSaved: false,
  fetchingClient: false,
  fetchingApplications: false,
  fetchingWorkflowList: false,
  fetchingTransactions: false,
  transactions: [],
  workflowList: [],
  workflows: [],
  workflow: null,
  workflowLevels: null,
  clientData: null,
  allClients: [],
  clientClaimsList: null,
  createFlowResponse: null,
  saveFlowData: null,
  applications: [],
  applicationsList: [],
};

const makerCheckerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MakerCheckerActionTypes.FETCHING_DATA:
      return {
        ...state,
        fetchingData: payload,
      };
    case MakerCheckerActionTypes.FETCH_APPLICATION_LIST:
      return {
        ...state,
        fetchingApplications: true,
        applications: [],
        applicationsList: [],
      };
    case MakerCheckerActionTypes.FETCH_APPLICATION_LIST_SUCCESS:
      return {
        ...state,
        fetchingApplications: false,
        applications: payload,
        applicationsList: payload.map(v => ({
          label: v.ClientName,
          value: v.ClientId,
        })),
      };
    case MakerCheckerActionTypes.FETCH_ALL_WORKFLOWS_SUCCESS:
      return {
        ...state,
        workflows: payload,
      };
    case MakerCheckerActionTypes.FETCHING_WORKFLOW_LIST:
      return {
        ...state,
        fetchingWorkflowList: payload,
      };
    case MakerCheckerActionTypes.FETCH_WORKFLOW_LIST:
      return {
        ...state,
        fetchingWorkflowList: true,
        workflowList: [],
      };
    case MakerCheckerActionTypes.FETCH_WORKFLOW_LIST_SUCCESS:
      return {
        ...state,
        fetchingWorkflowList: false,
        workflowList: payload.map(v => ({
          label: v.name,
          value: v.code,
        })),
      };
    case MakerCheckerActionTypes.FETCHING_TRANSACTIONS:
      return {
        ...state,
        fetchingTransactions: payload,
      };
    case MakerCheckerActionTypes.FETCH_TRANSACTION_DATA:
      return {
        ...state,
        fetchingTransactions: true,
        transactions: [],
      };
    case MakerCheckerActionTypes.FETCH_TRANSACTION_DATA_SUCCESS:
      return {
        ...state,
        fetchingTransactions: false,
        transactions: payload,
      };
    case MakerCheckerActionTypes.SAVE_WORKFLOW_SUCCESS:
      return {
        ...state,
        workflowSaved: payload,
      };
    case MakerCheckerActionTypes.SAVED_WORKFLOW_DATA:
      return {
        ...state,
        saveFlowData: payload,
      };
    case MakerCheckerActionTypes.PROCESSING_WORKFLOW_DATA:
      return {
        ...state,
        processing: payload,
      };
    case MakerCheckerActionTypes.FETCH_WORKFLOW_DETAILS_SUCCESS:
      return {
        ...state,
        workflow: payload,
      };
    case MakerCheckerActionTypes.FETCH_WORKFLOW_CLAIM_SUCCESS:
      return {
        ...state,
        workflowClaim: payload,
      };
    case MakerCheckerActionTypes.FETCH_WORKFLOW_LEVELS_SUCCESS:
      return {
        ...state,
        workflowLevels: payload,
      };
    case MakerCheckerActionTypes.FETCHING_CLIENT_DATA:
      return {
        ...state,
        fetchingClient: payload,
      };
    case MakerCheckerActionTypes.FETCHING_APPLICATIONS:
      return {
        ...state,
        fetchingApplications: payload,
      };
    case MakerCheckerActionTypes.FETCH_CLIENT_DATA:
      return {
        ...state,
        fetchingClient: true,
        clientData: null,
        allClients: [],
      };
    case MakerCheckerActionTypes.FETCH_CLIENT_DATA_SUCCESS:
      return {
        ...state,
        fetchingClient: false,
        clientData: payload.map(v => ({
          label: v.name,
          value: v.code,
        })),
        allClients: payload,
      };
    case MakerCheckerActionTypes.FETCH_CLIENT_CLAIMS:
      return {
        ...state,
        clientClaimsList: payload,
      };
    case MakerCheckerActionTypes.CREATING_MAKER_CLAIM:
      return {
        ...state,
        creatingClaim: payload,
      };
    case MakerCheckerActionTypes.CREATE_MAKER_CLAIM_SUCCESS:
      return {
        ...state,
        claimCreated: payload,
      };
    case MakerCheckerActionTypes.CREATING_CHECKER_LEVELS:
      return {
        ...state,
        creatingLevels: payload,
      };
    case MakerCheckerActionTypes.CREATE_CHECKER_LEVELS_SUCCESS:
      return {
        ...state,
        levelsCreated: payload,
      };
    case MakerCheckerActionTypes.SAVE_TRANSACTION_APPROVAL:
      return {
        ...state,
        processing: true,
      };
    case MakerCheckerActionTypes.SAVE_NEW_APPLICATION:
      return {
        ...state,
        processing: true,
      };
    default:
      return state;
  }
};

export default makerCheckerReducer;
