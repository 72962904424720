import React, { useState, useEffect } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { fetchBusinessUnit, fetchApplicationList, createMapApplication } from '../../../store/applications/applications-actions';
// import ApplicationModal from './applicationForm/applicationForm';
import BreadCrumb from '../../shared/breadCrumb/breadCrumb';
import { Urls } from '../../../utils/urls';
import { PageTitle } from '../../shared/titlePage/titlePage';
import { Colors } from '../../../utils/colors';
import Button from '../../shared/button/Button';

import './newApplication.scss';
import { useTitle } from '../../../utils/useTitle';

const NewClient = () => {
  useTitle('Create Application');
  const [form, setForm] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [createdStatus, setCreatedStatus] = useState(false);

  const [modalShow, setModalShow] = React.useState(false);
  const select = useSelector(state => {
    return {
      status: state.clients && state.clients.newClient && select.clients.newClient.status,
      clientList: state.clients && state.clients.clients.data && state.clients.clients.data.Response,
      applicationList: state.application && state.application.applications && state.application.applicationList && state.application.applicationList.data.Response,
      newMapApplicationStatus: state.application && state.application.newMapApplication && state.application.newMapApplication.status,
    };
  });

  const { status, clientList, applicationList, newMapApplicationStatus } = select;

  if (status && (status === 201 || status === 200)) {
    history.push(Urls.Clients);
    // dispatch(resetNewCreatedClient());
  }

  const submitForm = (event, value) => {
    value['ClientId'] = [value['ClientId']];
    setCreatedStatus(true);
    dispatch(createMapApplication(value));
  };

  useEffect(() => {
    dispatch(fetchBusinessUnit());
    dispatch(fetchApplicationList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createdStatus && newMapApplicationStatus && newMapApplicationStatus === 200) {
      form.reset();
      setCreatedStatus(false);
      history.push(Urls.Applications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMapApplicationStatus]);
  return (
    <section className='row new-client-container'>
      <div className='col'>
        <BreadCrumb title='Application' titleLink={Urls.Applications} sub1='Create a Application' />
        <div className='content-header'>
          <PageTitle url={Urls.Applications} title='Application' showArrow={true} />
        </div>

        <div className='card card-content'>
          <div className='card-body'>
            <AvForm onValidSubmit={submitForm} ref={c => setForm(c)}>
              <div className='row'>
                <div className='form-group col-12'>
                  <label htmlFor='Application'>Select an Application</label>
                  <AvField
                    type='select'
                    className='form-control'
                    name='ApplicationName'
                    id='Application'
                    validate={{
                      required: { value: true, errorMessage: 'Please select one' },
                    }}
                  >
                    <option></option>
                    {applicationList &&
                      applicationList.map(val => {
                        return <option key={val.Id}>{val.ApplicationName}</option>;
                      })}
                  </AvField>
                  <div className='new-addon' onClick={() => setModalShow(true)}>
                    Add New Application
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-12'>
                  <label htmlFor='clientId'>Select a Client Id</label>
                  <AvField
                    type='select'
                    className='form-control'
                    name='ClientId'
                    id='clientId'
                    validate={{
                      required: { value: true, errorMessage: 'Please select one' },
                    }}
                  >
                    <option></option>
                    {clientList &&
                      clientList.map(val => {
                        return (
                          <option key={val.ClientId} value={val.ClientId}>
                            {val.ClientName}
                          </option>
                        );
                      })}
                  </AvField>
                </div>
              </div>
            </AvForm>

            <div className='row'>
              <div className='col-12 text-center mt-4'>
                <Button size='sm' color={Colors.GREEN} handleClick={() => form.submit()}>
                  Create Application
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewClient;
