import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { fetchAccessLogs, accessLogDetails } from '../../store/access-logs/access-logs-actions';
import { LoadingTable } from '../shared/loader/Loader';
import { Paginator, ShimmerTableRowLoader } from '../shared';
import Datepicker from '../shared/datepicker/datepicker';

import moment from 'moment';
import { ViewAccessLogPage } from '../../utils/urls';
import { Colors } from '../../utils/colors';
import Button from '../shared/button/Button';
import { Table } from 'reactstrap';
import './access-logs.scss';
import { useTitle } from '../../utils/useTitle';

const AccessLogs = () => {
  useTitle('Access Logs');
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRow, setSelectedRow] = useState('');

  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

  const isFirstUpdate = useRef(true);
  const select = useSelector(state => {
    return {
      accessLogs: state.accessLogs && state.accessLogs.accessLogs.data,
      isLoading: state.accessLogs && state.accessLogs.isLoading,
    };
  });

  const handleDateChange = value => {
    let customDate = '';
    let dateValue = '';

    if (value === 'fromDate') {
      customDate = document.getElementById('from-date-picker-access');
      dateValue = customDate.value;
      setFromDate(dateValue);
    }

    if (value === 'toDate') {
      customDate = document.getElementById('to-date-picker-access');
      dateValue = customDate.value;
      setToDate(dateValue);
    }
  };

  const handlePageClick = data => {
    console.log(data);
    let selected = data.selected;
    setPageNo(selected + 1);
  };

  // search table
  const handleSearch = e => {
    e.preventDefault();
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleSelectRow = e => {
    e.preventDefault();
    const { value } = e.target;
    setSelectedRow(value);
  };

  const search = rows => {
    const columns = ['Username', 'ClientId', 'IsSuccessful', 'Timestamp', 'IpAddress'];
    if (!selectedRow) {
      return rows.filter(row =>
        columns.some(column => {
          if (row[column] !== null) {
            return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
          }
        })
      );
    }
    return rows.filter(row => row[`${selectedRow}`].toString().toLowerCase().indexOf(searchTerm) > -1);
  };

  // search in endpoint
  const searchAccessLogs = () => {
    dispatch(fetchAccessLogs({ pageSize, pageNo, fromDate, toDate }));
  };

  const viewAccessLogs = details => {
    history.push(ViewAccessLogPage(details.Id));
    dispatch(accessLogDetails(details));
  };

  // for updating page Number
  useEffect(() => {
    if (!isFirstUpdate.current) {
      searchAccessLogs();
    }
  }, [pageNo]);

  useEffect(() => {
    isFirstUpdate.current = false;
    dispatch(fetchAccessLogs({ pageSize, pageNo, fromDate, toDate }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className='row access-container'>
      <div className='col'>
        <div className='content-header d-flex justify-content-between'>
          <span>Access Logs</span>
        </div>
        <div className='card card-content'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='col-lg-4 col-md-12 col-sm-12'>
                <div className='row'>
                  {/* <div className="col-7"> */}
                  <div className='sp-pt--48'>
                    <div className='input'>
                      <input className='input__text' type='text' placeholder='Search' value={searchTerm} onChange={handleSearch} />
                      <button className='input__btn'>
                        <i className='ri-search-line'></i>
                      </button>
                    </div>
                  </div>
                  {/* </div>
                                    <div className="col-3">
                                        <select
                                            className="input__text p-0"
                                            onChange={handleSelectRow}
                                            value={selectedRow}
                                        >
                                            <option value="">All</option>
                                            <option value="Username">Username</option>
                                            <option value="ClientId">Client Id</option>
                                            <option value="IsSuccessful">Status</option>
                                            <option value="Timestamp">Date</option>
                                            <option value="IpAddress">Ip Address</option>
                                        </select>
                                    </div> */}
                </div>
              </div>
              <div className='col-lg-8 col-md-12 col-sm-12 datepicker-div'>
                <Datepicker
                  className='col-md-4 col-sm-5  mb-2'
                  duration='Start&nbsp;Date:&nbsp;'
                  name='fromDate'
                  mode='single'
                  dateFormat='Y-m-d'
                  maxDate='today'
                  defaultDate={fromDate}
                  id='from-date-picker-access'
                  onChange={() => handleDateChange('fromDate')}
                />

                <Datepicker
                  className='col-md-4 col-sm-5 mb-2'
                  duration='End&nbsp;Date:&nbsp;'
                  name='toDate'
                  mode='single'
                  dateFormat='Y-m-d'
                  maxDate='today'
                  minDate={fromDate}
                  id='to-date-picker-access'
                  defaultDate={fromDate}
                  onChange={() => handleDateChange('toDate')}
                />

                <span className='col-md-4 col-sm-2 btn-div'>
                  <Button size='sm' color='green' handleClick={searchAccessLogs}>
                    Search
                  </Button>
                </span>
              </div>
            </div>

            <div className='row table-container'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Client Id</th>
                    <th>Username</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Ip Address</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {select.isLoading ? (
                  <ShimmerTableRowLoader rows={pageSize} columnWidths={['5%', '15%', '30%', '10%', '15%', '15%', '10%']} />
                ) : (
                  <tbody>
                    {select.accessLogs &&
                      (search(select.accessLogs.Data).length ? (
                        search(select.accessLogs.Data).map((value, i) => {
                          return (
                            <tr key={value.Id}>
                              {pageNo > 1 ? <td width='5%'>{(pageNo - 1) * 10 + (i + 1)}</td> : <td width='5%'>{i + 1}</td>}
                              <td width='15%'>{value.ClientId}</td>
                              <td width='30%'>{value.Username}</td>
                              <td width='10%'>{value.IsSuccessful ? 'Successful' : 'Failed'}</td>
                              <td width='15%'>{value.Timestamp}</td>
                              <td width='15%'>{value.IpAddress ? value.IpAddress : 'Not found'}</td>
                              <th width='10%'>
                                <span className='green-color cursor mr-2' onClick={() => viewAccessLogs(value)}>
                                  View Details
                                </span>
                              </th>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan='7' className='font-weight-bold text-center pt-4' style={{ display: 'table-cell' }}>
                            No data available
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </Table>
              <Paginator
                currentPage={pageNo}
                perPage={pageSize}
                pageCount={select.accessLogs?.Paging?.PageCount}
                totalItems={select.accessLogs?.Paging?.TotalRecordCount}
                handlePageClick={handlePageClick}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccessLogs;
