import React from "react";

import "./secretDetail.scss";

const SecretDetail = ({ details }) => {
  return (
    <div className="scope-claims-container">
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Value</th>
              <th scope="col">Description</th>
              <th scope="col">Expiration</th>
            </tr>
          </thead>
          <tbody>
            {details &&
              details?.map((value, i) => {
                return (
                  <tr key={value.Id}>
                    <td>{value.Type}</td>
                    <td>{value.Value}</td>
                    <td>{value.Description}</td>
                    <td>{value.Expiration}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SecretDetail;
