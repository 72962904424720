import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { Table } from 'reactstrap';

import BreadCrumb from '../../shared/breadCrumb/breadCrumb';
import { PageTitle } from '../../shared/titlePage/titlePage';
import { Urls } from '../../../utils/urls';
import { useTitle } from '../../../utils/useTitle';

const ViewAccessLogs = () => {
  useTitle('Access Log Details');
  const select = useSelector(state => {
    return { accessLogs: state.accessLogs && state.accessLogs.details };
  });

  const handleBackClicked = e => {
    e.preventDefault();
    history.push(Urls.AccessLogs);
  };

  const history = useHistory();
  if (!select.accessLogs) {
    history.push(Urls.AccessLogs);
    return null;
  }

  return (
    <section className='client-detail-container'>
      <div className='col'>
        <BreadCrumb title='Access Log' titleLink={Urls.AccessLogs} sub1='Access Log Details' />
        <div className='content-header d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex justify-content-center align-items-center'>
            <a href='!#/' onClick={handleBackClicked} className='mr-2 goback'>
              <i className='ri-arrow-left-line'></i>
            </a>{' '}
            <div className='sp-disp-heading'>Access Log Information</div>
          </div>
        </div>
        <div className='card card-content mb-3'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='col'>
                <Tabs id='controlled-tab-example' activeKey='Details'>
                  <Tab eventKey='Details' title='Details'>
                    <div className='user-info-container'>
                      <div className='row'>
                        <div className='col-sm-12 col-md-6'>
                          <Table responsive borderless>
                            {select.accessLogs ? (
                              <tbody>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Username:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.accessLogs.Username || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    ClientId:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.accessLogs.ClientId || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Ip Address:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.accessLogs.IpAddress || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Time Stamp:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.accessLogs.Timestamp || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Grant Type:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.accessLogs.GrantType || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Status:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.accessLogs.IsSuccessful ? 'Successful' : 'Failed'}
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan='2' className='font-weight-bold text-center pt-4'>
                                    Not data available
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </Table>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewAccessLogs;
