import React from 'react';
import Button from '../shared/button/Button';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info,
    });
  }

  handleButtonClick = () => {
    this.props.history.replace('/');
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '20px' }}>
          <h1>Oops, something went wrong :(</h1>
          <p>The error: {this.state.error.toString()}</p>
          <Button icon labelPosition='left' handleClick={this.handleButtonClick}>
            GO BACK HOME
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
