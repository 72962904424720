import { all, call, put, takeEvery } from "redux-saga/effects";
import { ssoApi } from "../../axios/interceptor";

import {
  AuditActionTypes,
  fetchAuditSuccess,
} from "./audit-actions";

export function* FetchAuditAsync(action) {
  let pageSize = action.payload.pageSize;
    let pageNo = action.payload.pageNo;
    let startDate = action.payload.fromDate;
    let endDate = action.payload.toDate;
    
  try {
    const response = yield call(
      ssoApi.get,
      `audit/list?pageSize=${pageSize}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}`
    );
    yield put(fetchAuditSuccess(response));
  } catch (err) {
    console.log(err);
  }
}

export function* WatchFetchAuditflow() {
  yield takeEvery(
    AuditActionTypes.FETCHING_DATA,
    FetchAuditAsync
  );
}



export default function* auditSaga() {
  yield all([call(WatchFetchAuditflow) ]);
}
