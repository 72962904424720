import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import { Button, ProgressBar } from '../../shared';
import './defineWorkflow.scss';
import { SaveWorkflow, FetchWorkflowDetails, FetchClientData, FetchWorkflowDetailsSuccess, ProcessingWorkflowData } from '../../../store/maker-checker/maker-checker-actions';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../../shared/breadCrumb/breadCrumb';
import { Urls } from '../../../utils/urls';
import InfoTip from '../../shared/infoTip/infoTip';
import { Select } from 'antd';
import OtpModal from '../../shared/otpModal/otpModal';
import { useTitle } from '../../../utils/useTitle';
import ShowToast from '../../../utils/showToast';
import { formatNumberInput } from '../../../utils/formatNumberInput';

const DefineWorkflow = ({ match: { params } }) => {
  const { Option } = Select;
  const history = useHistory();
  const dispatch = useDispatch();
  const { processing, workflowSaved, workflow, clientData } = useSelector(state => state.makerChecker);
  const [form, setForm] = useState([]);
  const [formData, setFormData] = useState({
    name: null,
    code: null,
    level: null,
    client: null,
  });
  const [showOtp, setShowOtp] = useState(false);
  const [client, setClient] = useState('');
  useTitle(`Maker-Checker ${workflow ? 'Edit' : 'Create'} Workflow Step One`);

  const handleCancelWorkflow = e => {
    e.preventDefault();
    history.push(Urls.MakerChecker);
  };

  const readyToSubmit = data => {
    const a = Object.keys(data).filter(k => {
      if (data[k] === '' || data[k] === undefined || data[k] === null) {
        return k;
      }
    });
    return !a.length;
  };

  const handleClientChange = e => {
    console.log(e);
    setClient(e);
    setFormData({ ...formData, client: e });
  };
  const handleSubmitWorkflow = e => {
    if (readyToSubmit(formData)) {
      setShowOtp(true);
    } else {
      ShowToast({
        type: 'error',
        message: 'Please fill all required fields',
      });
    }
  };

  const handleFilter = (input, option) => {
    console.log(input);
    return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const updateProgressBar = () => {
    if (workflowSaved || workflow) {
      return 30;
    }
    return 0;
  };

  const handleValueChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log({ name });
    console.log({ value });
  };

  const onValidateOtp = () => {
    form.submit();
  };

  const submitForm = (e, formData) => {
    const inEditMode = !!workflow;
    const data = { ...formData, applicationCode: client };
    dispatch(SaveWorkflow({ data, history, inEditMode }));
  };
  useEffect(() => {
    dispatch(ProcessingWorkflowData(false));
    dispatch(FetchClientData({ type: 'a', pageSize: 20 }));
    if (params.code && params.clientId && !workflow) {
      dispatch(
        FetchWorkflowDetails({
          workflowCode: params.code,
          applicationCode: params.clientId,
        })
      );
    }
    return () => {
      dispatch(FetchWorkflowDetailsSuccess(null));
      dispatch(ProcessingWorkflowData(false));
    };
  }, []);

  useEffect(() => {
    setClient(params?.clientId);
    if (workflow) {
      setFormData({
        name: workflow?.name,
        code: params.code,
        level: workflow?.level,
        client: params.clientId,
      });
    }
  }, [workflow]);

  return (
    <section className='row define-workflow-container'>
      <OtpModal openModal={showOtp} closeModal={() => setShowOtp(false)} onValidateOtp={() => onValidateOtp()} />
      <div className='col'>
        <BreadCrumb title='Maker Checker' titleLink={Urls.MakerChecker} sub1={`${workflow ? 'Edit' : 'Create'} Workflow Step 1`} />
        <div className='content-header d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
            <a href='!#/' onClick={handleCancelWorkflow} className='mr-2 goback'>
              <i className='ri-arrow-left-line'></i>
            </a>{' '}
            <div className='sp-disp-heading'>{workflow ? 'Edit' : 'Create'} Workflow</div>
          </div>
          <div>
            <Button size='sm' outline={true} color='green' handleClick={handleCancelWorkflow} className='mr-2'>
              Cancel
            </Button>
            <Button size='sm' loading={processing} color='green' handleClick={handleSubmitWorkflow}>
              {workflow ? 'Update' : 'Next'}
            </Button>
          </div>
        </div>
        <ProgressBar bgColor='#31712D' completed={updateProgressBar()} />
        <div className='card card-content'>
          <div className='card-body'>
            <AvForm onValidSubmit={submitForm} ref={c => setForm(c)}>
              <div className='row mb-3'>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                  <label htmlFor='name'>Workflow Name</label>
                  <AvField
                    className='form-control'
                    id='name'
                    name='name'
                    value={workflow?.name}
                    placeholder='Enter Workflow Name'
                    onChange={handleValueChange}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Please enter First Name',
                      },
                    }}
                  />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                  <div className='sp-pt--48'>
                    <div className='input code-container'>
                      <label htmlFor='code'>Workflow Code</label>
                      <AvField
                        className='mb-0'
                        id='code'
                        name='code'
                        disabled={!!workflow}
                        value={workflow?.code}
                        placeholder='Enter Workflow Code'
                        onChange={handleValueChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Please enter Workflow Code',
                          },
                        }}
                      />
                      <small className='code-info'>Code must be between 3-20 characters</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                  {/* <div className='sp-pt--48'> */}
                  {/* <div className='input'> */}
                  <label className='input__label' htmlFor='applicationCode'>
                    Application
                  </label>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Select a Client'
                    optionFilterProp='children'
                    onChange={handleClientChange}
                    disabled={!!workflow}
                    value={client}
                    filterOption={handleFilter}
                  >
                    <Option></Option>
                    {clientData &&
                      clientData.map((client, idx) => (
                        <Option key={idx} value={client.value}>
                          {client.label}
                        </Option>
                      ))}
                  </Select>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                  <div className='sp-pt--48'>
                    <div className='input'>
                      <label className='input__label' htmlFor='level'>
                        Number of Levels
                        <InfoTip infoText='Specify the number of levels this workflow will pass through before approval. It cannot be reduced once created' />
                      </label>
                      <AvField
                        className='form-control'
                        type='number'
                        min={workflow?.level || 0}
                        id='level'
                        name='level'
                        value={workflow?.level}
                        placeholder='Enter Number of Levels'
                        onChange={handleValueChange}
                        onKeyDown={formatNumberInput}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Please specify Number of Levels',
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AvForm>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DefineWorkflow;
