import { UserManager, WebStorageStateStore } from 'oidc-client';
import { CLIENT_ID, RESPONSE_TYPE, SCOPE, SSO_URL } from '../constants/app-constant';

export const clientIdentityConfig = {
  client_id: CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  response_type: RESPONSE_TYPE,
  scope: SCOPE,
  authority: SSO_URL,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),

  accessTokenExpiringNotificationTime: 30,
  monitorSession: true,
};

const clientIdentity = new UserManager(clientIdentityConfig);

export default clientIdentity;
