import React from "react";
import { Route, Redirect } from "react-router-dom";

/**
 * PrivateRoute locks a route unless the isAuthenticated prop is true
 *
 * @param {Bool} isAuthenticated - if true, renders `compenent` prop. If false, redirects to `redirect` location
 * @param {Component} component - Component to render when accessible
 * @param {String} redirect - path to redirect to when inaccessible
 */
const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  redirect,
  ...rest
}) => (
  <Route
    {...rest}
    render={(routeProps) =>
      isAuthenticated() ? (
        <Component {...routeProps} />
      ) : (
        <Redirect to={redirect} />
      )
    }
  />
);

export default PrivateRoute;
