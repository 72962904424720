import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { fetchOtp, clearOtp } from "../../../store/otp/otp-actions";
import { Colors } from "../../../utils/colors";
import Button from "../../shared/button/Button";

import "./otpModal.scss";

const OtpModal = ({ openModal, closeModal, onValidateOtp, top }) => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({});

  const dispatch = useDispatch();
  const select = useSelector(state => {
    return {
      otpResponse: state.otp && state.otp.otp && state.otp.otp.data,
      isLoading: state.otp && state.otp.isLoading,
    };
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitOtp = (event, value) => {
    dispatch(fetchOtp(value.otp));
    // form.reset();
  };

  useEffect(() => {
    openModal ? handleShow() : handleClose();
  }, [openModal]);

  useEffect(() => {
    if (select.otpResponse && select.otpResponse === true) {
      onValidateOtp();
      dispatch(clearOtp());
      setShow(false);
      closeModal();
    }
  });

  return (
    <Modal centered={top ? false : true} show={show} onHide={closeModal} className="modal-container">
      <Modal.Header closeButton>
        <div className="title">OTP Confirmation</div>
      </Modal.Header>
      <Modal.Body>
        <div className="otp-google">Please enter the OTP code sent to your Google Authenticator account</div>
        <div>
          <AvForm className="row" onValidSubmit={submitOtp} ref={c => setForm(c)}>
            <div className="form-group col-12 otp-field">
              <label htmlFor="otp">OTP Code</label>
              <AvField
                autoComplete="off"
                type="text"
                className="form-control"
                id="otp"
                name="otp"
                placeholder="Enter OTP"
                validate={{
                  required: { value: true, errorMessage: "Please enter OTP" },
                  pattern: {
                    value: "^[0-9]+$",
                    errorMessage: "Your OTP must be a number",
                  },
                }}
              />
            </div>
          </AvForm>
        </div>
      </Modal.Body>
      <Modal.Footer className="">
        <Button size="sm" color={Colors.GREEN} handleClick={() => form.submit()} loading={select.isLoading} disabled={select.isLoading}>
          Validate OTP
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OtpModal;
