export const ClientsActionTypes = {
  FETCHING_DATA: "FETCHING_CLIENTS",
  FETCHING_CLIENT_SUCCESS: "FETCHING_CLIENT_SUCCESS",
  FETCHING_CLIENT_ERROR: "FETCHING_CLIENT_ERROR",
  CLIENT_DETAILS: "CLIENT_DETAILS",
  CREATE_CLIENT: "CREATE_CLIENT",
  CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
  CREATE_CLIENT_ERROR: "CREATE_CLIENT_ERROR",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
  NEW_CLIENT_RESET: "NEW_CLIENT_RESET",
  STOP_LOADER: "STOP_LOADER",
  ADD_AUTHORIZATION_SUCCESS: "ADD_AUTHORIZATION_SUCCESS",
  ADD_AUTHORIZATION_ERROR: "ADD_AUTHORIZATION_ERROR",
  ADD_AUTHORIZATION_PROCESSING: "ADD_AUTHORIZATION_PROCESSING",
  ENABLE_AUTHORIZATION_SUCCESS: "ENABLE_AUTHORIZATION_SUCCESS",
  ENABLE_AUTHORIZATION_ERROR: "ENABLE_AUTHORIZATION_ERROR",
  ENABLE_AUTHORIZATION_PROCESSING: "ENABLE_AUTHORIZATION_PROCESSING",
  DISABLE_AUTHORIZATION_SUCCESS: "DISABLE_AUTHORIZATION_SUCCESS",
  DISABLE_AUTHORIZATION_ERROR: "DISABLE_AUTHORIZATION_ERROR",
  DISABLE_AUTHORIZATION_PROCESSING: "DISABLE_AUTHORIZATION_PROCESSING",
  LIST_AUTHORIZATION_SUCCESS: "LIST_AUTHORIZATION_SUCCESS",
  LIST_AUTHORIZATION_ERROR: "LIST_AUTHORIZATION_ERROR",
  LIST_AUTHORIZATION_PROCESSING: "LIST_AUTHORIZATION_PROCESSING",
};

export const fetchClients = (PageNo, PageSize, clientName = "") => ({
  type: ClientsActionTypes.FETCHING_DATA,
  PageNo,
  PageSize,
  clientName,
});

export const fetchClientsSuccess = (payload) => ({
  type: ClientsActionTypes.FETCHING_CLIENT_SUCCESS,
  payload: payload,
});

export const addClientAuthorization = (payload) => ({
  type: ClientsActionTypes.ADD_AUTHORIZATION_PROCESSING,
  payload: payload,
});

export const enableClientAuthorization = (payload) => ({
  type: ClientsActionTypes.ENABLE_AUTHORIZATION_PROCESSING,
  payload: payload,
});

export const disableClientAuthorization = (payload) => ({
  type: ClientsActionTypes.DISABLE_AUTHORIZATION_PROCESSING,
  payload: payload,
});

export const listClientAuthorization = (payload) => ({
  type: ClientsActionTypes.LIST_AUTHORIZATION_PROCESSING,
  payload: payload,
});

export const addClientAuthorizationSuccess = (payload) => ({
  type: ClientsActionTypes.ADD_AUTHORIZATION_SUCCESS,
  payload: payload,
});

export const enableClientAuthorizationSuccess = (payload) => ({
  type: ClientsActionTypes.ENABLE_AUTHORIZATION_SUCCESS,
  payload: payload,
});

export const disableClientAuthorizationSuccess = (payload) => ({
  type: ClientsActionTypes.DISABLE_AUTHORIZATION_SUCCESS,
  payload: payload,
});

export const listClientAuthorizationSuccess = (payload) => ({
  type: ClientsActionTypes.LIST_AUTHORIZATION_SUCCESS,
  payload: payload,
});

export const clientDetails = (payload) => ({
  type: ClientsActionTypes.CLIENT_DETAILS,
  payload: payload,
});

export const createClient = (payload) => ({
  type: ClientsActionTypes.CREATE_CLIENT,
  payload: payload,
});

export const createClientSuccess = (payload) => ({
  type: ClientsActionTypes.CREATE_CLIENT_SUCCESS,
  payload: payload,
});

export const updateClient = (id, payload) => ({
  type: ClientsActionTypes.UPDATE_CLIENT,
  payload: { id, payload },
});

export const updateClientSuccess = (payload) => ({
  type: ClientsActionTypes.UPDATE_CLIENT_SUCCESS,
  payload: payload,
});

export const resetNewCreatedClient = () => ({
  type: ClientsActionTypes.NEW_CLIENT_RESET,
});

export const stopLoader = () => ({
  type: ClientsActionTypes.STOP_LOADER,
});
