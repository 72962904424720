export const Urls = {
  Dashboard: `/`,
  Onboarding: `/onboarding`,
  HandleOnboarding: `/onboarding/onboard/:id`,
  MakerChecker: `/maker-checker`,
  Users: `/users`,
  Scopes: `/scopes`,
  Profile: `/profile`,
  ViewScope: `/scopes/view/:id`,
  CreateScope: `/scopes/create`,
  EditScope: `/scopes/edit/:id`,
  Clients: `/clients`,
  ViewClient: `/clients/view/:id`,
  CreateClient: `/clients/create`,
  EditClient: `/clients/edit/:id`,
  Applications: `/applications`,
  CreateApplication: `/applications/create`,
  ApplicationDetails: `/applications/:id`,
  UserDetails: `/user-details/:id`,
  Audit: `/audit`,
  ViewAuditInfo: `/audit/view/:id`,
  AccessLogs: `/access-logs`,
  ViewAccessLogs: `/access-logs/view/:id`,
  OpenIdConfiguration: `/identity/.well-known/openid-configuration`,
  Login: "/login",
  LoggingOut: "/logingout",

  NewWorkflow: "/workflows/create",
  DefineWorkflow: "/workflows/define",

  MakerChecker: `/maker-checker`,
  EditWorkflow: "/workflows/edit/:clientId/:code",
  SecondStep: "/workflows/define/next/:clientId/:code",
  WorkflowTransactions: "/workflows/transactions",
  MakerCheckerApplications: "/maker-checker/applications",
  Authorization: "/authorization",

  AllUsers: `/users`,
  UserView: `/user/view/:id`,
  NewUser: `/user/new`,
  EditUser: `/user/edit/:id`,
};

export const ViewClientPage = (id) => {
  return Urls.ViewClient.replace(":id", id);
};

export const EditClientPage = (id) => {
  return Urls.EditClient.replace(":id", id);
};

export const ViewScopePage = (id) => {
  return Urls.ViewScope.replace(":id", id);
};

export const EditScopePage = (id) => {
  return Urls.EditScope.replace(":id", id);
};

export const ApplicationDetails = (id) => {
  return Urls.ApplicationDetails.replace(":id", id);
};

export const UserDetails = (id) => {
  return Urls.UserDetails.replace(":id", id);
};

// Maker Checker Urls
export const EditWorkflow = (clientId, code) => {
  return Urls.EditWorkflow.replace(":clientId/:code", `${clientId}/${code}`);
};
export const DefineWorkflowUserRole = (id) => {
  return Urls.DefineUserRole.replace(":id", id);
};
export const DefineWorkflowNextStep = (clientId, code) => {
  return Urls.SecondStep.replace(":clientId/:code", `${clientId}/${code}`);
};

// Users Management Urls
export const EditUserUrl = (id) => {
  return Urls.EditUser.replace(":id", id);
};

export const UserViewUrl = (id) => {
  return Urls.UserView.replace(":id", id);
};

export const ViewAuditInfoPage = (id) => {
  return Urls.ViewAuditInfo.replace(":id", id);
};

export const ViewAccessLogPage = (id) => {
  return Urls.ViewAccessLogs.replace(":id", id);
};

export const HandleOnboardingPage = (id) => {
  return Urls.HandleOnboarding.replace(":id", id);
};
