export const DashboardActionTypes = {
  FETCH_HOURLY_DATA: "FETCH_HOURLY_DATA",
  FETCH_HOURLY_DATA_SUCCESS: "FETCH_HOURLY_DATA_SUCCESS",
  FETCH_DAILY_DATA: "FETCH_DAILY_DATA",
  FETCH_DAILY_DATA_SUCCESS: "FETCH_DAILY_DATA_SUCCESS",
  FETCH_HISTORY_DATA: "FETCH_HISTORY_DATA",
  FETCH_HISTORY_DATA_SUCCESS: "FETCH_HISTORY_DATA_SUCCESS",
  FETCH_SUMMARY_DATA: "FETCH_SUMMARY_DATA",
  FETCH_SUMMARY_DATA_SUCCESS: "FETCH_SUMMARY_DATA_SUCCESS",
};

export const fetchHourly = (hour) => ({
  type: DashboardActionTypes.FETCH_HOURLY_DATA,
  payload: hour
});

export const fetchHourlySuccess = (payload) => ({
  type: DashboardActionTypes.FETCH_HOURLY_DATA_SUCCESS,
  payload: payload,
});

export const fetchDaily = () => ({
  type: DashboardActionTypes.FETCH_DAILY_DATA,
});

export const fetchDailySuccess = (payload) => ({
  type: DashboardActionTypes.FETCH_DAILY_DATA_SUCCESS,
  payload: payload,
});

export const fetchHistory = () => ({
  type: DashboardActionTypes.FETCH_HISTORY_DATA,
});

export const fetchHistorySuccess = (payload) => ({
  type: DashboardActionTypes.FETCH_HISTORY_DATA_SUCCESS,
  payload: payload,
});

export const fetchSummary = () => ({
  type: DashboardActionTypes.FETCH_SUMMARY_DATA,
});

export const fetchSummarySuccess = (payload) => ({
  type: DashboardActionTypes.FETCH_SUMMARY_DATA_SUCCESS,
  payload: payload,
});

