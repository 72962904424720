import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingTableContent } from "../../shared/loader/Loader";
import { useHistory } from "react-router-dom";
import { fetchAllApplicationsUsingRole } from "../../../store/onboarding/onboarding-actions";
import { Paginator } from "../../shared";
import { Table } from "reactstrap";
import OnboardModal from "../onboardModal/onboardModal";
import _debounce from "lodash.debounce";

const OtherUsersOnboardView = ({ onboardRole }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const [userEmail, setUserEmail] = useState(loggedInUser.profile.email);
  const [applicationData, setApplicationData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [modalShow, setModalShow] = React.useState(false);
  const [onboardAppName, setonboardAppName] = useState({});
  const [applicationsWithRoles, setApplicationsWithRoles] = useState({});
  const [hidePaginator, setHidePaginator] = useState(false);

  const select = useSelector((state) => {
    return {
      allApplicationsUsingRole:
        state.onboarding && state.onboarding.allApplicationsUsingRole.data,
      isLoading: state.onboarding && state.onboarding.isLoading,
    };
  });

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPageNo(selected + 1);
  };

  //  populate table with default data or search table with search values or
  const handleSearch = (e) => {
    e.preventDefault();

    if (e.target.value === "") {
      setPageNo(1);
      setHidePaginator(false);
      filteredTableData(applicationData);
    } else {
      search(applicationData, e.target.value);
    }
  };

  // search table
  const search = (values, searchItem) => {
    const searchResultList = values.filter((value) => {
      if (value !== null) {
        let data = value.replace(".role", "");
        return data.toString().toLowerCase().indexOf(searchItem) > -1;
      }
    });
    setHidePaginator(true);
    setApplicationsWithRoles(searchResultList);
  };

  const handleItemsPerPage = (e) => {
    e.preventDefault();
    setPageSize(e.target.value);
  };

  const handleOnboarding = (details) => {
    setonboardAppName(details);
    setModalShow(true);
  };

  // helper function to filter claims
  const filterClaims = (claimList, keyword) => {
    let filtered = [];

    claimList.map((claim, index) => {
      if (claim.Value.match(keyword)) {
        filtered.push(claimList[index].Type);
      }
    });

    setApplicationData(filtered.sort());
    return filtered;
  };

  // populate table with default data
  const filteredTableData = (applicationData) => {
    const lastItemIndex = pageNo * pageSize;
    const firstItemIndex = lastItemIndex - pageSize;

    const tableValues = applicationData.length
      ? applicationData.slice(firstItemIndex, lastItemIndex)
      : [];

    setApplicationsWithRoles(tableValues);
  };

  useEffect(() => {
    dispatch(fetchAllApplicationsUsingRole({ userEmail }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // filter applications using logged in user role
  useEffect(() => {
    if (
      select.allApplicationsUsingRole &&
      select.allApplicationsUsingRole.Data.length > 0 &&
      select.allApplicationsUsingRole.Data[0].Claims.length > 0
    ) {
      filterClaims(
        select.allApplicationsUsingRole.Data[0].Claims,
        loggedInUser.profile["ssoui.role"]
      );
    }
  }, [
    select.allApplicationsUsingRole &&
      select.allApplicationsUsingRole.Data.length > 0 &&
      select.allApplicationsUsingRole.Data[0].Claims.length,
  ]);

  useEffect(() => {
    filteredTableData(applicationData);
  }, [applicationData, pageNo, pageSize]);

  return (
    <section className="row scope-container">
      <OnboardModal
        openModal={modalShow}
        closeModal={() => setModalShow(false)}
        onboardAppName={onboardAppName}
        onboardRole={onboardRole}
        history={history}
      />

      <div className="col">
        <div className="content-header d-flex justify-content-between">
          <span>All manageable applications</span>
        </div>
        <div className="card card-content">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              {/* <div className="col-7"> */}
              <div className="sp-pt--48">
                <div className="input">
                  <input
                    className="input__text"
                    type="text"
                    placeholder="Search Applications"
                    onChange={handleSearch}
                  />
                  <button className="input__btn">
                    <i className="ri-search-line"></i>
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="mr-2">showing:</span>
                <select
                  className="input__text input__select p-0"
                  value={pageSize}
                  onChange={handleItemsPerPage}
                >
                  <option value={5}>5 Entries</option>
                  <option value={10}>10 Entries</option>
                  <option value={15}>15 Entries</option>
                  <option value={20}>20 Entries</option>
                </select>
              </div>
            </div>

            <div className="row table-container">
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Applications</th>
                    {/* <th>Cliend ID</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                {(!select.allApplicationsUsingRole ||
                  (select.allApplicationsUsingRole &&
                    select.allApplicationsUsingRole.Data)) &&
                select.isLoading ? (
                  <LoadingTableContent colSpan={3} />
                ) : (
                  <tbody>
                    {select.allApplicationsUsingRole &&
                      select.allApplicationsUsingRole.Data &&
                      (applicationsWithRoles.length ? (
                        applicationsWithRoles.map((value, i) => {
                          let application = [];
                          // remove .role extension for Applications
                          application = value.replace(".role", "");

                          // format string to capitalize first letter
                          application =
                            application[0].toUpperCase() +
                            application.substr(1).toLowerCase();

                          return (
                            <tr key={i + 1}>
                              {pageNo > 1 ? (
                                <td>{(pageNo - 1) * 10 + (i + 1)}</td>
                              ) : (
                                <td>{i + 1}</td>
                              )}
                              <td>{application}</td>
                              {/* <td>{value.ClientId}</td> */}
                              <th>
                                <span
                                  className="green-color cursor mr-2"
                                  onClick={() => handleOnboarding(application)}
                                >
                                  Onboard
                                </span>
                              </th>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            style={{ display: "table-cell" }}
                            colSpan={4}
                            className="font-weight-bold text-center pt-4"
                          >
                            No Content Found
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </Table>
            </div>
            {applicationData && !hidePaginator && (
              <Paginator
                currentPage={pageNo}
                perPage={pageSize}
                pageCount={Math.ceil(applicationData.length / pageSize)}
                totalItems={applicationData.length}
                handlePageClick={handlePageClick}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtherUsersOnboardView;
