import React from "react";
import { SSO_API_URL, SSO_URL } from "../../../../constants/app-constant";

import { Urls } from '../../../../utils/urls';
import 'bootstrap/dist/css/bootstrap.min.css';
import './documentTopNav.scss';

const DocumentTopNav = () => {
    const domain = window.location.hostname;
    const domainSplit = domain.split('.');
    const swaggerLink = domainSplit.indexOf('test') > -1 || domainSplit.indexOf('localhost') > -1?'http://ssoapi.test.vggdev.com/swagger/ui/index':'http://ssoapi.staging.vggdev.com/swagger/ui/index';
    const ssoDocument = "https://venturegardengroup.atlassian.net/wiki/spaces/RES/pages/6849133/Integration";
    return (
        <nav className="row document-nav-container">
            <div className="col doc-items">
                <span onClick={()=> window.open(Urls.OpenIdConfiguration, "_blank")}>Open ID-Configuration</span>
                <span onClick={()=> window.open(swaggerLink, "_blank")}>API Documentation</span>
                <span onClick={()=> window.open(ssoDocument, "_blank")}>SSO Integration Documentation</span>
            </div>
        </nav>
    )
}

export default DocumentTopNav;
