export const ErrorHandler = errorResponse => {
  if (errorResponse.status && errorResponse.status !== 302) {
    if (errorResponse.status && errorResponse.status === 500) {
      return 'Error occurred while processing this request';
    } else if (errorResponse.data && !errorResponse.data.Message) {
      return errorResponse.data;
    } else if (errorResponse.data && errorResponse.data.Message) {
      return errorResponse.data.Message;
    }
  } else {
    return 'The information already exists';
  }
  return '';
};
