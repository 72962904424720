import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './notifications.scss';

export const Notification = ({ type, message }) => {
    return (
        <>
            <ToastContainer
                position="top-left"
                autoClose={180000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* {message && type && message !== '' && type === 'error' && <ErrorNotification message={message} />} */}
        </>
    )
};

export const ErrorNotification = ({ message }) => {
    return (
        <>

        </>
        // <div className="sp-column-notification">
        //     <div className="sp-error-notification">
        //         <div className="sp-error-content">
        //             <div className="sp-error-close"><span>&#10005;</span></div>
        //             <div><p>{message}</p></div>
        //             <div className="sp-error-cancel" onClick={() => subscriber.next(null)}><span>&#10005;</span></div>
        //         </div>
        //     </div>
        // </div>
    )
}