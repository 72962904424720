import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import $ from "jquery";

import { fetchClients, addClientAuthorization, listClientAuthorization, enableClientAuthorization, disableClientAuthorization } from "../../store/clients/clients-actions";
import { LoadingTableContent } from "../shared/loader/Loader";
import { Colors } from "../../utils/colors";
import Button from "../shared/button/Button";
import AuthorizedClient from "./authorizedClient/authorizedClient";
import { useTitle } from "../../utils/useTitle";

import "./clientAuthorization.scss";

const ClientAuthorization = () => {
  useTitle("Applications");
  const history = useHistory();
  const dispatch = useDispatch();
  const [authorizationList, setAuthorizationList] = useState(null);
  const [index, setIndex] = useState("");
  const [clientId, setClientId] = useState("");
  const [mapApplicationModalShow, setMapApplicationModalShow] = useState(false);

  const select = useSelector((state) => {
    return {
      authorizations: state.clients && state.clients.listData && state.clients.listData.data,
      enableProcessing: state.clients && state.clients.enableProcessing,
      disableProcessing: state.clients && state.clients.disableProcessing,
      enableData: state.clients && state.clients.enableData,
      disableData: state.clients && state.clients.disableData,
      isLoading: state.clients && state.clients.listProcessing,
    };
  });
  const { authorizations, enableProcessing, disableProcessing, enableData, disableData } = select;
  const enableAuthorization = (data, i) => {
    setIndex(i);
    setClientId(data.ClientId);
    dispatch(enableClientAuthorization({ ...data }));
  };

  useEffect(() => {
    if (authorizations) {
      setAuthorizationList(authorizations);
    }
  }, [authorizations]);
  useEffect(() => {
    if (enableData) {
      authorizationList[index]["Enabled"] = true;
      setAuthorizationList([...authorizationList]);
    }
  }, [enableData]);
  useEffect(() => {
    if (disableData) {
      authorizationList[index]["Enabled"] = false;
      setAuthorizationList([...authorizationList]);
      // select.authorizations[index]["Enabled"] = false;
      // console.log({ enable: select.authorizations[index]["Enabled"] });
    }
  }, [disableData]);
  const disableAuthorization = (data, i) => {
    setIndex(i);
    setClientId(data.ClientId);
    dispatch(disableClientAuthorization({ ...data }));
  };

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(listClientAuthorization());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authorizationList && authorizationList.length > 0) {
      $(document).ready(function () {
        $("#applications-table").DataTable();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationList && authorizationList.length]);

  return (
    <section className="row authorise-container">
      <AuthorizedClient openModal={mapApplicationModalShow} closeModal={() => setMapApplicationModalShow(false)} />
      <div className="col">
        <div className="content-header row">
          <span className="application-title col-auto">Client Authorization</span>
          <span className="sub-top-btn col-md-auto col-sm-12">
            <div className="align-right">
              <Button
                size="sm"
                color={Colors.GREEN}
                handleClick={() => {
                  setMapApplicationModalShow(true);
                }}
                className=" mr-2 button-width"
              >
                Authorize Client
              </Button>
            </div>
          </span>
        </div>
        <div className="card card-content">
          <div className="card-body">
            <div className="row table-container">
              <Table responsive id="applications-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Client Id</th>
                    <th scope="col">Date Updated </th>
                    <th scope="col">Status </th>
                    {/* <th scope="col">Actions </th> */}
                  </tr>
                </thead>
                {!authorizationList && select.isLoading && <LoadingTableContent colSpan={4} />}
                <tbody>
                  {authorizationList &&
                    authorizationList.map((value, i) => {
                      return (
                        <tr key={value.Id}>
                          <th>{i + 1}</th>
                          <td className="sbu-name">{value.ClientId}</td>
                          <td>{value.CreatedDate && new Date(value.CreatedDate).toISOString().slice(0, 10)}</td>
                          <td>{value.Enabled ? "Enabled" : "Disabled"}</td>
                          {/* <td>
                            {(disableProcessing || enableProcessing) && index === i ? (
                              <span>{clientId === value.ClientId && <i className="fa fa-spin fa-spinner"></i>}</span>
                            ) : value.Enabled ? (
                              <span
                                className="enable"
                                onClick={() =>
                                  disableAuthorization({ ClientId: value.ClientId, Enabled: false, Id: value.Id, CreatedBy: UserDetails.profile.sid, CreatedDate: new Date().toISOString() }, i)
                                }
                              >
                                Disable Client
                              </span>
                            ) : (
                              <span
                                className="enable"
                                onClick={() =>
                                  enableAuthorization({ ClientId: value.ClientId, Enabled: true, Id: value.Id, CreatedBy: UserDetails.profile.sid, CreatedDate: new Date().toISOString() }, i)
                                }
                              >
                                Enable Client
                              </span>
                            )}
                          </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientAuthorization;
