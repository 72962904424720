import React from "react";
import { Table } from "reactstrap";
import Moment from "react-moment";
import "./userInfo.scss";

const UserInfo = props => {
  const {
    AccessFailedCount,
    CreatedDate,
    Email,
    EmailConfirmed,
    FullName,
    Id,
    IsGoogleAuthenticatorEnabled,
    LastUpdate,
    LockoutEnabled,
    LockoutEndDateUtc,
    PhoneNumber,
    PhoneNumberConfirmed,
    TwoFactorEnabled,
    UserName,
  } = props.data;
  return (
    <div className="user-info-container">
      <div className="row">
        <div className="col-sm-12 col-md-8">
          <Table responsive borderless width="100%">
            {props.data ? (
              <tbody>
                <tr>
                  <td width="20%" className="user-label">
                    ID:
                  </td>
                  <td width="80%" className="user-value">
                    {Id || "---"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Full Name:
                  </td>
                  <td width="80%" className="user-value">
                    {FullName || "---"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    User Name:
                  </td>
                  <td width="80%" className="user-value">
                    {UserName || "---"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Email Address:
                  </td>
                  <td width="80%" className="user-value">
                    {Email || "---"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Email Confirmed:
                  </td>
                  <td width="80%" className="user-value">
                    {EmailConfirmed ? "YES" : "NO"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Phone Number:
                  </td>
                  <td width="80%" className="user-value">
                    {PhoneNumber || "---"}
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="user-label">
                    Phone Number Confirmed:
                  </td>
                  <td width="50%" className="user-value">
                    {PhoneNumberConfirmed ? "YES" : "NO"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Two Factor Enabled:
                  </td>
                  <td width="80%" className="user-value">
                    {TwoFactorEnabled ? "YES" : "NO"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Google Authenticaion Enabled:
                  </td>
                  <td width="80%" className="user-value">
                    {IsGoogleAuthenticatorEnabled ? "YES" : "NO"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Lockout Enabled:
                  </td>
                  <td width="80%" className="user-value">
                    {LockoutEnabled ? "YES" : "NO"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Lock Out End Date:
                  </td>
                  <td width="80%" className="user-value">
                    {LockoutEndDateUtc ? (
                      <Moment format="D MMMM YYYY" withTitle>
                        {LockoutEndDateUtc}
                      </Moment>
                    ) : (
                      "---"
                    )}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Access Failed Count:
                  </td>
                  <td width="80%" className="user-value">
                    {AccessFailedCount?.toString() || "---"}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Last Updated:
                  </td>
                  <td width="80%" className="user-value">
                    {LastUpdate ? (
                      <Moment format="D MMMM YYYY" withTitle>
                        {LastUpdate}
                      </Moment>
                    ) : (
                      "---"
                    )}
                  </td>
                </tr>
                <tr>
                  <td width="20%" className="user-label">
                    Date Created:
                  </td>
                  <td width="80%" className="user-value">
                    {CreatedDate ? <Moment format="D MMMM YYYY">{CreatedDate}</Moment> : "---"}
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="2" className="font-weight-bold text-center pt-4">
                    Not data available
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
