import { all, call, put, takeEvery } from "redux-saga/effects";
import { ssoApi } from "../../axios/interceptor";

import {
    AccessLogsActionTypes,
    fetchAccessLogsSuccess,
} from "./access-logs-actions";

export function* FetchAccessLogsAsync(action) {
    let pageSize = action.payload.pageSize;
    let pageNo = action.payload.pageNo;
    let startDate = action.payload.fromDate;
    let endDate = action.payload.toDate;
    
    try {
        const response = yield call(
            ssoApi.get,
            `loginrecord/list?pageSize=${pageSize}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&grant_type=all`
        );
        yield put(fetchAccessLogsSuccess(response));
    } catch (err) {
        console.log(err);
    }
}

export function* WatchFetchAccessLogsflow() {
    yield takeEvery(
        AccessLogsActionTypes.FETCHING_DATA,
        FetchAccessLogsAsync
    );
}



export default function* accessLogSaga() {
    yield all([call(WatchFetchAccessLogsflow)]);
}
