import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import _debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Spinner } from "reactstrap";
import { validateEmail } from "../../../utils/emailValidation";
import ShowToast from "../../../utils/showToast";

import { resetButton, verifyEmail, onboardToApplication } from "../../../store/onboarding/onboarding-actions";
import { Colors } from "../../../utils/colors";
import Button from "../../shared/button/Button";

import "./onboardModal.scss";
import { Urls } from "../../../utils/urls";

const OnboardModal = ({ openModal, closeModal, onboardAppName, onboardRole, history, top }) => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [userData, setUserData] = useState({
    UserId: "",
    Claims: [
      { Type: "application", Value: onboardAppName },
      { Type: "role", Value: onboardRole },
    ],
  });

  const dispatch = useDispatch();
  const select = useSelector((state) => {
    return {
      onboardResponse: state.onboarding && state.onboarding.onboardResponse,
      isLoading: state.onboarding && state.onboarding.isLoading,
      verifyingEmail: state.onboarding && state.onboarding.verifyingEmail,
      userDetails: state.onboarding && state.onboarding.userDetails,
      changeButton: state.onboarding && state.onboarding.changeButton,
      disableButton: state.onboarding && state.onboarding.disableButton,
      showSuccess: state.onboarding && state.onboarding.showSuccess,
    };
  });

  const handleClose = () => {
    setShow(false);
    setSearchTerm("");
  };

  const handleShow = () => setShow(true);

  const onboardUser = () => {
    dispatch(onboardToApplication({ userData, closeModal, onboardAppName }));
  };

  const loadOptions = _debounce((event) => {
    const { value } = event.target;
    if (value && value !== "") {
      setSearchTerm(value);
    } else if (value === "") {
      dispatch(resetButton());
    }
  }, 1500);

  const handleRedirect = () => {
    setShow(false);
    closeModal();
    history.push(Urls.NewUser);
  };

  const enableBtnBasedOnEmailValidation = (e) => {
    validateEmail(e.target.value) ? setValidEmail(true) : setValidEmail(false);
  };

  useEffect(() => {
    openModal ? handleShow() : handleClose();
  }, [openModal]);

  useEffect(() => {
    if (searchTerm !== "" && validateEmail(searchTerm)) {
      dispatch(verifyEmail({ userEmail: searchTerm }));
    }
  }, [searchTerm]);

  useEffect(() => {
    userData.Claims[0]["Value"] = onboardAppName;
    setUserData(userData);
  }, [onboardAppName]);

  useEffect(() => {
    userData.UserId = select.userDetails.data && select.userDetails?.data?.Id;
  }, [select.userDetails]);

  useEffect(() => {
    if (select.onboardResponse && select.showSuccess) {
      ShowToast({
        type: "success",
        message: `User Onboarded Successfully.`,
      });
      setSearchTerm("");
      handleClose();
    }
  }, [select.isLoading]);

  return (
    <Modal centered={true} show={show} onHide={closeModal} className="modal-container">
      <Modal.Header closeButton>
        <div className="title">Onboard User to: {onboardAppName}</div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <AvForm className="row" ref={(c) => setForm(c)}>
            <div className="form-group col-12 otp-field">
              <label className="input__label" htmlFor="email">
                Email Address &nbsp;
                {select.verifyingEmail && <Spinner size="sm" color={"green"} />}
              </label>

              <AvField
                type="text"
                className="form-control"
                id="email"
                name="email"
                onChange={loadOptions}
                value={searchTerm}
                onKeyUp={enableBtnBasedOnEmailValidation}
                placeholder="Enter Email Address"
              />
            </div>
          </AvForm>
        </div>
      </Modal.Body>
      <Modal.Footer className="onboard-modal-footer">
        {select.changeButton && searchTerm !== "" ? (
          <>
            <span className="error-message">User not found, click the button below to add this user</span>
            <Button size="sm" color={Colors.GREEN} handleClick={() => handleRedirect()} loading={select.isLoading} disabled={select.disableButton}>
              Create User
            </Button>
          </>
        ) : (
          <>
            <Button
              size="sm"
              color={select.isLoading || select.disableButton || searchTerm === "" || !validEmail ? "red" : Colors.GREEN}
              handleClick={() => onboardUser()}
              loading={select.isLoading}
              disabled={select.isLoading || select.disableButton || searchTerm === "" || !validEmail}
            >
              Onboard User
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default OnboardModal;
