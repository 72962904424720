const { OtpActionTypes } = require("./otp-actions");
const initialState = {
  isLoading: false,
  otp: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OtpActionTypes.VALIDATE_OTP:
      return {
        ...state,
        isLoading: true,
      };
    case OtpActionTypes.VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otp: payload,
      };
    case OtpActionTypes.VALIDATE_OTP_ERROR:
      return {
        ...state,
        otpError: payload,
      };
    case OtpActionTypes.CLEAR_OTP:
      return {
        ...state,
        otp: null,
      };
    default:
      return state;
  }
};

export default reducer;
