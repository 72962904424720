import React, {useState} from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import ScopeDetails from '../scopeDetails/scopeDetails';
import ScopeClaims from '../scopeClaims/scopeClaims';
import ScopeSecrets from '../../../shared/secretDetail/secretDetail';

const ViewContent =({select})=> {
    const [key, setKey] = useState('Details');
    return (
        <div className="card card-content">
        <div className="card-body">
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="Details" title="Scope Details">
                    <ScopeDetails details={select.scopes} />
                </Tab>
                <Tab eventKey="Claims" title="Scope Claims">
                    <ScopeClaims details={select.scopes} />
                </Tab>
                <Tab eventKey="Secrets" title="Scope Secrets">
                    <ScopeSecrets details={select.scopes.ScopeSecrets} />
                </Tab>
            </Tabs>
        </div>
    </div>

    )
}

export default ViewContent;