import axios from 'axios';
import { BehaviorSubject } from 'rxjs';

import {
  CLIENT_ID,
  SSO_API_URL,
  MAKER_CHECKER_API_URL,
} from '../constants/app-constant';

export const subscriber = new BehaviorSubject(0);

const ssoApi = axios.create({
  baseURL: SSO_API_URL,
});

const makerCheckerApi = axios.create({
  baseURL: MAKER_CHECKER_API_URL,
});

ssoApi.interceptors.request.use(
  async (config) => {
    const credentials = JSON.parse(localStorage.getItem('user'));

    config.headers = {
      'Authorization': `Bearer ${credentials.access_token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'client-id': CLIENT_ID,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

makerCheckerApi.interceptors.request.use(
  async (config) => {
    const credentials = JSON.parse(localStorage.getItem('user'));

    config.headers = {
      'Authorization': `Bearer ${credentials.access_token}`,
      'Accept-Language': 'en-US, en;q=0.9',
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'client-id': CLIENT_ID,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

ssoApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      subscriber.next(error.response);
    }
    return error;
  }
);

const requestWithCustomClientId = (baseURL, clientId) => {
  const credentials = JSON.parse(localStorage.getItem('user'));
  const defaultOptions = {
    baseURL,
    headers: {
      'Authorization': `Bearer ${credentials.access_token}`,
      'Accept-Language': 'en-US, en;q=0.9',
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'client-id': clientId.toLowerCase(),
    },
  };

  return {
    get: (url, options = {}) =>
      axios.get(url, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      axios.post(url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) =>
      axios.put(url, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) =>
      axios.delete(url, { ...defaultOptions, ...options }),
  };
};

export { ssoApi, makerCheckerApi, requestWithCustomClientId };
