import React from 'react';
import { Table } from 'reactstrap';
import './claimGroups.scss';

const ClaimGroups = ({ groups }) => {
  return (
    <div className='user-group-container'>
      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <Table responsive borderless>
            <tbody>
              {groups.length ? (
                groups.map((group) => (
                  <tr key={`${group.Type}${group.Value}`}>
                    <td width='30%' className='user-label'>
                      {group.Type}
                    </td>
                    <td width='70%' className='user-value'>
                      {group.Value || '---'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='2' className='font-weight-bold text-center pt-4'>
                    Not data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ClaimGroups;
