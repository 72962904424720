import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchProfile } from "../../store/profile/profile-action";
import { Colors } from "../../utils/colors";
import Button from "../shared/button/Button";
import ProfileForm from "./profileForm/profileForm";
import PasswordChangeForm from "./passwordChangeForm/passwordChangeForm";
import { useTitle } from "../../utils/useTitle";
import "./profile.scss";

const Profile = () => {
  useTitle("User Profile");
  const dispatch = useDispatch();
  const [profileModalShow, setProfileModalShow] = useState(false);
  const [passwordChangeModalShow, setPasswordChangeModalShow] = useState(false);
  const [action, setAction] = useState("");

  const { userProfile } = useSelector(state => {
    return {
      userProfile: state.profile.profile && state.profile.profile,
    };
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch(fetchProfile(user.profile.sub));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="row profile-container">
      {userProfile && action === 2 && <ProfileForm openModal={profileModalShow} closeModal={() => setProfileModalShow(false)} details={userProfile} />}
      {userProfile && action === 1 && <PasswordChangeForm openModal={passwordChangeModalShow} closeModal={() => setPasswordChangeModalShow(false)} userId={userProfile.Id} />}
      <div className="col">
        <div className="content-header d-flex justify-content-between">
          <span>Profile</span>
          <div className="sub-top-btn">
            <Button
              size="sm"
              outline={true}
              color={Colors.GREEN}
              handleClick={() => {
                setPasswordChangeModalShow(true);
                setAction(1);
              }}
              className="mr-2"
            >
              Change Password
            </Button>
            <Button
              size="sm"
              color={Colors.GREEN}
              handleClick={() => {
                setProfileModalShow(true);
                setAction(2);
              }}
              className="mr-2"
            >
              Edit Profile
            </Button>
          </div>
        </div>
        <div className="card card-content">
          <div className="card-body">
            <div className="data">
              <div className="title">Name</div>
              <div className="value">
                {userProfile && userProfile.FirstName} {userProfile && userProfile.LastName}
              </div>
            </div>
            <div className="data">
              <div className="title">Username</div>
              <div className="value">{userProfile && userProfile.UserName}</div>
            </div>
            <div className="data">
              <div className="title">Email address</div>
              <div className="value">{userProfile && userProfile.Email}</div>
            </div>
            <div className="data">
              <div className="title">Phone number</div>
              <div className="value">{userProfile && userProfile.PhoneNumber}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
