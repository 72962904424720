import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const NavLinkWrapper = ({ item, icon, to, collapse, openMenu, setOnSideBarHover, dropdown, children, className = "", subNavInfo = { item: "", openSubNav: false } }) => {
  const [showItem, setShowItem] = useState("");

  //show the nav sub item on collsape bar
  const showOnCollapse = itemName => {
    collapse && item === itemName ? setShowItem(itemName) : setShowItem("");
  };

  //hide the nav sub item on collsape bar
  const hideOnCollapse = () => setShowItem("");
  const openSubNav = subNavInfo.item === item && subNavInfo.openSubNav;
  const NavbarLink = dropdown ? "div" : NavLink;
  return (
    <li
      className={`nav-item ${collapse && "nav-collapse"}`}
      onMouseEnter={() => {
        collapse && setOnSideBarHover(true);
        collapse && showOnCollapse(item);
      }}
      onMouseLeave={() => {
        collapse && setOnSideBarHover(false);
        collapse && hideOnCollapse();
      }}
    >
      <NavbarLink to={to} activeClassName="active" className={`nav-link ${className}`} exact>
        {icon}
        {(!collapse || (collapse && showItem === item)) && (
          <span
            className="item-name"
            onClick={() => {
              openMenu(item);
              collapse && setOnSideBarHover(true);
            }}
          >
            {item}
            {!collapse && dropdown && (
              <span>
                {!openSubNav && <i className="fa fa-angle-right animated fadeIn"></i>}
                {openSubNav && <i className="fa fa-angle-down animated fadeIn"></i>}
              </span>
            )}
          </span>
        )}
      </NavbarLink>
      {((!collapse && openSubNav) || (collapse && showItem === item)) && children}
    </li>
  );
};
export default NavLinkWrapper;
