const Alphabet = [
  {
    label: "-",
    value: "",
  },
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },
  {
    label: "C",
    value: "C",
  },
  {
    label: "D",
    value: "D",
  },
  {
    label: "E",
    value: "E",
  },
  {
    label: "F",
    value: "F",
  },
  {
    label: "G",
    value: "G",
  },
  {
    label: "H",
    value: "H",
  },
  {
    label: "I",
    value: "I",
  },
  {
    label: "J",
    value: "J",
  },
  {
    label: "K",
    value: "K",
  },
  {
    label: "L",
    value: "L",
  },
  {
    label: "M",
    value: "M",
  },
  {
    label: "N",
    value: "N",
  },
  {
    label: "O",
    value: "O",
  },
  {
    label: "P",
    value: "P",
  },
  {
    label: "Q",
    value: "Q",
  },
  {
    label: "R",
    value: "R",
  },
  {
    label: "S",
    value: "S",
  },
  {
    label: "T",
    value: "T",
  },
  {
    label: "U",
    value: "U",
  },
  {
    label: "V",
    value: "V",
  },
  {
    label: "W",
    value: "W",
  },
  {
    label: "X",
    value: "X",
  },
  {
    label: "Y",
    value: "Y",
  },
  {
    label: "Z",
    value: "Z",
  },
];

export default Alphabet;
