import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from "reactstrap";

import { createApplication, fetchApplicationList } from '../../../store/applications/applications-actions';
import { Colors } from '../../../utils/colors';
import Button from "../../shared/button/Button";

import './applicationForm.scss';

const ApplicationForm = ({ openModal, closeModal }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [form, setForm] = useState({});
    const [createdStatus, setCreatedStatus] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const select = useSelector((state) => {
        return {
            newApplicationStatus: state.application && state.application.newApplication && state.application.newApplication.status,
            isApplicationLoading: state.application && state.application.isApplicationLoading,
            isBusinessUnitLoading: state.application && state.application.isBusinessUnitLoading,
            sbuList: state.application && state.application.sbuList && state.application.sbuList.data && state.application.sbuList.data.Response,
        }
    })

    const submitForm = (event, value) => {
        value['ClientIds'] = [];
        setCreatedStatus(true);
        dispatch(fetchApplicationList());
        dispatch(createApplication(value))
        // form.reset();
    }

    useEffect(() => {
        openModal ? handleShow() : handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal])

    useEffect(() => {
        if (createdStatus && select.newApplicationStatus && select.newApplicationStatus === 200) {
            setCreatedStatus(false);
            closeModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [select.newApplicationStatus])

    return (
        <>

            <Modal
                centered
                show={show}
                onHide={closeModal}
                className="application-modal-container">
                <Modal.Header closeButton>
                   New Application
                </Modal.Header>
                <Modal.Body>
                    <AvForm onValidSubmit={submitForm} ref={c => (setForm(c))}>
                        <div className="row">
                            <div className="form-group col-12 otp-field">
                                <label htmlFor="ApplicationName">Name of Application</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    id="ApplicationName"
                                    name="ApplicationName"
                                    placeholder="Enter Name of Application"
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter Name of Application' },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-12">
                                <label htmlFor="SbuName">Select Business Unit  &nbsp; {select.isBusinessUnitLoading &&
                                        <Spinner
                                            size="sm"
                                            color={"green"}
                                        />
                                        }</label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="SbuName"
                                    id="SbuName"
                                    validate={{
                                        required: { value: true, errorMessage: 'Please select one' },
                                    }}>
                                    <option></option>
                                    {select.sbuList && select.sbuList.map(val => {
                                        return <option key={val.Id}>{val.SbuName}</option>
                                    })}
                                </AvField>
                            </div>
                        </div>
                    </AvForm>
                    <div className="d-flex justify-content-end pr-3">
                      <Button
                            size="sm"
                            outline={true}
                            color={Colors.GREEN}
                            handleClick={() => {closeModal()}}
                            className="mr-2"
                        >
                           Cancel
                         </Button>
                        <Button size="sm" color={Colors.GREEN} handleClick={() => form.submit()} loading={select.isApplicationLoading}  disabled={select.isApplicationLoading}>
                            Create
                        </Button>
                    </div>
                  </Modal.Body>
            </Modal>
        </>
    );
}

export default ApplicationForm;