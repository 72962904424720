import React,  {useEffect} from "react";
import $ from 'jquery';

import { LoadingTableContent } from '../../shared/loader/Loader';

const SummaryTable = ({data, type ,isLoading}) => {
    useEffect(() => {
        if(data && data.length > 0 && type==='admin') {
            $(document).ready(function () {
                $('#admin-table').DataTable();
            });
        } else if(data && data.length > 0 && type==='user') {
            $(document).ready(function () {
                $('#user-table').DataTable();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <section className="row scope-container">
                        {type==="admin" && 
                        <div className="table-responsive">
                       <div className="summary-title">Administrators</div>
                            <table className="table" id="admin-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Last Update</th>
                                    </tr>
                                </thead>
                                {!data && isLoading && <LoadingTableContent colSpan={5} />}
                                  <tbody>
                                    {data && data.map((value, i) => {
                                        return (
                                            <tr key={value.Id}>
                                                <th>{i + 1}</th>
                                                <td>{value.FirstName}</td>
                                                <td>{value.LastName}</td>
                                                <td>{value.Email}</td>
                                                <td>{value.LastUpdate && new Date(value.LastUpdate).toISOString().slice(0,10)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
                       
                        {type==="user" && 
                        <div className="table-responsive">
                       <div className="summary-title">Top 10 Logged in Users</div>
                            <table className="table" id="user-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Action</th>
                                        <th scope="col">IP</th>
                                        <th scope="col">Last Update</th>
                                    </tr>
                                </thead>
                                {!data && isLoading && <LoadingTableContent colSpan={5} />}
                                  <tbody>
                                    {data && data.map((value, i) => {
                                        return (
                                            <tr key={value.Id}>
                                                <th>{i + 1}</th>
                                                <td>{value.CreatedBy}</td>
                                                <td>{value.Action}</td>
                                                <td>{value.Ip}</td>
                                                <td>{value.TimeStamp && new Date(value.TimeStamp).toISOString().slice(0,10)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
 
        </section>
    );
};

export default SummaryTable;
