import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { makerCheckerApi, requestWithCustomClientId, ssoApi } from '../../axios/interceptor';
import { WORKFLOWS_API_URLs } from '../../constants/api-urls/maker-checker';
import { CLIENT_ID, MAKER_CHECKER_API_URL } from '../../constants/app-constant';
import ShowToast from '../../utils/showToast';
import { DefineWorkflowNextStep, Urls } from '../../utils/urls';
import {
  SaveWorkflowSuccess,
  FetchAllWorkflowDataSuccess,
  FetchClientDataSuccess,
  FetchingWorkflowData,
  MakerCheckerActionTypes,
  ProcessingWorkflowData,
  FetchWorkflowDetailsSuccess,
  FetchingApplicationList,
  CreatingMakerClaim,
  CreateMakerClaimSuccess,
  CreatingCheckerLevels,
  CreateCheckerLevelsSuccess,
  FetchWorkflowClaimSuccess,
  FetchWorkflowClaim,
  FetchWorkflowLevelsSuccess,
  FetchApplicationListSuccess,
  FetchWorkflowListSuccess,
  FetchTransactionDataSuccess,
  FetchingTransactions,
  FetchingWorkflowList,
  FetchTransactionData,
  FetchingClientData,
  FetchClientData,
} from './maker-checker-actions';

// GET Application list by client id
export function* FetchApplictionListAsync() {
  try {
    // yield put(FetchingWorkflowData(true));
    // const res = yield call(ssoApi.get, `/applicationclients?sbuApplicationId=${CLIENT_ID}`);
    const res = yield call(ssoApi.get, `/client/list`);
    console.log(res);
    if (res.status === 200) {
      yield put(FetchApplicationListSuccess(res.data.Response));
      // yield put(FetchClientClaimsData(res.data.Response.Claims));
    }
  } catch (err) {
    yield put(FetchingApplicationList(false));
    yield ShowToast({
      type: 'error',
      message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`,
    });
  }
}
export function* WatchFetchApplictionList() {
  yield takeEvery(MakerCheckerActionTypes.FETCH_APPLICATION_LIST, FetchApplictionListAsync);
}

// Create Checker Levels
export function* SaveNewApplicationAsync({ payload: { formPayload, closeModal } }) {
  try {
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, formPayload.code);
    const res = yield call(req.post, `/api/Application`, JSON.stringify(formPayload));
    if (res.status === 200 && !res.data.isError) {
      yield put(ProcessingWorkflowData(false));
      yield closeModal();
      yield ShowToast({
        type: 'success',
        message: res.data.data.message ? res.data.data.message : `Application created successfully`,
      });
      yield put(FetchClientData({}));
    } else {
      yield put(ProcessingWorkflowData(false));
      yield ShowToast({
        type: 'error',
        message: res.data.message,
      });
    }
  } catch (err) {
    yield put(ProcessingWorkflowData(false));
    yield console.log(err.response);
    yield ShowToast({
      type: 'error',
      message: err.response.data.responseException.exceptionMessage,
    });
  }
}
export function* WatchSaveNewApplicationForm() {
  yield takeLatest(MakerCheckerActionTypes.SAVE_NEW_APPLICATION, SaveNewApplicationAsync);
}

// GET Client's data by client id
export function* FetchClientDataAsync({ payload: { type, pageSize } }) {
  try {
    const res = yield call(makerCheckerApi.get, `/api/Application`);
    if (res.status === 200) {
      yield put(FetchClientDataSuccess(res.data.data));
      // yield put(FetchClientClaimsData(res.data.Response.Claims));
    }
  } catch (err) {
    yield put(FetchingClientData(false));
    yield ShowToast({
      type: 'error',
      message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`,
    });
  }
}
export function* WatchFetchClientData() {
  yield takeEvery(MakerCheckerActionTypes.FETCH_CLIENT_DATA, FetchClientDataAsync);
}

// GET All Application's workflow data
export function* FetchAllWorkflowsAsync({ payload: { applicationCode } }) {
  try {
    yield put(FetchingWorkflowData(true));
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, applicationCode);
    const res = yield call(req.get, `/api/WorkFlow/${applicationCode}/application`);
    yield put(FetchAllWorkflowDataSuccess(res.data.data));
    yield put(FetchingWorkflowData(false));
  } catch (err) {
    yield put(FetchingWorkflowData(false));
    yield ShowToast({
      type: 'error',
      message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`,
    });
  }
}
export function* WatchFetchAllWorkflows() {
  yield takeEvery(MakerCheckerActionTypes.FETCH_ALL_WORKFLOWS, FetchAllWorkflowsAsync);
}

// GET All Application's workflow list
export function* FetchWorkflowListAsync({ payload: { applicationCode } }) {
  try {
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, applicationCode);
    const res = yield call(req.get, `/api/WorkFlow/${applicationCode}/application`);
    yield put(FetchWorkflowListSuccess(res.data.data));
  } catch (err) {
    yield put(FetchingWorkflowList(false));
    yield ShowToast({
      type: 'error',
      message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`,
    });
  }
}
export function* WatchFetchWorkflowList() {
  yield takeEvery(MakerCheckerActionTypes.FETCH_WORKFLOW_LIST, FetchWorkflowListAsync);
}

// GET All Application's workflow list
export function* FetchTransactionsAsync({ payload: { workflowCode, email, applicationCode } }) {
  try {
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, applicationCode);
    const res = yield call(req.get, `/api/MakerChecker/${workflowCode}/${email}`);
    if (res.status === 200 && !res.data.isError) {
      yield put(FetchTransactionDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(FetchingTransactions(false));
    yield ShowToast({
      type: 'error',
      message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`,
    });
  }
}
export function* WatchFetchTransactions() {
  yield takeEvery(MakerCheckerActionTypes.FETCH_TRANSACTION_DATA, FetchTransactionsAsync);
}

// GET workflow by code data
export function* FetchWorkflowByCodeAsync({ payload: { workflowCode, applicationCode } }) {
  try {
    yield put(FetchingWorkflowData(true));
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, applicationCode);
    const res = yield call(req.get, `/api/WorkFlow/${workflowCode}`);
    if (!res.data.isError) {
      yield put(FetchWorkflowDetailsSuccess(res.data.data));
      yield put(FetchWorkflowClaim({ workflowCode, applicationCode }));
      yield put(FetchingWorkflowData(false));
    }
  } catch (err) {
    yield put(FetchingWorkflowData(false));
    yield ShowToast({
      type: 'error',
      message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`,
    });
  }
}
export function* WatchFetchWorkflowByCode() {
  yield takeEvery(MakerCheckerActionTypes.FETCH_WORKFLOW_DETAILS, FetchWorkflowByCodeAsync);
}

// GET workflow by code data
export function* FetchWorkflowClaimAsync({ payload: { applicationCode, workflowCode } }) {
  try {
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, applicationCode);
    const res = yield call(req.get, `/api/WorkFlow/role/${workflowCode}`);
    yield put(FetchWorkflowClaimSuccess(res.data.data[0]));
  } catch (err) {
    yield ShowToast({
      type: 'error',
      message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`,
    });
  }
}
export function* WatchFetchWorkflowClaim() {
  yield takeEvery(MakerCheckerActionTypes.FETCH_WORKFLOW_CLAIM, FetchWorkflowClaimAsync);
}

// GET workflow by code data
export function* FetchWorkflowLevelsAsync({ payload: { applicationCode, workflowCode } }) {
  try {
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, applicationCode);
    const res = yield call(req.get, `/api/UserLevel/${workflowCode}`);
    yield put(FetchWorkflowLevelsSuccess(res.data.data));
  } catch (err) {
    yield ShowToast({
      type: 'error',
      message: `Something went wrong: ${err.response ? err.response.data.responseException.exceptionMessage : 'Data Could not be loaded'}`,
    });
  }
}
export function* WatchFetchWorkflowLevels() {
  yield takeEvery(MakerCheckerActionTypes.FETCH_WORKFLOW_LEVELS, FetchWorkflowLevelsAsync);
}

// Create and Update workflow
export function* SaveWorkflowAsync({ payload: { data, history, inEditMode } }) {
  try {
    yield put(ProcessingWorkflowData(true));
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, data.applicationCode);
    const res = inEditMode ? yield call(req.put, `/api/WorkFlow`, JSON.stringify(data)) : yield call(req.post, `/api/WorkFlow`, JSON.stringify(data));
    if (!res.data.isError) {
      yield put(SaveWorkflowSuccess(!!res.isError));
      yield put(ProcessingWorkflowData(false));
      yield history.push(DefineWorkflowNextStep(data.applicationCode, data.code));
      // inEditMode
      //   ? yield history.push(Urls.MakerChecker)
      //   : yield history.push(DefineWorkflowNextStep(formData.code));
      yield ShowToast({
        type: 'success',
        message: 'Record saved successfully',
      });
    } else {
      yield put(ProcessingWorkflowData(false));
      yield put(SaveWorkflowSuccess(false));
      yield ShowToast({
        type: 'error',
        message: res.data.message,
      });
    }
  } catch (err) {
    yield put(ProcessingWorkflowData(false));
    yield put(SaveWorkflowSuccess(false));
    yield ShowToast({
      type: 'error',
      message: err.response?.data?.responseException.exceptionMessage,
    });
  }
}
export function* WatchSaveWorkflow() {
  yield takeLatest(MakerCheckerActionTypes.SAVE_WORKFLOW, SaveWorkflowAsync);
}

// Create Claim
export function* CreateMakerClaimAsync({ payload: { formValue, applicationCode, actionType, claimForm } }) {
  try {
    yield put(ProcessingWorkflowData(true));
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, applicationCode);
    yield put(CreatingMakerClaim(true));
    const res =
      actionType === 'add'
        ? yield call(req.post, WORKFLOWS_API_URLs.createClaim, JSON.stringify(formValue))
        : yield call(req.delete, WORKFLOWS_API_URLs.createClaim, {
            data: JSON.stringify(formValue),
          });
    if (!res.data.isError) {
      yield put(CreatingMakerClaim(false));
      yield ShowToast({
        type: 'success',
        message: `Record ${actionType === 'add' ? 'created' : 'removed'} successfully`,
      });
      if (actionType === 'remove') {
        yield put(CreateMakerClaimSuccess(false));
        yield claimForm.reset();
      }
      if (actionType === 'add') {
        yield put(CreateMakerClaimSuccess(true));
      }
      yield put(
        FetchWorkflowClaim({
          workflowCode: formValue.workFlowCode,
          applicationCode,
        })
      );
    } else {
      yield put(CreateMakerClaimSuccess(false));
      yield put(CreatingMakerClaim(false));
      yield ShowToast({
        type: 'error',
        message: res.data.message,
      });
    }
  } catch (err) {
    yield put(CreateMakerClaimSuccess(false));
    yield put(CreatingMakerClaim(false));
    // yield ShowToast({
    //   type: 'error',
    //   message: err.response.data.responseException.exceptionMessage,
    // });
  }
}
export function* WatchCreateMakerClaim() {
  yield takeLatest(MakerCheckerActionTypes.CREATE_MAKER_CLAIM, CreateMakerClaimAsync);
}

// Create Checker Levels
export function* CreateCheckerLevelsAsync({ payload: { data, history, inEditMode, applicationCode } }) {
  try {
    yield put(CreatingCheckerLevels(true));
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, applicationCode);
    const res = inEditMode ? yield call(req.put, WORKFLOWS_API_URLs.createLevels, JSON.stringify(data)) : yield call(req.post, WORKFLOWS_API_URLs.createLevels, JSON.stringify(data));
    if (!res.data.isError) {
      yield put(CreateCheckerLevelsSuccess(true));
      yield put(CreatingCheckerLevels(false));
      yield history.push(Urls.MakerChecker);
      yield ShowToast({
        type: 'success',
        message: `Checkers Levels ${inEditMode ? 'updated' : 'created'} successfully`,
      });
    } else {
      yield put(CreateCheckerLevelsSuccess(false));
      yield put(CreatingCheckerLevels(false));
      yield ShowToast({
        type: 'error',
        message: res.data.message,
      });
    }
  } catch (err) {
    yield put(CreateCheckerLevelsSuccess(false));
    yield put(CreatingCheckerLevels(false));
    yield ShowToast({
      type: 'error',
      message: err.response.data.responseException.exceptionMessage,
    });
  }
}
export function* WatchCreateCheckerLevels() {
  yield takeLatest(MakerCheckerActionTypes.CREATE_CHECKER_LEVELS, CreateCheckerLevelsAsync);
}

// Create Checker Levels
export function* SaveApprovalFormAsync({ payload: { formPayload, closeModal, approvalType, applicationCode, email, selectedClient, selectedWorkflow } }) {
  try {
    const req = yield requestWithCustomClientId(MAKER_CHECKER_API_URL, applicationCode);
    const res = yield call(req.post, `/api/MakerChecker/${approvalType}`, JSON.stringify(formPayload));
    if (res.status === 200 && !res.data.isError) {
      yield put(ProcessingWorkflowData(false));
      yield closeModal();
      yield ShowToast({
        type: 'success',
        message: res.data.data.message ? res.data.data.message : `Transaction ${approvalType === 'approve' ? 'approved' : 'rejected'} successfully`,
      });
      yield put(
        FetchTransactionData({
          workflowCode: selectedWorkflow,
          email: email,
          applicationCode: selectedClient,
        })
      );
    } else {
      yield put(ProcessingWorkflowData(false));
      yield ShowToast({
        type: 'error',
        message: res.data.message,
      });
    }
  } catch (err) {
    yield put(ProcessingWorkflowData(false));
    yield ShowToast({
      type: 'error',
      message: err.response.data.responseException.exceptionMessage,
    });
  }
}
export function* WatchSaveApprovalForm() {
  yield takeLatest(MakerCheckerActionTypes.SAVE_TRANSACTION_APPROVAL, SaveApprovalFormAsync);
}

export default function* makerCheckerSagas() {
  yield all([
    call(WatchFetchAllWorkflows),
    call(WatchSaveWorkflow),
    call(WatchCreateMakerClaim),
    call(WatchCreateCheckerLevels),
    call(WatchFetchClientData),
    call(WatchFetchWorkflowByCode),
    call(WatchFetchWorkflowClaim),
    call(WatchFetchWorkflowLevels),
    call(WatchFetchApplictionList),
    call(WatchFetchWorkflowList),
    call(WatchFetchTransactions),
    call(WatchSaveApprovalForm),
    call(WatchSaveNewApplicationForm),
  ]);
}
