import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import Moment from 'react-moment';
import moment from 'moment';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';

import { Button } from '../../../shared';
import { SaveTransactionApproval } from '../../../../store/maker-checker/maker-checker-actions';
import './commentModal.scss';
import OtpModal from '../../../shared/otpModal/otpModal';

const CommentModal = ({
  openModal,
  closeModal,
  currentUser,
  processing,
  transactionReference,
  applicationCode,
  approvalType,
  email,
  selectedClient,
  selectedWorkflow,
}) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [formPayload, setFormPayload] = useState(null);
  const [show, setShow] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [comment, setComment] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
  };
  const handleSubmit = () => {
    const f = {
      email: currentUser?.email,
      transactionReference,
      dateApproved: moment(Date.now()).format(),
      approvedByUserId: currentUser?.sid,
      approvedByUserName: currentUser?.preferred_username,
      approvalComment: comment,
    };
    setFormPayload(f);
  };

  const submitForm = (e) => {
    dispatch(
      SaveTransactionApproval({
        formPayload,
        closeModal,
        applicationCode,
        approvalType,
        email,
        selectedClient,
        selectedWorkflow,
      })
    );
  };

  const onValidateOtp = () => {
    form.submit();
  };
  useEffect(() => {
    if (formPayload) {
      setShowOtp(true);
    }
  }, [formPayload]);

  useEffect(() => {
    openModal ? handleShow() : handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <OtpModal
        openModal={showOtp}
        closeModal={() => setShowOtp(false)}
        onValidateOtp={() => onValidateOtp()}
      />
      <Modal
        show={show}
        onHide={closeModal}
        className='comment-modal-container'
      >
        <Modal.Header closeButton>
          {approvalType === 'approve' ? 'Approve' : 'Reject'} Transaction
        </Modal.Header>
        <hr className='header-border' />
        <Modal.Body>
          <div className='default-content mb-3'>
            <div className='default-item d-flex justify-content-between align-items-center w-100 mb-1'>
              <div className='item-label'>Email</div>
              <div className='item-value'>{currentUser?.email}</div>
            </div>
            <div className='default-item d-flex justify-content-between align-items-center mb-1'>
              <div className='item-label'>Transaction Ref</div>
              <div className='item-value'>{transactionReference}</div>
            </div>
            <div className='default-item d-flex justify-content-between align-items-center mb-1'>
              <div className='item-label'>Date</div>
              <div className='item-value'>
                <Moment format='D MMMM YYYY' withTitle>
                  {Date.now()}
                </Moment>
              </div>
            </div>
            <div className='default-item d-flex justify-content-between align-items-center mb-1'>
              <div className='item-label'>Checker ID</div>
              <div className='item-value'>{currentUser?.sid}</div>
            </div>
            <div className='default-item d-flex justify-content-between align-items-center'>
              <div className='item-label'>Checker Username</div>
              <div className='item-value'>
                {currentUser?.preferred_username}
              </div>
            </div>
          </div>
          <AvForm
            className='comment-form'
            onValidSubmit={submitForm}
            ref={(c) => setForm(c)}
          >
            <label className='form-label'>Comment</label>
            <AvInput
              type='textarea'
              rows={4}
              name='approvalComment'
              placeholder='Enter approval comment'
              onChange={handleCommentChange}
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Please enter approval comment',
                },
              }}
            />
          </AvForm>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              outline={true}
              color='orange'
              handleClick={closeModal}
              className='mr-2'
            >
              Cancel
            </Button>
            <Button
              color={approvalType === 'approve' ? 'green' : 'destructive'}
              handleClick={handleSubmit}
              disabled={false}
            >
              {processing ? (
                <span>
                  Saving... <Spinner size='sm' />
                </span>
              ) : (
                'Confirm'
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommentModal;
