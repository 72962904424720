import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactComponent as VGGLogo } from '../../vgg-logo.svg';

import Button from '../shared/button/Button';
import clientIdentity from '../../utils/user-manager';
import { Colors } from '../../utils/colors';
import DocumentTopNav from '../layout/top-nav/documentTopNav/documentTopNav';

import './login.scss';

class Login extends Component {
  state = {
    isProcessing: false,
  };

  componentDidMount() {
    this.setPageTitle();
  }

  setPageTitle = () => {
    const prevTitle = document.title;
    document.title = `Login | ${prevTitle}`;
  };
  handleLogin = e => {
    // e.persist();
    // e.preventDefault();
    this.setState({ isProcessing: true });
    clientIdentity.signinRedirect().catch(() => this.setState({ isProcessing: false }));
  };

  render() {
    return (
      <section className='login-page-container'>
        <div className='row documents'>
          <div className='col-12'>
            <DocumentTopNav />
          </div>
        </div>

        <div className='row main-content'>
          <div className='col'>
            <VGGLogo />
            <div className='identity-server'>Identity Server</div>
            <div className='subtitle'>Securely authenticate and manage multiple applications and websites with just one set of credentials.</div>
            <Button handleClick={this.handleLogin} loading={this.state.isProcessing} size='sm' color={Colors.GREEN}>
              Sign In
            </Button>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='footer'>
              <div className='footer-content'>© 2020 - VGG</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Login);
