import React from "react";

const ListSingValue = ({ details, title }) => {
  if (details === null || !Array.isArray(details)) {
    return null;
  }
  return (
    <div className="client-details-container">
      <table className="table table-striped">
        <thead>
          <tr>
            <td>{title}</td>
          </tr>
        </thead>
        <tbody>
          {details &&
            details?.map((val) => {
              return (
                <tr key={val.Id}>
                  <td>{val.Scope}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ListSingValue;
