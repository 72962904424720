import React, { useEffect, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import InfoTip from "../../shared/infoTip/infoTip";
import InfoTipText from "../../shared/infoTip/infoTipText";

const ScopeClaim = ({ title, claims, singleResult, tipText }) => {
  const [scopeClaims, setScopeClaims] = useState([]);
  const [form, setForm] = useState([]);
  const [myClaim, setMyClaim] = useState({});

  const submitScope = (event, values) => {
    const result = [...scopeClaims, values];
    setScopeClaims(result);
    singleResult(title, result);
    setMyClaim({});
    form.reset();
  };

  const remove = (claim) => {
    const index = scopeClaims.indexOf(claim);
    scopeClaims.splice(index, 1);
    setScopeClaims([...scopeClaims]);
  };

  const edit = (claim) => {
    setTimeout(() => form.reset(), 30);
    setMyClaim(claim);
    remove(claim);
  };

  useEffect(() => {
    claims && setScopeClaims(claims);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AvForm className="row" onValidSubmit={submitScope} ref={(c) => setForm(c)} model={myClaim}>
        <div className="form-group col">
          <label htmlFor="name">
            Name <InfoTip infoText={InfoTipText.scopes.scopeClaim.Name} />
          </label>
          <AvField
            type="text"
            className="form-control"
            name="Name"
            id="name"
            placeholder="Enter Name"
            validate={{
              required: { value: true, errorMessage: "Please enter a name" },
            }}
          />
        </div>
        <div className="form-group col">
          <label htmlFor="display-name">
            Description <InfoTip infoText={InfoTipText.scopes.scopeClaim.Description} />
          </label>
          <AvField
            type="text"
            className="form-control"
            name="Description"
            id="description"
            placeholder="Enter description"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter a description",
              },
            }}
          />
        </div>
        <div className="form-group col">
          <label htmlFor="token">
            Always include in token <InfoTip infoText={InfoTipText.scopes.scopeClaim.AlwaysIncludeInIdToken} />
          </label>
          <AvField
            type="select"
            className="form-control"
            name="AlwaysIncludeInIdToken"
            id="token"
            validate={{
              required: { value: true, errorMessage: "Please select one" },
            }}
          >
            <option></option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </AvField>
        </div>
        <div className="col d-flex align-items-center">
          <button type="submit" className="btn btn-sm btn-success">
            Add Claim
          </button>
        </div>
      </AvForm>
      {scopeClaims && scopeClaims.length > 0 && (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Always include in token</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {scopeClaims &&
                scopeClaims.map((value, i) => {
                  return (
                    <tr key={i}>
                      <td>{value.Name}</td>
                      <td>{value.Description}</td>
                      <td>{value.AlwaysIncludeInIdToken.toString()}</td>
                      <td>
                        <span className="edit-item" onClick={() => edit(value)}>
                          Edit
                        </span>
                        <span className="delete-item" onClick={() => remove(value)}>
                          Delete
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ScopeClaim;
