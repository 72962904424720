import React, { useEffect, useRef, useState } from "react";
import SsoAdminOnboardView from "./OnboardingViews/ssoAdminOnboardView";
import OtherUsersOnboardView from "./OnboardingViews/otherUsersOnboardView";
import { useTitle } from "../../utils/useTitle";
import _debounce from "lodash.debounce";

const Onboarding = () => {
  useTitle("Onboarding");
  const [viewForSsoAdmin, setViewForSsoAdmin] = useState(null);
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const loggedInUserRole = loggedInUser.profile["ssoui.role"];

  useEffect(() => {
    if (loggedInUserRole === "sso_admin") {
      setViewForSsoAdmin(true);
    } else {
      setViewForSsoAdmin(false);
    }
  }, []);

  // define roles that can be created based on logged in user
  const defineOnboardRole = (input) => {
    switch (input) {
      case "sso_admin":
        return "vgg_superadmin";

      case "vgg_superadmin":
        return "vgg_admin";

      default:
        return " ";
    }
  };

  return (
    <div>
      {viewForSsoAdmin ? (
        <SsoAdminOnboardView
          onboardRole={defineOnboardRole(loggedInUser.profile["ssoui.role"])}
        />
      ) : (
        <OtherUsersOnboardView
          onboardRole={defineOnboardRole(loggedInUser.profile["ssoui.role"])}
        />
      )}
    </div>
  );
};

export default Onboarding;
