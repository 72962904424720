import React from 'react';
import Shimmer from 'react-shimmer-effect';

/**
 *
 * @param {rows, columnWidths} props
 * @rows: Specify number of rows
 * @columnWidths: Specify the number of columns for the shimmer to use by providing a list of widths in percentage
 */
const ShimmerTableRowLoader = props => {
  const { rows, columnWidths } = props;
  const rowList = [];
  const columnList = [];
  for (let i = 1; i <= (rows || 5); i++) {
    rowList.push(i);
  }

  return (
    <tbody>
      {rowList.map(row => (
        <tr key={row} style={{ padding: 0, margin: 0 }}>
          {columnWidths.map((columnWidth, idx) => (
            <td width={`${columnWidth}`} key={`${columnWidth}${idx}`} style={{ display: 'table-cell' }}>
              <Shimmer>
                <div className='shimmer-line' />
              </Shimmer>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default ShimmerTableRowLoader;
