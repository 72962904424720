import React from 'react';
import { useHistory } from 'react-router-dom';

import './titlePage.scss';

export const PageTitle = ({ url, title, showArrow }) => {
    const history = useHistory()
    return (
        <span className="title-page-container">
            {showArrow && <span onClick={() => history.push(url)}
                className="mr-2 goback">
                <i className="ri-arrow-left-line"></i>
            </span>}
            <span className="title">{title}</span>
        </span>
    )
}