
export const GetRoles =() =>{
        const userClaim =  localStorage.getItem('user_claim');
        if(userClaim) {
            const claims = JSON.parse(userClaim);
            const roleClaims = claims.map(val => {
                if(val.Type && val.Type !== '') {
                  return val.Type.toLowerCase()
                }
                return ''
            });
            return roleClaims;
        }
};
export const validateRole = (...userRoles) => {
    const userDetails =  JSON.parse(localStorage.getItem('user'));
    const role = userDetails.profile && userDetails.profile['ssoui.role'];

    if(userRoles.length > 0 && role && role !== ''){
        return userRoles.indexOf(role.toLowerCase()) > -1
    }else{
        return false
    } 
}

export const ROLENAMES = {
    SSOADMIN: "sso_admin",
    VGGSUPERADMIN: "vgg_superadmin",
    VGGADMIN: "vgg_admin",
    CLIENTADMIN: "client_admin",
    CLIENTUSER: "client_user",
}