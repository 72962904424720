import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import ClientDetails from "../clientDetails/clientDetails";
import ListSingleValue from "../../../shared/listSingleValue/listSingleValue";
import Claim from "../claims/claims";
// import ClientClaims from './clientClaims/clientClaims';
import ClientSecrets from "../../../shared/secretDetail/secretDetail";

const ViewContent = ({ select }) => {
  const [key, setKey] = useState("Details");
  return (
    <div className="card card-content">
      <div className="card-body tab-content">
        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab eventKey="Details" title="Client Details">
            <ClientDetails details={select.clients} />
          </Tab>
          <Tab eventKey="Scopes" title="Allowed Scopes">
            <ListSingleValue details={select.clients.AllowedScopes} title="Scopes" />
          </Tab>
          <Tab eventKey="Redirect" title="Redirect URIS">
            <ListSingleValue details={select.clients.RedirectUris} title="URI" />
          </Tab>
          <Tab eventKey="PostLogout" title="Post Logout Redirect URLS">
            <ListSingleValue details={[]} title="Origin" />
          </Tab>
          <Tab eventKey="AllowedCORS" title="Allowed CORS Origins">
            <ListSingleValue details={select.clients.PostLogoutRedirectUris} title="URI" />
          </Tab>
          <Tab eventKey="ClientSecrets" title="Client Secrets">
            <ClientSecrets details={select.clients.ClientSecrets} />
          </Tab>
          <Tab eventKey="Claims" title="Claims">
            <Claim details={select.clients} />
          </Tab>
          <Tab eventKey="IdentityProvider" title="Identity Provider Restrictions">
            <ListSingleValue details={select.clients.IdentityProviderRestrictions} title="Provider" />
          </Tab>
          <Tab eventKey="AllowedCustom" title="Allowed Custom Grant Types">
            <ListSingleValue details={[]} title="Grant Types" />
          </Tab>
          <Tab eventKey="CallbackNotification" title="Callback Notification URL">
            <ListSingleValue details={select.clients.CallBackNotificationUrl} title="Callback Url" />
          </Tab>
          <Tab eventKey="RemoveClaim" title="Remove Claim Notification URL">
            <ListSingleValue details={select.clients.RemoveClaimNotificationUrl} title="Remove Claim Notification Url" />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ViewContent;
