import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";

import { fetchClients, addClientAuthorization } from "../../../store/clients/clients-actions";
import { Urls } from "../../../utils/urls";
import { Colors } from "../../../utils/colors";
import Button from "../../shared/button/Button";

import "./authorizedClient.scss";
import OtpModal from "../../shared/otpModal/otpModal";

const AuthorizedClient = ({ openModal, closeModal }) => {
  const [form, setForm] = useState([]);
  const [payload, setPayload] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const select = useSelector((state) => {
    return {
      addClients: state.clients && state.clients.addData,
      processing: state.clients && state.clients.addProcessing,
      clientList: state.clients && state.clients.clients.data && state.clients.clients.data.Response,
      clientLoading: state.clients && state.clients.isLoading,
    };
  });

  const { clientList, clientLoading, addClients, processing } = select;

  const submitForm = (event, value) => {
    // const payload = { ClientId: value["ClientId"], Enable: true };
    setPayload({ ClientId: value["ClientId"], Enable: true });
    setModalShow(true);
  };

  useEffect(() => {
    dispatch(fetchClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    openModal ? handleShow() : handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    if (addClients) {
      form.reset();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addClients]);
  return (
    <div>
      <OtpModal openModal={modalShow} closeModal={() => setModalShow(false)} onValidateOtp={() => dispatch(addClientAuthorization(payload))} />

      <Modal centered show={show} onHide={closeModal} className="map-application-modal-container">
        <Modal.Header closeButton>Authorize New Client</Modal.Header>
        <Modal.Body>
          <AvForm onValidSubmit={submitForm} ref={(c) => setForm(c)}>
            <div className="row">
              <div className="form-group col-12">
                <label htmlFor="clientId">Select a Client Id &nbsp; {clientLoading && <Spinner size="sm" color={"green"} />}</label>
                <AvField
                  type="select"
                  className="form-control"
                  name="ClientId"
                  id="clientId"
                  validate={{
                    required: { value: true, errorMessage: "Please select one" },
                  }}
                >
                  <option></option>
                  {clientList &&
                    clientList.map((val) => {
                      return (
                        <option key={val.ClientId} value={val.ClientId}>
                          {val.ClientName}
                        </option>
                      );
                    })}
                </AvField>
              </div>
            </div>
          </AvForm>
          <div className="d-flex justify-content-end pr-3">
            <Button
              size="sm"
              outline={true}
              color={Colors.GREEN}
              handleClick={() => {
                closeModal();
              }}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button size="sm" color={Colors.GREEN} handleClick={() => form.submit()} loading={processing} disabled={processing}>
              Authorize
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AuthorizedClient;
