import React from "react";
import DocumentTopNav from './documentTopNav/documentTopNav';
import MainTopNav from './mainNavTop/mainNavTop';
import "./topNavBar.scss";

export const TopNav = (props) => {
  const {
    handleSignout,
    userProfile,
    isCollapse
  } = props;
  return (
    <div className="row top-nav-container fixed-top">
      <div className="col-12">
        <DocumentTopNav />
        <MainTopNav userProfile={userProfile} handleSignout={handleSignout} isCollapse={isCollapse} />
      </div>

    </div>
  );
};

export default TopNav;
