import React from "react";
import "./progressBar.scss";

const ProgressBar = (props) => {
  const { completed, bgColor, showLabel } = props;
  return (
    <div className="progress-bar-container mt-3">
      <div
        style={{
          height: "100%",
          backgroundColor: bgColor,
          width: `${completed}%`,
          borderRadius: "inherit",
          textAlign: "right",
        }}
      >
        {showLabel && <span className="label">{`${completed}%`}</span>}
      </div>
    </div>
  );
};

export default ProgressBar;
