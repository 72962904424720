import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Select } from 'antd';
import _debounce from 'lodash.debounce';
import { FetchAllWorkflowData, FetchClientData, FetchWorkflowDetailsSuccess } from '../../store/maker-checker/maker-checker-actions';
import { Button, Paginator, ShimmerTableRowLoader } from '../shared';
import { DefineWorkflowNextStep, EditWorkflow, Urls } from '../../utils/urls';
import './makerChecker.scss';
import { CLIENT_ID } from '../../constants/app-constant';
import { useTitle } from '../../utils/useTitle';

const MakerChecker = () => {
  useTitle('Maker Checker Workflows');
  const { Option } = Select;
  const history = useHistory();
  const dispatch = useDispatch();
  const { workflows, fetchingData, clientData, editDataLoaded } = useSelector(state => state.makerChecker);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [selectedClient, setSelectedClient] = useState(CLIENT_ID);

  const handlePageClick = data => {
    let selected = data.selected;
    setCurrentPage(selected + 1);
  };
  const handleFilter = (input, option) => {
    return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const handleCreateWorkflow = e => {
    e.preventDefault();
    history.push(Urls.DefineWorkflow);
  };

  const handleEditCheckers = (e, recordCode) => {
    e.preventDefault();
    history.push(DefineWorkflowNextStep(selectedClient, recordCode));
    if (editDataLoaded) {
    }
  };

  const handleEditWorkflow = (e, record) => {
    e.preventDefault();
    dispatch(FetchWorkflowDetailsSuccess(record));
    history.push(EditWorkflow(selectedClient, record.code));
  };

  const handleSearch = e => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleItemsPerPage = value => {
    setPerPage(value);
  };

  const handleClientChange = client => {
    setSelectedClient(client);
  };

  const search = rows => {
    const columns = ['name', 'code', 'level'];
    if (!selectedRow) {
      return rows.filter(row => columns.some(column => row[column].toString().toLowerCase().indexOf(searchTerm) > -1));
    }
    return rows.filter(row => row[`${selectedRow}`].toString().toLowerCase().indexOf(searchTerm) > -1);
  };

  useEffect(() => {
    dispatch(FetchClientData({ type: 'all', pageSize: 20 }));
    dispatch(FetchAllWorkflowData({ applicationCode: CLIENT_ID }));
    // dispatch(FetchAllWorkflowData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(FetchAllWorkflowData({ applicationCode: selectedClient }));
    // dispatch(FetchAllWorkflowData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  const lastItemIndex = currentPage * perPage;
  const firstItemIndex = lastItemIndex - perPage;
  const currentItems = workflows.length ? workflows.slice(firstItemIndex, lastItemIndex) : [];
  return (
    <section className='row maker-checker-container'>
      <div className='col'>
        <div className='d-flex flex-column justify-content-center align-items-end  flex-lg-row justify-content-lg-between align-items-lg-center'>
          <div className='sp-disp-heading align-self-start mb-3 mb-lg-0 font-weight-bold'>Maker Checker</div>
          <div className='d-flex flex-column-reverse flex-lg-row justify-content-end align-items-sm-end align-items-lg-center'>
            <div style={{ width: '280px', maxWidth: '280px' }} className='mr-0 mr-lg-2 mt-2 mt-lg-0 client-select'>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder='Select Application'
                optionFilterProp='children'
                onChange={handleClientChange}
                // onSearch={onSearch}
                filterOption={handleFilter}
              >
                <Option></Option>
                {clientData &&
                  clientData.map((client, idx) => (
                    <Option key={idx} value={client.value}>
                      {client.label}
                    </Option>
                  ))}
              </Select>
            </div>
            <div style={{ maxWidth: '320px' }} className='align-self-end align-self-lg-center mt-2 mt-lg-0 mr-sm-0 mr-lg-2'>
              <div className='input'>
                <input className='input__text input__search' type='text' placeholder='Search' value={searchTerm} onChange={handleSearch} />
                <button className='input__btn'>
                  <i className='ri-search-line'></i>
                </button>
              </div>
            </div>
            <div className='justify-self-end align-self-end align-self-lg-center justify-self-lg-center mt-2 mt-lg-0'>
              <Button size='sm' color='green' handleClick={handleCreateWorkflow}>
                New Workflow
              </Button>
            </div>
          </div>
        </div>
        <div className='card card-content'>
          <div className='card-body'>
            <div className='row mb-3 w-100'>
              <div className='col-12 d-flex align-items-center justify-content-between mb-3 mb-lg-0 px-0'>
                <div className='sp-section-title'>Existing Workflow</div>
                <div className='entry-number d-flex flex-column flex-lg-row align-items-start justify-content-center align-items-lg-center justify-content-lg-end'>
                  <span className='mr-2 showing'>showing:</span>
                  <div className='' style={{ maxWidth: 142 }}>
                    <Select showSearch style={{ width: '100%' }} placeholder='Select a Client' optionFilterProp='children' onChange={handleItemsPerPage} value={perPage}>
                      <Option value={5}>5 Entries</Option>
                      <Option value={10}>10 Entries</Option>
                      <Option value={15}>15 Entries</Option>
                      <Option value={20}>20 Entries</Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className='row table-container'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Levels</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {fetchingData ? (
                  <ShimmerTableRowLoader rows={perPage} columnWidths={['54%', '15%', '10%', '21%']} />
                ) : (
                  <tbody>
                    {search(currentItems).length ? (
                      search(currentItems).map((value, i) => {
                        return (
                          <tr key={value.id}>
                            <td width='45%'>{value.name}</td>
                            <td width='15%'>{value.code}</td>
                            <td width='10%'>{value.level}</td>
                            <td width='30%' className='pr-0'>
                              <div className='row w-100 mx-0'>
                                <div className='col-12 col-lg-5 px-0'>
                                  <a href='#/' className='action' onClick={e => handleEditCheckers(e, value.code)}>
                                    Edit Checkers
                                  </a>
                                </div>
                                <div className='col-12 col-lg-5 px-0 mx-0'>
                                  <a href='#/' className='action' onClick={e => handleEditWorkflow(e, value)}>
                                    Edit Workflow
                                  </a>
                                </div>
                                <div className='col-lg-2 px-0 mx-0'></div>
                              </div>
                              {/* <a href='#/' className='action' onClick={e => handleEditCheckers(e, value.code)}>
                                Edit Checkers
                              </a>
                              <a href='#/' className='action' onClick={e => handleEditWorkflow(e, value)}>
                                Edit Workflow
                              </a> */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan='4' className='font-weight-bold text-center pt-4'>
                          Not data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </Table>
            </div>
            <Paginator currentPage={currentPage} perPage={perPage} pageCount={Math.ceil(workflows.length / perPage)} totalItems={workflows.length} handlePageClick={handlePageClick} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MakerChecker;
