export const MakerCheckerActionTypes = {
  FETCHING_DATA: '[ MAKER-CHECKER - STORE ] FETCHING WORKFLOWS',
  FETCHING_WORKFLOW_LIST: '[ MAKER-CHECKER - STORE ] FETCHING WORKFLOW LIST',
  FETCHING_CLIENT_DATA: '[ MAKER-CHECKER - STORE ] FETCHING CLIENT DATA',
  FETCHING_APPLICATIONS: '[ MAKER-CHECKER - STORE ] FETCHING APPLICATIONS',
  PROCESSING_WORKFLOW_DATA: '[ MAKER-CHECKER - STORE ] PROCESSING WORKFLOW DATA',

  FETCH_CLIENT_DATA: '[ MAKER-CHECKER - STORE ] FETCH CLIENT DATA',
  FETCH_CLIENT_DATA_SUCCESS: '[ MAKER-CHECKER - STORE ] FETCH CLIENT DATA SUCCESS',
  FETCH_CLIENT_CLAIMS: '[ MAKER-CHECKER - STORE ] FETCH CLIENT CLAIMS DATA',

  FETCH_ALL_WORKFLOWS: '[ MAKER-CHECKER - STORE ] FETCH ALL WORKFLOWS',
  FETCH_ALL_WORKFLOWS_SUCCESS: '[ MAKER-CHECKER - STORE ] FETCH ALL WORKFLOWS SUCCESS',

  FETCH_WORKFLOW_LIST: '[ MAKER-CHECKER - STORE ] FETCH WORKFLOW LIST',
  FETCH_WORKFLOW_LIST_SUCCESS: '[ MAKER-CHECKER - STORE ] FETCH WORKFLOW LIST SUCCESS',

  FETCHING_TRANSACTIONS: '[ MAKER-CHECKER - STORE ] FETCHING TRANSACTIONS',
  FETCH_TRANSACTION_DATA: '[ MAKER-CHECKER - STORE ] FETCH TRANSACTION DATA',
  FETCH_TRANSACTION_DATA_SUCCESS: '[ MAKER-CHECKER - STORE ] FETCH TRANSACTION DATA SUCCESS',
  FETCH_ALL_WORKFLOWS_FAILURE: '[ MAKER-CHECKER - STORE ] FETCH ALL WORKFLOWS FAILURE',

  FETCH_WORKFLOW_DETAILS: '[ MAKER-CHECKER - STORE ] FETCH WORKFLOW DETAILS',
  FETCH_WORKFLOW_DETAILS_SUCCESS: '[ MAKER-CHECKER - STORE ] FETCH WORKFLOW DETAILS SUCCESS',

  FETCH_WORKFLOW_CLAIM: '[ MAKER-CHECKER - STORE ] FETCH WORKFLOW CLAIM',
  FETCH_WORKFLOW_CLAIM_SUCCESS: '[ MAKER-CHECKER - STORE ] FETCH WORKFLOW CLAIM SUCCESS',

  FETCH_WORKFLOW_LEVELS: '[ MAKER-CHECKER - STORE ] FETCH WORKFLOW LEVELS',
  FETCH_WORKFLOW_LEVELS_SUCCESS: '[ MAKER-CHECKER - STORE ] FETCH WORKFLOW LEVELS SUCCESS',

  FETCH_APPLICATION_LIST: '[ MAKER-CHECKER - STORE ] FETCH APPLICATION LIST',
  FETCH_APPLICATION_LIST_SUCCESS: '[ MAKER-CHECKER - STORE ] FETCH APPLICATION LIST SUCCESS',

  SAVE_WORKFLOW: '[ MAKER-CHECKER - STORE ] SAVE WORKFLOW',
  SAVED_WORKFLOW_DATA: '[ MAKER-CHECKER - STORE ] SAVED WORKFLOW DATA',
  SAVE_WORKFLOW_SUCCESS: '[ MAKER-CHECKER - STORE ] SAVE WORKFLOW SUCCESS',

  CREATING_MAKER_CLAIM: '[ MAKER-CHECKER - STORE ] CREATING MAKER CLAIM',
  CREATE_MAKER_CLAIM: '[ MAKER-CHECKER - STORE ] CREATE MAKER CLAIM',
  CREATE_MAKER_CLAIM_SUCCESS: '[ MAKER-CHECKER - STORE ] CREATE MAKER CLAIM SUCCESS',

  CREATING_CHECKER_LEVELS: '[ MAKER-CHECKER - STORE ] CREATEING CHECKER LEVELS',
  CREATE_CHECKER_LEVELS: '[ MAKER-CHECKER - STORE ] CREATE CHECKER LEVELS',
  CREATE_CHECKER_LEVELS_SUCCESS: '[ MAKER-CHECKER - STORE ] CREATE CHECKER LEVELS SUCCESS',
  SAVE_TRANSACTION_APPROVAL: '[ MAKER-CHECKER - STORE ] SAVE TRANSACTION APPROVAL',
  SAVE_NEW_APPLICATION: '[ MAKER-CHECKER - STORE ] SAVE NEW APPLICATION',
};

export const FetchingWorkflowData = payload => ({
  type: MakerCheckerActionTypes.FETCHING_DATA,
  payload,
});

export const FetchingWorkflowList = payload => ({
  type: MakerCheckerActionTypes.FETCHING_WORKFLOW_LIST,
  payload,
});

export const FetchingClientData = payload => ({
  type: MakerCheckerActionTypes.FETCHING_CLIENT_DATA,
  payload,
});

export const FetchingApplicationList = payload => ({
  type: MakerCheckerActionTypes.FETCHING_APPLICATIONS,
  payload,
});

export const FetchClientData = payload => ({
  type: MakerCheckerActionTypes.FETCH_CLIENT_DATA,
  payload,
});

export const FetchClientDataSuccess = payload => ({
  type: MakerCheckerActionTypes.FETCH_CLIENT_DATA_SUCCESS,
  payload,
});

export const FetchClientClaimsData = payload => ({
  type: MakerCheckerActionTypes.FETCH_CLIENT_CLAIMS,
  payload,
});

export const FetchAllWorkflowData = payload => ({
  type: MakerCheckerActionTypes.FETCH_ALL_WORKFLOWS,
  payload,
});

export const FetchAllWorkflowDataSuccess = payload => ({
  type: MakerCheckerActionTypes.FETCH_ALL_WORKFLOWS_SUCCESS,
  payload,
});

export const FetchWorkflowList = payload => ({
  type: MakerCheckerActionTypes.FETCH_WORKFLOW_LIST,
  payload,
});

export const FetchWorkflowListSuccess = payload => ({
  type: MakerCheckerActionTypes.FETCH_WORKFLOW_LIST_SUCCESS,
  payload,
});

export const FetchingTransactions = payload => ({
  type: MakerCheckerActionTypes.FETCHING_TRANSACTIONS,
  payload,
});

export const FetchTransactionData = payload => ({
  type: MakerCheckerActionTypes.FETCH_TRANSACTION_DATA,
  payload,
});

export const FetchTransactionDataSuccess = payload => ({
  type: MakerCheckerActionTypes.FETCH_TRANSACTION_DATA_SUCCESS,
  payload,
});

export const FetchAllWorkflowDataFailure = payload => ({
  type: MakerCheckerActionTypes.FETCH_ALL_WORKFLOWS_FAILURE,
  payload,
});

export const FetchApplicationList = () => ({
  type: MakerCheckerActionTypes.FETCH_APPLICATION_LIST,
});

export const FetchApplicationListSuccess = payload => ({
  type: MakerCheckerActionTypes.FETCH_APPLICATION_LIST_SUCCESS,
  payload,
});

export const SaveWorkflow = data => ({
  type: MakerCheckerActionTypes.SAVE_WORKFLOW,
  payload: data,
});

export const SavedWorkflowData = data => ({
  type: MakerCheckerActionTypes.SAVED_WORKFLOW_DATA,
  payload: data,
});

export const SaveWorkflowSuccess = payload => ({
  type: MakerCheckerActionTypes.SAVE_WORKFLOW_SUCCESS,
  payload,
});

export const ProcessingWorkflowData = payload => ({
  type: MakerCheckerActionTypes.PROCESSING_WORKFLOW_DATA,
  payload,
});

export const FetchWorkflowDetails = payload => ({
  type: MakerCheckerActionTypes.FETCH_WORKFLOW_DETAILS,
  payload,
});

export const FetchWorkflowDetailsSuccess = payload => ({
  type: MakerCheckerActionTypes.FETCH_WORKFLOW_DETAILS_SUCCESS,
  payload,
});

export const FetchWorkflowClaim = payload => ({
  type: MakerCheckerActionTypes.FETCH_WORKFLOW_CLAIM,
  payload,
});

export const FetchWorkflowClaimSuccess = payload => ({
  type: MakerCheckerActionTypes.FETCH_WORKFLOW_CLAIM_SUCCESS,
  payload,
});

export const FetchWorkflowLevels = payload => ({
  type: MakerCheckerActionTypes.FETCH_WORKFLOW_LEVELS,
  payload,
});

export const FetchWorkflowLevelsSuccess = payload => ({
  type: MakerCheckerActionTypes.FETCH_WORKFLOW_LEVELS_SUCCESS,
  payload,
});

export const CreatingMakerClaim = payload => ({
  type: MakerCheckerActionTypes.CREATING_MAKER_CLAIM,
  payload,
});

export const CreateMakerClaim = record => ({
  type: MakerCheckerActionTypes.CREATE_MAKER_CLAIM,
  payload: record,
});

export const CreateMakerClaimSuccess = payload => ({
  type: MakerCheckerActionTypes.CREATE_MAKER_CLAIM_SUCCESS,
  payload,
});

export const CreatingCheckerLevels = payload => ({
  type: MakerCheckerActionTypes.CREATING_CHECKER_LEVELS,
  payload,
});

export const CreateCheckerLevels = payload => ({
  type: MakerCheckerActionTypes.CREATE_CHECKER_LEVELS,
  payload,
});

export const CreateCheckerLevelsSuccess = payload => ({
  type: MakerCheckerActionTypes.CREATE_CHECKER_LEVELS_SUCCESS,
  payload,
});

export const SaveTransactionApproval = payload => ({
  type: MakerCheckerActionTypes.SAVE_TRANSACTION_APPROVAL,
  payload,
});

export const SaveNewApplication = payload => ({
  type: MakerCheckerActionTypes.SAVE_NEW_APPLICATION,
  payload,
});
