import React from "react";
import ReactPaginate from "react-paginate";
import "./paginator.scss";

const Paginator = ({
  currentPage,
  perPage,
  pageCount,
  handlePageClick,
  totalItems,
}) => {
  return (
    <div className="tb-paginator w-100">
      <div className="pg-count">
        Showing Page {currentPage || 1} of{" "}
        {pageCount
          ? pageCount
          : totalItems
          ? Math.ceil(totalItems / perPage)
          : 1}
      </div>
      <ReactPaginate
        containerClassName={"pg-nav"}
        pageCount={
          pageCount
            ? pageCount
            : totalItems
            ? Math.ceil(totalItems / perPage)
            : 1
        }
        activeClassName={"item-active"}
        previousLabel="prev"
        nextLabel="next"
        onPageChange={handlePageClick}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        breakLabel={<i className="ri-more-line"></i>}
      />
    </div>
  );
};

export default Paginator;
