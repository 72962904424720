import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import {useDispatch, useSelector} from 'react-redux';
import clientIdentity from "../../utils/user-manager";
import { fetchApplicationUserAccount } from '../../store/applications/applications-actions'
import "./auth-callback.scss";

const AuthCallback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [callbackComplete, setCallbackComplete] = useState(false);
  const _isMounted = useRef(true);

  const select = useSelector((state) => {
      return {
          userDetails: state.application && state.application.applicationUserAccount && state.application.applicationUserAccount.data,
          isDetailLoading: state.application && state.application.isDetailLoading,
      };
  });

  useEffect(() => {
    if (select.userDetails) {
      localStorage.setItem('user_claim', JSON.stringify(select.userDetails.Response.Claims));
      history.push('/')
      // return <Redirect to="/" />;
    }
  }, [select.userDetails]);

  useEffect(() => {
    if (_isMounted.current) {
      clientIdentity.signinRedirectCallback().then((user) => {
        if (user) {
          dispatch(fetchApplicationUserAccount(user.profile.sub))
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("user_profile", JSON.stringify(user.profile));
          localStorage.setItem("initialTime", Date.now());
          setCallbackComplete(true);
        }
      });
    }

    
    return () => {
      _isMounted.current = false;
    };
  });


  return (
    <div className="d-flex justify-content-center align-items-center auth-callback">
      <div className="main">
        <div className="container text-center pt-5">
          <p className="mb-3">Performing Verification check...</p>
          <div className="container-item">
            <BeatLoader loading color="#5fbeaa" size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthCallback;
