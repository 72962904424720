import React from "react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

import "./datepicker.scss";

const Datepicker = (props) => {

    const {
        className,
        duration,
        id,
        mode,
        dateFormat,
        maxDate,
        name,
        onChange,
        minDate,
        defaultDate
    } = props;


    return (
        <span className={className}>
            <span className="duration">{duration}</span>
            <Flatpickr
                className="flatpicker-wrapper"
                id={id}
                name={name}
                onChange={onChange}
                options={{
                    mode: mode,
                    dateFormat: dateFormat,
                    maxDate: maxDate,
                    minDate: minDate,
                    // allowInput: true,
                    defaultDate: defaultDate,
                }}
            />
            <a style={{marginLeft: "-29px"}} className="input-button" title="toggle" data-toggle>
                <i className="icon-calendar">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.3334 2.00008H14C14.1769 2.00008 14.3464 2.07032 14.4714 2.19534C14.5965 2.32037 14.6667 2.48994 14.6667 2.66675V13.3334C14.6667 13.5102 14.5965 13.6798 14.4714 13.8048C14.3464 13.9298 14.1769 14.0001 14 14.0001H2.00004C1.82323 14.0001 1.65366 13.9298 1.52864 13.8048C1.40361 13.6798 1.33337 13.5102 1.33337 13.3334V2.66675C1.33337 2.48994 1.40361 2.32037 1.52864 2.19534C1.65366 2.07032 1.82323 2.00008 2.00004 2.00008H4.66671V0.666748H6.00004V2.00008H10V0.666748H11.3334V2.00008ZM2.66671 6.00008V12.6667H13.3334V6.00008H2.66671ZM4.00004 8.66675H7.33337V11.3334H4.00004V8.66675Z"
                            fill="#3A434B"
                        />
                    </svg>
                </i>
            </a>
        </span>
    )
}

export default Datepicker;