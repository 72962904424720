import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import $ from 'jquery';

import { fetchClients } from '../../store/clients/clients-actions';
import { fetchBusinessUnit, fetchApplicationList, fetchApplications } from '../../store/applications/applications-actions';
import { LoadingTableContent } from '../shared/loader/Loader';
import { Colors } from '../../utils/colors';
import Button from '../shared/button/Button';
import ApplicationModal from './applicationForm/applicationForm';
import BusinessUnitForm from './businessNameForm/businessNameForm';
import MapApplication from './mapApplicationForm/mapApplicationForm';
import EnablePrompt from './enablePrompt/enablePrompt';
import { Urls } from '../../utils/urls';
import { useTitle } from '../../utils/useTitle';

import './applications.scss';

const Applications = () => {
  useTitle('Applications');
  const history = useHistory();
  const dispatch = useDispatch();
  const [applicationModalShow, setApplicationModalShow] = useState(false);
  const [businessModalShow, setBusinessModalShow] = useState(false);
  const [mapApplicationModalShow, setMapApplicationModalShow] = useState(false);

  const select = useSelector(state => {
    return {
      applications: state.application && state.application.applications && state.application.applications.data,
      isLoading: state.application && state.application.isLoading,
    };
  });

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchApplications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (select.applications && select.applications.length > 0) {
      $(document).ready(function () {
        $('#applications-table').DataTable();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.applications && select.applications.length]);

  return (
    <section className='row application-container'>
      <ApplicationModal openModal={applicationModalShow} closeModal={() => setApplicationModalShow(false)} />
      <BusinessUnitForm openModal={businessModalShow} closeModal={() => setBusinessModalShow(false)} />
      <MapApplication openModal={mapApplicationModalShow} closeModal={() => setMapApplicationModalShow(false)} />
      <div className='col'>
        <div className='content-header row'>
          <span className='application-title col-auto'>Applications</span>
          <span className='sub-top-btn col-md-auto col-sm-12'>
            <div className='align-right'>
              <Button
                size='sm'
                outline={true}
                color={Colors.GREEN}
                handleClick={() => {
                  setBusinessModalShow(true);
                }}
                className=' mb-1 mr-2 button-width'
              >
                Add Business Unit
              </Button>
              <Button
                size='sm'
                outline={true}
                color={Colors.GREEN}
                handleClick={() => {
                  setApplicationModalShow(true);
                  dispatch(fetchBusinessUnit());
                }}
                className=' mb-1 mr-2 button-width'
              >
                Add New Application
              </Button>
              <Button
                size='sm'
                color={Colors.GREEN}
                handleClick={() => {
                  setMapApplicationModalShow(true);
                  dispatch(fetchBusinessUnit());
                  dispatch(fetchApplicationList());
                }}
                className=' mr-2 button-width'
              >
                Map Application
              </Button>
            </div>
          </span>
        </div>
        <div className='card card-content'>
          <div className='card-body'>
            <div className='row table-container'>
              <Table responsive id='applications-table'>
                <thead>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Business</th>
                    <th scope='col'>Applications</th>
                  </tr>
                </thead>
                {!select.applications && select.isLoading && <LoadingTableContent colSpan={3} />}
                <tbody>
                  {select.applications &&
                    select.applications.map((value, i) => {
                      return (
                        <tr key={value.Id}>
                          <th>{i + 1}</th>
                          <td className='sbu-name'>{value.SbuName}</td>
                          <td>
                            {value.Applications.map(app => {
                              return (
                                <span className='application-name' key={app.Id} onClick={() => history.push(`applications/${app.Id}`)}>
                                  {app.ApplicationName}
                                </span>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Applications;
