import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import BreadCrumb from '../../shared/breadCrumb/breadCrumb';
import { fetchApplicationReport, fetchApplicationUser } from '../../../store/applications/applications-actions';
import { LoadingDashboard } from '../../shared/loader/Loader';
import { Urls, UserViewUrl } from '../../../utils/urls';
import { PageTitle } from '../../shared/titlePage/titlePage';
import './viewApplication.scss';
import { useTitle } from '../../../utils/useTitle';

const ViewClient = () => {
  useTitle('Application Details');
  const dispatch = useDispatch();
  const params = useParams();
  const applicationReports = useSelector(state => {
    return {
      details: state.application && state.application.applicationReport && state.application.applicationReport.data,
      isReportLoading: state.application && state.application.isReportLoading,
    };
  });
  const applicationUser = useSelector(state => {
    return {
      details: state.application && state.application.applicationUser && state.application.applicationUser.data,
      isUserLoading: state.application && state.application.isUserLoading,
    };
  });

  const details = applicationReports.details ? applicationReports.details : {};
  const userDetails = applicationUser.details ? applicationUser.details : {};

  useEffect(() => {
    if (params && params.id) {
      dispatch(fetchApplicationReport(params.id));
      dispatch(fetchApplicationUser(params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className='row application-detail-container'>
      <div className='col'>
        <BreadCrumb title='Applications' titleLink={Urls.Applications} sub1='Application Details' />
        <div className='content-header'>
          <PageTitle url={Urls.Applications} title='Application' showArrow={true} />
        </div>

        {(applicationReports.isReportLoading || applicationUser.isUserLoading) && <LoadingDashboard />}
        {!applicationReports.isReportLoading && !applicationUser.isUserLoading && (
          <>
            <div className='row'>
              <div className='col-md-4'>
                <div className='card card-content'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col'>
                        <span className='application'>{details.ApplicationName}</span>
                        <div>
                          {details.ClientIds &&
                            details.ClientIds.map(id => {
                              return (
                                <span className='application-name' key={id}>
                                  {id}
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-8'>
                <div className='card card-content'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col attempts'>
                        <div className='attempt-status'>
                          <span>Login Attempts</span>
                          <span>{details.TotalAttempts}</span>
                        </div>
                        <div className='attempt-status'>
                          <span>Successful</span>
                          <span>{details.SuccessfulAttempts}</span>
                        </div>
                        <div className='attempt-status'>
                          <span>Failed</span>
                          <span>{details.FailedAttempts}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <div className='card card-content'>
                  <div className='card-body'>
                    <div class='list-group'>
                      <div class='list-group-item colored-card-header'>VGG Super Admin</div>
                      {userDetails.SuperAdmins &&
                        userDetails.SuperAdmins.map(val => {
                          return (
                            <Link to={UserViewUrl(val.UserId)} class='list-group-item list-group-item-action'>
                              <div className='row user-info'>
                                <div className='col'>
                                  <span className='user-name'>{val.FullName}</span>(<span className='user-email'>{val.Email}</span>)
                                </div>
                                <div className='col-auto'>
                                  <span className='application-name'>{val.AppRole}</span>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                    </div>
                  </div>

                  <div className='card-body'>
                    <div class='list-group'>
                      <div class='list-group-item colored-card-header'>Vgg Admin</div>
                      {userDetails.VggAdmins &&
                        userDetails.VggAdmins.map(val => {
                          return (
                            <Link to={UserViewUrl(val.UserId)} class='list-group-item list-group-item-action'>
                              <div className='row user-info'>
                                <div className='col'>
                                  <span className='user-name'>{val.FullName}</span>(<span className='user-email'>{val.Email}</span>)
                                </div>
                                <div className='col-auto'>
                                  <span className='application-name'>{val.AppRole}</span>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                  <div className='card-body'>
                    <div class='list-group'>
                      <div class='list-group-item colored-card-header'>Client Admin</div>
                      {userDetails.ClientAdmins &&
                        userDetails.ClientAdmins.map(val => {
                          return (
                            <Link to={UserViewUrl(val.UserId)} class='list-group-item list-group-item-action'>
                              <div className='row user-info'>
                                <div className='col'>
                                  <span className='user-name'>{val.FullName}</span>(<span className='user-email'>{val.Email}</span>)
                                </div>
                                <div className='col-auto'>
                                  <span className='application-name'>{val.AppRole}</span>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ViewClient;
