import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import "./claimRemove.scss";

const ClaimRemove = ({
  claimList,
  loading,
  handleOnSelectAll,
  handleOnSelect,
}) => {
  const columns = [
    {
      dataField: "Type",
      text: "Type",
    },
    {
      dataField: "Value",
      text: "Value",
    },
  ];
  const selectRow = {
    mode: "checkbox",
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  return (
    <>
      <BootstrapTable
        data={claimList.map((x, idx) => {
          x.id = idx;
          return x;
        })}
        columns={columns}
        selectRow={selectRow}
        keyField="id"
        bordered={false}
      />
    </>
  );
};

export default ClaimRemove;
