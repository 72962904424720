import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BreadCrumb from '../../shared/breadCrumb/breadCrumb';

import { PageTitle } from '../../shared/titlePage/titlePage';
import { Colors } from '../../../utils/colors';
import Button from '../../shared/button/Button';
import { Urls, EditScopePage } from '../../../utils/urls';
import { scopeDetails } from '../../../store/scopes/scopes-actions';
import ViewContent from './viewContent/viewContent';
import OtpModal from '../../shared/otpModal/otpModal';
import './viewScope.scss';
import { useTitle } from '../../../utils/useTitle';

const ViewScope = () => {
  useTitle('Scope Details');
  const dispatch = useDispatch();
  const history = useHistory();
  const [otpShow, setOtpShow] = useState(false);

  const select = useSelector(state => {
    return { scopes: state.scopes && state.scopes.details };
  });

  if (!select.scopes) {
    history.push(Urls.Scopes);
    return null;
  }

  const editScope = details => {
    history.push(EditScopePage(details.Id));
    dispatch(scopeDetails(details));
  };

  const exportToJsonFile = jsonData => {
    let dataStr = JSON.stringify(jsonData);
    let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

    let exportFileDefaultName = `${jsonData.Name}.json`;

    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  return (
    <section className='row scope-detail-container'>
      <OtpModal openModal={otpShow} closeModal={() => setOtpShow(false)} onValidateOtp={() => exportToJsonFile(select.scopes)} />
      <div className='col'>
        <BreadCrumb title='Scopes' titleLink={Urls.Scopes} sub1='Scope Details' />
        <div className='content-header d-flex justify-content-between'>
          <PageTitle url={Urls.Scopes} title='Scopes' showArrow={true} />
          <div>
            <Button
              size='sm'
              outline={true}
              color={Colors.GREEN}
              handleClick={() => {
                setOtpShow(true);
              }}
              className='mr-2'
            >
              Export Scope
            </Button>
            <Button size='sm' color={Colors.GREEN} handleClick={() => editScope(select.scopes)}>
              Edit Scope
            </Button>
          </div>
        </div>

        <ViewContent select={select} />
      </div>
    </section>
  );
};

export default ViewScope;
