import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import InfoTip from "../../shared/infoTip/infoTip";
import InfoTipText from "../../shared/infoTip/infoTipText";

const Secrets = ({ title, secrets, singleResult }) => {
  const [scopeSecrets, setScopeSecrets] = useState(secrets || []);
  const [form, setForm] = useState([]);
  const [mySecret, setMySecret] = useState({});

  const submitScope = (event, values) => {
    const result = [...scopeSecrets, values];
    setScopeSecrets(result);
    singleResult(title, result);
    setMySecret({});
    form.reset();
  };

  const remove = (secret) => {
    const index = scopeSecrets.indexOf(secret);
    scopeSecrets.splice(index, 1);
    setScopeSecrets([...scopeSecrets]);
  };

  const edit = (secret) => {
    setTimeout(() => form.reset(), 30);
    setMySecret(secret);
    remove(secret);
  };

  useEffect(() => {
    secrets && setScopeSecrets(secrets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AvForm className="row" onValidSubmit={submitScope} ref={(c) => setForm(c)} model={mySecret}>
        <div className="form-group col">
          <label htmlFor="value">
            Value <InfoTip infoText={InfoTipText.scopes.scopeSecret.Value} />
          </label>
          <AvField
            type="text"
            className="form-control"
            name="Value"
            id="value"
            placeholder="Enter Value"
            validate={{
              required: { value: true, errorMessage: "Please enter a value" },
            }}
          />
        </div>
        <div className="form-group col">
          <label htmlFor="type">
            Type <InfoTip infoText={InfoTipText.scopes.scopeSecret.Type} />
          </label>
          <AvField
            type="text"
            className="form-control"
            name="Type"
            id="type"
            placeholder="Enter Type"
            validate={{
              required: { value: true, errorMessage: "Please enter a type" },
            }}
          />
        </div>
        <div className="form-group col">
          <label htmlFor="display-name">
            Description <InfoTip infoText={InfoTipText.scopes.scopeSecret.Description} />
          </label>
          <AvField
            type="text"
            className="form-control"
            name="Description"
            id="description"
            placeholder="Enter description"
            validate={{
              required: { value: true, errorMessage: "Please enter a description" },
            }}
          />
        </div>
        <div className="form-group col">
          <label htmlFor="Expiration">
            Expiration <InfoTip infoText={InfoTipText.scopes.scopeSecret.Expiration} />
          </label>
          <AvField
            type="date"
            className="form-control"
            name="Expiration"
            id="expiration"
            placeholder="Enter expiration"
            validate={{
              required: { value: true, errorMessage: "Please enter expiration" },
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <button type="submit" className="btn btn-sm btn-success">
            Add Secret
          </button>
        </div>
      </AvForm>
      {scopeSecrets && scopeSecrets.length > 0 && (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Value</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Expiration</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {scopeSecrets.map((value, i) => {
                return (
                  <tr key={i}>
                    <td>{value.Value}</td>
                    <td>{value.Type}</td>
                    <td>{value.Description}</td>
                    <td>{value.Expiration}</td>
                    <td>
                      <span className="edit-item" onClick={() => edit(value)}>
                        Edit
                      </span>
                      <span className="delete-item" onClick={() => remove(value)}>
                        Delete
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Secrets;
