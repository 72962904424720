export const WORKFLOWS_API_URLs = {
  editWorkflow: "/api/WorkFlow", // PUT
  createWorkflow: "/api/WorkFlow", // POST
  createClaim: "/api/WorkFlow/role", // POST
  createLevels: "/api/UserLevel/Authorizers", // POST
  deleteRole: "/api/WorkFlow/role", // DELETE
  getAll: "/api/WorkFlow", // {code}
  getRoles: "/api/WorkFlow/role", //{code}
  getUsers: "/api/WorkFlow/users", //{code}
};

export const MAKER_CHECKER_API_URLs = {
  processRequest: "/api/MakerChecker", // POST
  approve: "/api/MakerChecker/approve", // pOST
  getMakerChecker: "/api/MakerChecker", //{workflowcode}/{transactionReference}/query
  reject: "/api/MakerChecker/reject", //POST
  getPendingTransactions: "/api/MakerChecker", //{workflowcode}/{email}
  getUserApproved: "/api/MakerChecker", //{workflowcode}/{transactionReference}/log
  getAllCallbacks: "/api/MakerChecker/callback",
};
