import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../components/dashboard/dashboard";
import Onboarding from "../components/onboarding/onboarding";
import Clients from "../components/clients/clients";
import Scopes from "../components/scopes/scopes";
import NewScopes from "../components/scopes/newScope/newScope";
import NewClient from "../components/clients/newClient/newClient";
import ViewScopes from "../components/scopes/viewScope/viewScope";
import ViewClients from "../components/clients/viewClient/viewClient";
import Applications from "../components/applications/applications";
import ViewApplications from "../components/applications/viewApplication/viewApplication";
import newApplication from "../components/applications/newApplication/newApplication";
import MakerChecker from "../components/makerChecker/makerChecker";
import Audit from "../components/audit/audit";
import NotFoundPage from "../components/notFoundPage/notFoundPage";
import ViewAuditInfo from "../components/audit/viewAuditInfo/viewAuditInfo";
import Profile from "../components/profile/profile";
import OpenIdConfiguration from "../components/openIdConfiguration";
import AccessLogs from "../components/access-logs/access-logs";
import ClientAuthorization from "../components/clientAuthorization/clientAuthorization";
import ViewAccessLogs from "../components/access-logs/viewAccessLogs/viewAccessLogs";
import { Urls } from "../utils/urls";
import { DefineWorkflow, DefineWorkflowSecondStep, Transactions, UserEditor, Users, UserViewer } from "../components";
import MakerCheckerApplications from "../components/makerChecker/makerCheckerApplications/makerCheckerApplications";
import { validateRole, ROLENAMES } from "../components/shared/roleClaim";

const { SSOADMIN, VGGSUPERADMIN } = ROLENAMES;
const RouteLists = () => {
  return (
    <Switch>
      <Route exact path={Urls.Dashboard} component={Dashboard} />
      {validateRole(VGGSUPERADMIN, SSOADMIN) && <Route exact path={Urls.Onboarding} component={Onboarding} />}
      <Route exact path={Urls.MakerChecker} component={MakerChecker} />
      <Route exact path={Urls.Users} component={Users} />
      <Route exact path={Urls.Scopes} component={Scopes} />
      <Route exact path={Urls.CreateScope} component={NewScopes} />
      <Route exact path={Urls.ViewScope} component={ViewScopes} />
      <Route exact path={Urls.EditScope} component={NewScopes} />
      <Route exact path={Urls.Clients} component={Clients} />
      <Route exact path={Urls.CreateClient} component={NewClient} />
      <Route exact path={Urls.ViewClient} component={ViewClients} />
      <Route exact path={Urls.EditClient} component={NewClient} />
      <Route exact path={Urls.CreateApplication} component={newApplication} />
      <Route exact path={Urls.Profile} component={Profile} />
      <Route exact path={Urls.Applications} component={Applications} />
      <Route exact path={Urls.ApplicationDetails} component={ViewApplications} />
      <Route exact path={Urls.Audit} component={Audit} />
      <Route exact path={Urls.ViewAuditInfo} component={ViewAuditInfo} />
      <Route exact path={Urls.AccessLogs} component={AccessLogs} />
      <Route exact path={Urls.ViewAccessLogs} component={ViewAccessLogs} />
      <Route exact path={Urls.Authorization} component={ClientAuthorization} />

      <Route exact path={Urls.DefineWorkflow} component={DefineWorkflow} />
      <Route exact path={Urls.EditWorkflow} component={DefineWorkflow} />
      <Route exact path={Urls.SecondStep} component={DefineWorkflowSecondStep} />
      <Route exact path={Urls.MakerCheckerApplications} component={MakerCheckerApplications} />
      <Route exact path={Urls.WorkflowTransactions} component={Transactions} />
      <Route exact path={Urls.UserView} component={UserViewer} />
      <Route exact path={Urls.NewUser} component={UserEditor} />
      <Route exact path="**" component={NotFoundPage} />
    </Switch>
  );
};
export default RouteLists;
