import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { createBrowserHistory as history } from "history";
import { useHistory } from "react-router-dom";

import "./App.css";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import { Route, Switch } from "react-router-dom";
import { AuthCallback, Layout, Login } from "./components";
import { PrivateRoute } from "./routes";
import OpenIdConfiguration from "./components/openIdConfiguration";
import { Urls } from "./utils/urls";
import clientIdentity from "./utils/user-manager";
import { isUserExist } from "./utils/userDetails";
import LoggingOut from "./components/loging-out/loggingOut";

const isAuth = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    return false;
  }
  const expires = user.expires_at;
  return new Date().getTime() / 1000 < expires;
};

const App = () => {
  const hstry = useHistory();
  const handleOnIdle = event => {
    if (getRemainingTime() === 0 && isUserExist()) {
      localStorage.removeItem("user");
      localStorage.removeItem("user_claim");
      localStorage.removeItem("user_profile");
      localStorage.removeItem("initialTime");
      hstry.push(Urls.Login);
      clientIdentity.signoutRedirect().then(res => {
        clientIdentity.removeUser();
      });
    }
  };

  const handleOnActive = event => {
    // console.log('user is active', event)
  };

  const handleOnAction = e => {
    // console.log('u ser did something', e)
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 3600000, // millisecond of idle time eg 120000 is 2 minutes
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 1000, // reduce by 1 seconds in milliseconds eg 1000 is 1 second
  });

  return (
    <ErrorBoundary history={history()}>
      <Switch>
        <Route exact path={Urls.OpenIdConfiguration} component={OpenIdConfiguration} />
        <Route exact path={Urls.LoggingOut} component={LoggingOut} />
        <Route path={Urls.Login} component={Login} />
        <Route path="/callback" component={AuthCallback} />
        <PrivateRoute path="/" component={Layout} isAuthenticated={isAuth} redirect={Urls.Login} />
      </Switch>
    </ErrorBoundary>
  );
};

export default App;
