import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import getRoleList from "../../../utils/roles";
import { Urls } from "../../../utils/urls";
import { Button } from "../../shared";
import BreadCrumb from "../../shared/breadCrumb/breadCrumb";

import "./userEditor.scss";
import { SaveUser, SetActionFor } from "../../../store/user/user-actions";
import OtpModal from "../../shared/otpModal/otpModal";
import { useTitle } from "../../../utils/useTitle";

const UserEditor = ({ match: { params } }) => {
  useTitle("Add User");
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const dispatch = useDispatch();
  const { processing, user, actionFor } = useSelector(state => state.user);
  const [roleOptions, setRoleOptions] = useState([]);
  const [form, setForm] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    UserName: "",
    Email: "",
    Password: "",
    PhoneNumber: "",
    ConfirmEmail: true,
    Claims: [],
  });

  const handleBackClicked = e => {
    e.preventDefault();
    history.push(Urls.Users);
  };

  const handleSubmitForm = e => {
    e.preventDefault();
    form.submit();
  };

  const handleValueChange = e => {
    return e.target.value.replace(/[^0-9]/g, "");
  };
  const submitForm = (event, value) => {
    // setModalShow(true);
    const val = roleOptions.find(obj => {
      return obj.Value === value.Claims;
    });
    const claimObj = roleOptions.find(obj => {
      return obj.Value === value.Claims;
    });
    delete claimObj.label;
    const result = { ...formData, ...value };
    const formattedResult = {
      ...result,
      Claims: [claimObj],
      ConfirmEmail: JSON.parse(result.ConfirmEmail),
    };
    setFormData(formattedResult);
    dispatch(SetActionFor("saveUser"));
    setModalShow(true);
  };

  const onValidateOtp = () => {
    if (actionFor === "saveUser") {
      dispatch(SaveUser({ formData, history }));
    }
  };

  useEffect(() => {
    setRoleOptions(getRoleList(loggedInUser.profile["ssoui.role"]));
  }, []);

  return (
    <section className="row user-editor-container">
      <OtpModal openModal={modalShow} closeModal={() => setModalShow(false)} onValidateOtp={onValidateOtp} />
      <div className="col">
        <BreadCrumb title="Users" titleLink={Urls.Users} sub1="New User" />
        <div className="content-header d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <a href="!#/" onClick={handleBackClicked} className="mr-2 goback">
              <i className="ri-arrow-left-line"></i>
            </a>{" "}
            <div className="sp-disp-heading">{user ? "Edit" : "Create"} User</div>
          </div>
          <div className="text-right">
            <Button size="sm" outline={true} color="green" handleClick={handleBackClicked} className="mr-sm-0 mr-md-2">
              Cancel
            </Button>
            <Button size="sm" loading={processing} color="green" handleClick={handleSubmitForm}>
              Submit
            </Button>
          </div>
        </div>
        <div className="card card-content">
          <div className="card-body">
            <AvForm onValidSubmit={submitForm} ref={c => setForm(c)}>
              <div className="row mb-3">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <label htmlFor="FirstName">First Name</label>
                  <AvField
                    className="form-control"
                    id="FirstName"
                    name="FirstName"
                    placeholder="Enter First Name"
                    autoComplete="off"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter First Name",
                      },
                      pattern: { value: "^[A-Za-z]+$", errorMessage: "This field does not accept digits" },
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="sp-pt--48">
                    <div className="input">
                      <label htmlFor="LastName">Last Name</label>
                      <AvField
                        className="form-control"
                        id="LastName"
                        name="LastName"
                        placeholder="Enter Last Name"
                        autoComplete="off"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Last Name",
                          },
                          pattern: { value: "^[A-Za-z]+$", errorMessage: "This field does not accept digits" },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="sp-pt--48">
                    <div className="input">
                      <label htmlFor="UserName">Username</label>
                      <AvField
                        className="form-control"
                        id="UserName"
                        name="UserName"
                        placeholder="Enter Username"
                        autoComplete="off"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Username",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="sp-pt--48">
                    <div className="input">
                      <label className="input__label" htmlFor="Email">
                        Email Address
                      </label>
                      <AvField
                        className="form-control"
                        type="email"
                        id="Email"
                        name="Email"
                        placeholder="Enter Email Address"
                        autoComplete="off"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Email Address",
                          },
                          email: {
                            value: true,
                            errorMessage: "Invalid Email Address",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="sp-pt--48">
                    <div className="input">
                      <label className="input__label" htmlFor="PhoneNumber">
                        Phone Number
                      </label>
                      <AvField
                        type="text"
                        className="form-control"
                        id="PhoneNumber"
                        name="PhoneNumber"
                        placeholder="Enter Phone Number"
                        autoComplete="off"
                        maxLength={11}
                        validate={{
                          minLength: { value: 11, errorMessage: "Phone must be 11 character long" },
                          pattern: { value: "^[0-9]+$", errorMessage: "Phone does not allow letters" },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="sp-pt--48">
                    <div className="input">
                      <label className="input__label" htmlFor="Password">
                        Password
                      </label>
                      <AvField
                        className="form-control"
                        id="Password"
                        type="password"
                        name="Password"
                        autoComplete="new-password"
                        placeholder="Enter Password"
                        readOnly={readOnly}
                        onClick={() => setReadOnly(false)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Password",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <AvRadioGroup className="form-group col-md-6 radio-group pl-0" name="ConfirmEmail" required value="true" errorMessage="Please select one">
                    <div className="radio-label">Confirm Email?</div>
                    <div className="radio-content d-flex">
                      <div className="form-check pl-0">
                        <AvRadio className="form-check-input" label="Yes" id="ConfirmEmail-yes" value="true" />
                      </div>
                      <div className="form-check">
                        <AvRadio className="form-check-input" label="No" id="ConfirmEmail-no" value="false" />
                      </div>
                    </div>
                  </AvRadioGroup>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="sp-pt--48">
                    <div className="input">
                      <label className="input__label" htmlFor="Claims">
                        Assign role to user
                      </label>
                      <AvField
                        type="select"
                        className="form-control"
                        name="Claims"
                        id="Claims"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please select one",
                          },
                        }}
                      >
                        <option></option>
                        {roleOptions.map(role => (
                          <option key={role.Value} value={role.Value}>
                            {role.label}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </div>
                </div>
              </div>
            </AvForm>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserEditor;
