import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDaily, fetchHourly, fetchHistory, fetchSummary } from '../../store/dashboard/dashboard-actions';
import Chart from './chart/chart';
import SummaryTable from './summaryTable/summaryTable';
import { useTitle } from '../../utils/useTitle';
import './dashboard.scss';

const Dashboard = () => {
  useTitle('Dashboard');
  const dispatch = useDispatch();
  const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const onSelectHour = e => {
    dispatch(fetchHourly(parseInt(e.target.value)));
  };

  const select = useSelector(state => {
    return {
      hourly: state.dashboard && state.dashboard.hourly,
      daily: state.dashboard && state.dashboard.daily,
      history: state.dashboard && state.dashboard.history,
      summary: state.dashboard && state.dashboard.summary,
      hourlyLoading: state.dashboard && state.dashboard.isLoadingHourly,
      dailyLoading: state.dashboard && state.dashboard.isLoadingDaily,
      historyLoading: state.dashboard && state.dashboard.isLoadingHistory,
      summaryLoading: state.dashboard && state.dashboard.isLoadingSummary,
    };
  });

  console.log({ summary: select.summary });
  useEffect(() => {
    dispatch(fetchHourly(1));
    dispatch(fetchDaily());
    dispatch(fetchHistory());
    dispatch(fetchSummary());
  }, []);

  return (
    <section className='row dashboard-container '>
      <div className='col'>
        <div className='content-header d-flex justify-content-between'>
          <span>Dashboard</span>
        </div>
        <div className='card card-content'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-sm-12 col-md-4'>
                {/* <div className="d-flex justify-content-between align-items-center"> */}

                {/* <div className="sp-inputs sp-pt--48"> */}
                <div className='d-flex justify-content-between align-items-center'>
                  <label for='label'>Filter by hour</label>
                  <br />
                  <select className='sp-select sp-pl--12 sp-pr--12 sp-pt--8 sp-pb--8 large' onChange={onSelectHour}>
                    {hours.map(val => {
                      return <option key={val}>{val}</option>;
                    })}
                  </select>
                  {/* <select
                            className="sp-select sp-pl--12 sp-pr--12 sp-pt--8 sp-pb--8 large"
                          >
                            <option label="Select">Select</option>
                          </select> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* <label>Filter by hour </label>
                              <select className="form-select" onChange={onSelectHour}>
                                  {hours.map(val => {
                                   return <option key={val}>{val}</option>
                                  })}
                              </select> */}
                </div>
                <Chart data={select.hourly && select.hourly.data} />
              </div>
              <div className='col-sm-12 col-md-4'>
                <div className='d-flex justify-content-center align-items-center chart-title'>
                  <label>Daily </label>
                </div>
                <Chart data={select.daily && select.daily.data} />
              </div>
              <div className='col-sm-12 col-md-4'>
                <div className='d-flex justify-content-center align-items-center chart-title'>
                  <label>History</label>
                </div>
                <Chart data={select.history && select.history.data} />
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-12'>
                <div>
                  <SummaryTable data={select.summary && select.summary.data && select.summary.data.Administrators} type='admin' isLoading={select.summaryLoading} />
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-12'>
                <SummaryTable data={select.summary && select.summary.data && select.summary.data.TopTenLoggedInUsers} type='user' isLoading={select.summaryLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
