// show Section
export const sectionSelect = (input) => {
    switch (input) {
        case 1:
            return "Account";

        case 2:
            return "Scope";

        case 3:
            return "Client";

        default:
            return " ";
    }
};