import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash.debounce';

import OtpModal from '../../shared/otpModal/otpModal';
import { passwordChange } from '../../../store/profile/profile-action';
import { Colors } from '../../../utils/colors';
import Button from "../../shared/button/Button";
import './passwordChangeForm.scss';

const PasswordChangeForm = ({ openModal, closeModal, userId }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [form, setForm] = useState({});
    const [otpShow, setOtpShow] = useState(false);
    const [payload, setPayload] = useState(null);
    const [passwordMessage, setPasswordMessage] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submitForm = (event, value) => {
        setPayload({...value, UserId:userId});
        console.log("change password")
        setOtpShow(true);
    }

    const select = useSelector((state) => {
        return {
            status: state.profile && state.profile.passwordUpdate && state.profile.passwordUpdate.status,
            isLoading: state.profile && state.profile.isChanging,
        }
    })

    const validate = _debounce((value, ctx, input, cb) => {
        const re = /^(?=.*\d)(?=.*[!@#$%^&.*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        cb(re.test(value))
      }, 100);

      
    useEffect(() => {
        if (select.status && select.status === 200) {
            form.reset && form.reset();
            closeModal();
           }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [select.status])

    useEffect(() => {
        openModal ? handleShow() : handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal])


    return (
        <>
            <OtpModal
                openModal={otpShow}
                closeModal={() => setOtpShow(false)}
                onValidateOtp={() => dispatch(passwordChange(payload))}
            />
            <Modal
                centered
                show={show}
                onHide={closeModal}
                className="password-change-modal-container">
                <Modal.Header closeButton>
                    Change Password 
                </Modal.Header>
                <Modal.Body>
                    <AvForm onValidSubmit={submitForm} ref={c => (setForm(c))}>
                        <div className="row">
                            <div className="form-group col-12">
                            <label htmlFor="CurrentPassword">Current Password</label>
                                <AvField
                                    type="password"
                                    className="form-control"
                                    name="CurrentPassword"
                                    id="CurrentPassword"
                                    placeholder="Current Password"
                                    validate={{
                                        required: { value: true, errorMessage: 'Enter a Current Password' },
                                    }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-12">
                            <label htmlFor="NewPassword">New Password</label>
                                <AvField
                                    type="password"
                                    className="form-control"
                                    name="NewPassword"
                                    id="NewPassword"
                                    placeholder="New Password"
                                    errorMessage='Password should contain character, number and special character'
                                    validate={{
                                        async: validate,
                                         required: { value: true, errorMessage: 'Enter a New Password' },
                                        //  pattern: {value: '^[A-Za-z0-9]+$', errorMessage: 'Your name must be composed with letter and numbers'},
                                    }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-12">
                            <label htmlFor="reenterPassword">Re-enter New Password</label>
                                <AvField
                                    type="password"
                                    className="form-control"
                                    name="confirmPassword"
                                    id="reenterPassword"
                                    placeholder="Re-enter New Password"
                                    validate={{
                                        required: { value: true, errorMessage: 'Enter a Current Password' },
                                        match: {
                                            value: 'NewPassword',
                                            errorMessage: 'Your new password and re-entered new password are not the same'
                                          }
                                    }} />
                            </div>
                        </div>
                     </AvForm>
                     <div className="d-flex justify-content-end pr-3">
                      <Button
                            size="sm"
                            outline={true}
                            color={Colors.GREEN}
                            handleClick={() => {closeModal()}}
                            className="mr-2"
                        >
                           Cancel
                         </Button>
                        <Button size="sm" color={Colors.GREEN} handleClick={() => form.submit()} loading={select.isLoading}  disabled={select.isLoading}>
                            Create
                        </Button>
                        </div>
                </Modal.Body>
            
            </Modal>
        </>
    );
}

export default PasswordChangeForm;