const ToolTips = {
  clients: {
    Enabled: "Specifies if client is enabled",
    ClientId: "Unique ID of the client",
    ClientSecrets: "List of client secrets",
    ClientName: "Client display name",
    ClientUri: "URI to further information about client",
    LogoUri: "URI to client logo",
    RequireConsent: "Specifies whether a consent screen is required",
    AllowRememberConsent: "Specifies whether user can choose to store consent decisions",
    Flow: "Specifies allowed flow for client",
    AllowClientCredentialsOnly: "Allows client to request token using client credentials only",
    RedirectUris: "Specifies the allowed URIs to return tokens or authorization codes to",
    PostLogoutRedirectUris: "Specifies allowed URIs to redirect to after logout",
    LogoutUri: "Specifies logout URI at client for HTTP based logout",
    LogoutSessionRequired: "Specifies if the user’s session id should be sent to the LogoutUri",
    RequireSignOutPrompt: "Specifies if the client will always show a confirmation page for sign-out",
    AllowedScopes: "Specifies the scopes explicitly",
    AllowAccessTokensViaBrowser: "Specifies whether this client is allowed to request access tokens via the browser",
    AllowedCustomGrantTypes: "Explicitly specifies the grant types",
    IdentityTokenLifetime: "Lifetime of identity token / user session",
    AccessTokenLifetime: "Lifetime of access token (for api access)",
    AuthorizationCodeLifetime: "Lifetime of authorization code",
    AbsoluteRefreshTokenLifetime: "Maximum lifetime of a refresh token",
    SlidingRefreshTokenLifetime: "Sliding lifetime of a refresh token",
    AllowAccessWithUnknownIP: "Allow access from unknown IP address",
    RefreshTokenUsage: {
      Reuse: "the refresh token handle will stay the same when refreshing tokens",
      OneTime: "the refresh token handle will be updated when refreshing tokens",
    },
    RefreshTokenExpiration: {
      Absolute: "the refresh token will expire on a fixed point in time",
      Sliding: "when refreshing the token, the lifetime of the refresh token will be renewed",
    },
    UpdateAccessTokenClaimsOnRefresh: "indicating whether the access token should be updated on a refresh token request",
    AccessTokenType: "Specifies the access token type",
    EnableLocalLogin: "Specifies if this client can use local accounts, or external IdPs only",
    IdentityProviderRestrictions: "Specifies which external IdPs can be used with this client",
    IncludeJwtId: "Specifies whether JWT access tokens should have an embedded unique ID",

    AllowedCorsOrigins: "If specified, will be used by the default CORS policy service implementations",
    Claims: "Allows settings claims for the client",
    AlwaysSendClientClaims: "If set, the client claims will be sent for every flow",
    PrefixClientClaims: "If set, all client claims will be prefixed with client_",

    AllowAllGrantTypes: "allow access to all grant types",
    AllowAllScopes: "allow access to all scopes",
    ClientTokenExpiration: "Absolute (fixed) or Sliding (renewed)",
    ClientTokenUsage: "Reuse (same token) or OneTime (update token)",
  },
  scopes: {
    Enabled: "Indicates if scope is enabled and can be requested",
    Name: "Name of the scope",
    DisplayName: "Display name for consent screen",
    Description: "Description for the consent screen",
    Required: "Specifies whether the user can de-select the scope on the consent screen",
    ScopeSecrets: "Adds secret to scope ",
    AllowUnrestrictedIntrospection: "Allows this scope to see all other scopes in the access token when using the introspection endpoint",
    Emphasize: "Specifies whether the consent screen will emphasize this scope",
    Type: "Either Identity or Resource",
    Claims: "Adds claims to scope",
    IncludeAllClaimsForUser: "If enabled, all claims for the user will be included in the token",
    ClaimsRule: "Rule for determining which claims should be included in the token",
    ShowInDiscoveryDocument: "Specifies whether this scope is shown in the discovery document",
    scopeClaim: {
      Name: "Name of the claim",
      Description: "Description of the claim",
      AlwaysIncludeInIdToken: "Specifies whether this claim should always be present in the identity token",
    },
    scopeSecret: {
      Value: "Value of the secret",
      Type: "Specifies type of secret",
      Description: "Description of the secret",
      Expiration: "Specifies expiration date of the secret",
    },
  },
};

export default ToolTips;
