import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";

import "./singleClientForm.scss";

const ClientForm = ({ title, fieldTitle, label, singleResult, value }) => {
  const [clientForm, setClientForm] = useState([]);
  const [form, setForm] = useState([]);
  const [myValue, setMyValue] = useState({});

  const submitForm = (event, values) => {
    const result = [...clientForm, values];
    setClientForm(result);
    singleResult(title, result);
    setMyValue({});
    form.reset();
  };

  const remove = (value) => {
    const index = clientForm.indexOf(value);
    clientForm.splice(index, 1);
    setClientForm([...clientForm]);
  };

  const edit = (value) => {
    setTimeout(() => form.reset(), 30);
    setMyValue(value);
    remove(value);
  };

  useEffect(() => {
    value && setClientForm(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AvForm className="row" onValidSubmit={submitForm} ref={(c) => setForm(c)} model={myValue}>
        <div className="form-group col">
          <label htmlFor="name">{label}</label>
          <AvField
            type="text"
            className="form-control"
            name={fieldTitle}
            id="name"
            placeholder={`Enter ${title}`}
            validate={{
              required: { value: true, errorMessage: `Please enter ${title}` },
            }}
          />
        </div>
        <div className="col-auto d-flex align-items-center">
          <button type="submit" className="btn btn-sm btn-success">
            Add
          </button>
        </div>
      </AvForm>
      {clientForm && clientForm.length > 0 && (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">{label}</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {clientForm.map((value, i) => {
                return (
                  <tr key={i}>
                    <td>{value[fieldTitle]}</td>
                    <td>
                      <span className="edit-item" onClick={() => edit(value)}>
                        Edit
                      </span>
                      <span className="delete-item" onClick={() => remove(value)}>
                        Delete
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ClientForm;
