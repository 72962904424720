import React from "react";
import { useHistory } from "react-router-dom";

import Button from "../shared/button/Button";
import { Urls } from "../../utils/urls";
import { Colors } from "../../utils/colors";
import NotFoundImage from "../../assets/images/404-icon.svg";

import "./notFoundPage.scss";

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <div className="row">
      <div className="col not-found-content">
        <div>
          <img src={NotFoundImage} alt="notFound" />
        </div>
        <div className="page-not-found">Page not Found</div>
        <div className="description">The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</div>
        <div className="redirect-btn">
          <Button handleClick={() => history.push(Urls.Dashboard)} size="sm" color={Colors.GREEN}>
            Return to Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
