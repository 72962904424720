import React from "react";

export default {
  onboarding: (menu) => {
    return (
      <span className="icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 12.6666H14V14H2V12.6666ZM8.66667 6.66665V12H7.33333V6.66665H2.66667L8 1.33331L13.3333 6.66665H8.66667Z" fill={menu === "onboarding" ? "#1E944D" : "#677684"} />
        </svg>
      </span>
    );
  },
  makerChecker: (menu) => {
    return (
      <span className="icon">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 0.666687L13.478 1.88402C13.7827 1.95202 14 2.22202 14 2.53469V9.19269C14 10.53 13.3313 11.7794 12.2187 12.5207L8 15.3334L3.78133 12.5207C2.668 11.7787 2 10.53 2 9.19335V2.53469C2 2.22202 2.21733 1.95202 2.522 1.88402L8 0.666687ZM10.968 5.48135L7.668 8.78069L5.78267 6.89535L4.84 7.83802L7.66867 10.6667L11.9113 6.42402L10.968 5.48135Z"
            fill={menu === "maker-checker" ? "#1E944D" : "#677684"}
          />
        </svg>
      </span>
    );
  },
  dashboard: (menu) => {
    return (
      <span className="icon">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 13.3334C13.3333 13.5102 13.2631 13.6797 13.1381 13.8048C13.013 13.9298 12.8435 14 12.6667 14H3.33333C3.15652 14 2.98695 13.9298 2.86193 13.8048C2.7369 13.6797 2.66666 13.5102 2.66666 13.3334V7.33336H0.666664L7.55133 1.07469C7.67407 0.963013 7.83405 0.901123 8 0.901123C8.16594 0.901123 8.32592 0.963013 8.44866 1.07469L15.3333 7.33336H13.3333V13.3334ZM7.33333 8.66669V12.6667H8.66666V8.66669H7.33333Z"
            fill={menu === "" ? "#1E944D" : "#677684"}
          />
        </svg>
      </span>
    );
  },
  applications: (menu) => {
    return (
      <span className="icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.5 1.66669C4.87208 1.66669 5.24051 1.73997 5.58427 1.88236C5.92802 2.02475 6.24037 2.23345 6.50347 2.49655C6.76657 2.75965 6.97527 3.07199 7.11766 3.41575C7.26005 3.75951 7.33333 4.12794 7.33333 4.50002V7.33335H4.5C3.74855 7.33335 3.02788 7.03484 2.49653 6.50349C1.96518 5.97214 1.66667 5.25147 1.66667 4.50002C1.66667 3.74857 1.96518 3.0279 2.49653 2.49655C3.02788 1.9652 3.74855 1.66669 4.5 1.66669ZM4.5 8.66669H7.33333V11.5C7.33333 12.0604 7.16716 12.6082 6.85583 13.0741C6.5445 13.5401 6.10199 13.9032 5.58427 14.1177C5.06654 14.3321 4.49686 14.3882 3.94724 14.2789C3.39763 14.1696 2.89278 13.8997 2.49653 13.5035C2.10028 13.1072 1.83043 12.6024 1.72111 12.0528C1.61178 11.5032 1.66789 10.9335 1.88234 10.4158C2.09679 9.89803 2.45994 9.45552 2.92588 9.14419C3.39182 8.83286 3.93962 8.66669 4.5 8.66669ZM11.5 1.66669C12.2514 1.66669 12.9721 1.9652 13.5035 2.49655C14.0348 3.0279 14.3333 3.74857 14.3333 4.50002C14.3333 5.25147 14.0348 5.97214 13.5035 6.50349C12.9721 7.03484 12.2514 7.33335 11.5 7.33335H8.66667V4.50002C8.66667 3.74857 8.96518 3.0279 9.49653 2.49655C10.0279 1.9652 10.7486 1.66669 11.5 1.66669ZM8.66667 8.66669H11.5C12.0604 8.66669 12.6082 8.83286 13.0741 9.14419C13.5401 9.45552 13.9032 9.89803 14.1177 10.4158C14.3321 10.9335 14.3882 11.5032 14.2789 12.0528C14.1696 12.6024 13.8997 13.1072 13.5035 13.5035C13.1072 13.8997 12.6024 14.1696 12.0528 14.2789C11.5031 14.3882 10.9335 14.3321 10.4157 14.1177C9.898 13.9032 9.4555 13.5401 9.14417 13.0741C8.83284 12.6082 8.66667 12.0604 8.66667 11.5V8.66669Z"
            fill={menu === "applications" ? "#1E944D" : "#677684"}
          />
        </svg>
      </span>
    );
  },
  users: (menu) => {
    return (
      <span className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 11a5 5 0 0 1 5 5v6H7v-6a5 5 0 0 1 5-5zm-6.712 3.006a6.983 6.983 0 0 0-.28 1.65L5 16v6H2v-4.5a3.5 3.5 0 0 1 3.119-3.48l.17-.014zm13.424 0A3.501 3.501 0 0 1 22 17.5V22h-3v-6c0-.693-.1-1.362-.288-1.994zM5.5 8a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM12 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8z"
            fill={menu === "users" ? "#1E944D" : "#677684"}
          />
        </svg>
      </span>
    );
  },
  clients: (menu) => {
    return (
      <span className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M10 19.748V16.4c0-1.283.995-2.292 2.467-2.868A8.482 8.482 0 0 0 9.5 13c-1.89 0-3.636.617-5.047 1.66A8.017 8.017 0 0 0 10 19.748zm8.88-3.662C18.485 15.553 17.17 15 15.5 15c-2.006 0-3.5.797-3.5 1.4V20a7.996 7.996 0 0 0 6.88-3.914zM9.55 11.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5zm5.95 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"
            fill={menu === "clients" ? "#1E944D" : "#677684"}
          />
        </svg>
      </span>
    );
  },
  scopes: (menu) => {
    return (
      <span className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M17 7a8.003 8.003 0 0 0-7.493 5.19l1.874.703A6.002 6.002 0 0 1 23 15a6 6 0 0 1-6 6H7A6 6 0 0 1 5.008 9.339a7 7 0 0 1 13.757-2.143A8.027 8.027 0 0 0 17 7z"
            fill={menu === "scopes" ? "#1E944D" : "#677684"}
          />
        </svg>
      </span>
    );
  },
  audit: (menu) => {
    return (
      <span className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 14">
          <path
            d="M12 4.99992V12.9953C12.0006 13.0828 11.984 13.1696 11.951 13.2507C11.9181 13.3319 11.8695 13.4057 11.808 13.468C11.7466 13.5304 11.6734 13.58 11.5928 13.6141C11.5121 13.6481 11.4255 13.666 11.338 13.6666H0.662C0.486542 13.6666 0.318261 13.5969 0.194131 13.4729C0.070001 13.3489 0.000176695 13.1807 0 13.0053V0.994585C0 0.636585 0.298 0.333252 0.665333 0.333252H7.33333V4.33325C7.33333 4.51006 7.40357 4.67963 7.5286 4.80466C7.65362 4.92968 7.82319 4.99992 8 4.99992H12ZM12 3.66659H8.66667V0.335252L12 3.66659ZM3.33333 3.66659V4.99992H5.33333V3.66659H3.33333ZM3.33333 6.33325V7.66659H8.66667V6.33325H3.33333ZM3.33333 8.99992V10.3333H8.66667V8.99992H3.33333Z"
            fill={menu === "audit" ? "#1E944D" : "#677684"}
          />
        </svg>
      </span>
    );
  },
  accessLog: (menu) => {
    return (
      <span className="icon">
        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V11H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM3 13H21V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V13ZM7 16V18H10V16H7ZM7 6V8H10V6H7Z"
            fill={menu === "access-logs" ? "#1E944D" : "#677684"}
          />
        </svg>
      </span>
    );
  },
};
