import { all, call, put, takeEvery } from "redux-saga/effects";
import { ssoApi } from "../../axios/interceptor";

import {
  fetchBusinessUnitSuccess,
  fetchApplicationListSuccess,
  createApplicationSuccess,
  createBusinessUnitSuccess,
  createMapApplicationSuccess,
  ApplicationsActionTypes,
  fetchApplicationsSuccess,
  fetchApplicationReportSuccess,
  fetchApplicationUserSuccess,
  fetchApplicationUserAccountSuccess,
  fetchApplicationUserClearanceSuccess
} from "./applications-actions";

export function* FetchBusinessUnitAsync() {
  try {
    const response = yield call(
      ssoApi.get,
      "/sbulist"
    );
    yield put(fetchBusinessUnitSuccess(response));
  } catch (err) {
  }
}

export function* FetchApplicationListAsync() {
  try {
    const response = yield call(
      ssoApi.get,
      "/applicationlist"
    );
    yield put(fetchApplicationListSuccess(response));
  } catch (err) {
  }
}

export function* CreateBusnissUnitAsync(action) {
  try {
    const response = yield call(
      ssoApi.post,
      "/createsbu",
      action.payload
    );
    yield put(createBusinessUnitSuccess(response));
  } catch (err) {
  }
}

export function* CreateApplicationAsync(action) {
  try {
    const response = yield call(
      ssoApi.post,
      "/createapplication",
      action.payload
    );
    yield put(createApplicationSuccess(response));
  } catch (err) {
  }
}

export function* CreateMapApplicationAsync(action) {
  try {
    const response = yield call(
      ssoApi.post,
      "/applicationclient",
      action.payload
    );
    yield put(createMapApplicationSuccess(response));
  } catch (err) {
  }
}

export function* FetchApplicationsAsync() {
  try {
    const response = yield call(
      ssoApi.get,
      "/sbuapplications"
    );
    yield put(fetchApplicationsSuccess(response));
  } catch (err) {
  }
}

export function* FetchApplicationUserAsync(action) {
  try {
    const response = yield call(
      ssoApi.get,
      `/loginrecord/applicationUsers/${action.payload}`
    );
    yield put(fetchApplicationUserSuccess(response));
  } catch (err) {
  }
}

export function* FetchApplicationReportAsync(action) {
  try {
    const response = yield call(
      ssoApi.get,
      `/loginrecord/applicationReport/${action.payload}`
    );
    yield put(fetchApplicationReportSuccess(response));
  } catch (err) {
  }
}

export function* FetchApplicationUserAccountAsync(action) {
  try {
    const response = yield call(
      ssoApi.get,
      `/account/getuser?userId=${action.payload}`
    );
    yield put(fetchApplicationUserAccountSuccess(response));
  } catch (err) {
  }
}

export function* FetchApplicationUserClearanceAsync(action) {
  try {
    const response = yield call(
      ssoApi.get,
      `/account/clearance?userId=${action.payload}`
    );
    yield put(fetchApplicationUserClearanceSuccess(response));
  } catch (err) {
  }
}

export function* WatchFetchApplicationsflow() {
  yield takeEvery(
    ApplicationsActionTypes.FETCHING_DATA,
    FetchApplicationsAsync
  );
}

export function* WatchFetchApplicationUserflow() {
  yield takeEvery(
    ApplicationsActionTypes.FETCH_APPLICATION_USER,
    FetchApplicationUserAsync
  );
}

export function* WatchFetchApplicationReportflow() {
  yield takeEvery(
    ApplicationsActionTypes.FETCH_APPLICATION_REPORT,
    FetchApplicationReportAsync
  );
}

export function* WatchFetchApplicationUserAccountflow() {
  yield takeEvery(
    ApplicationsActionTypes.FETCH_APPLICATION_USERS_ACCOUNT,
    FetchApplicationUserAccountAsync
  );
}

export function* WatchFetchApplicationUserClearanceflow() {
  yield takeEvery(
    ApplicationsActionTypes.FETCH_APPLICATION_USER_CLEARANCE,
    FetchApplicationUserClearanceAsync
  );
}


export function* WatchCreateBusinessUnitflow() {
  yield takeEvery(
    ApplicationsActionTypes.CREATE_BUSINESS_UNIT,
    CreateBusnissUnitAsync
  );
}


export function* WatchApplicationflow() {
  yield takeEvery(
    ApplicationsActionTypes.CREATE_APPLICATION,
    CreateApplicationAsync
  );
}


export function* WatchMapApplicationflow() {
  yield takeEvery(
    ApplicationsActionTypes.CREATE_MAP_APPLICATION,
    CreateMapApplicationAsync
  );
}

export function* WatchBusinessUnitflow() {
  yield takeEvery(
    ApplicationsActionTypes.FETCHING_BUSINESS_UNIT,
    FetchBusinessUnitAsync
  );
}

export function* WatchApplicationListflow() {
  yield takeEvery(
    ApplicationsActionTypes.FETCHING_APPLICATION_LIST,
    FetchApplicationListAsync
  );
}


export default function* applicationSagas() {
  yield all([call(WatchFetchApplicationsflow),
  call(WatchFetchApplicationUserflow),
  call(WatchFetchApplicationReportflow),
  call(WatchFetchApplicationUserAccountflow),
  call(WatchFetchApplicationUserClearanceflow),
  call(WatchCreateBusinessUnitflow),
  call(WatchApplicationflow),
  call(WatchMapApplicationflow),
  call(WatchBusinessUnitflow),
  call(WatchApplicationListflow),
  ]);
}
