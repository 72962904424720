import React from 'react';

import './scopeClaims.scss';

const ScopeClaim = ({ details }) => {
    const { ScopeClaims } = details;
    return (
        <div className='scope-claims-container'>
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Always Include In ID Token</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ScopeClaims && ScopeClaims.map((value, i) => {
                            return (
                                <tr key={value.Id}>
                                    <td>{value.Name}</td>
                                    <td>{value.Description}</td>
                                    <td>{value.AlwaysIncludeInIdToken.toString()}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ScopeClaim;