import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import _debounce from 'lodash.debounce';
import { Select } from 'antd';
import './makerCheckerApplications.scss';
import { Button, Paginator, ShimmerTableRowLoader } from '../../shared';
import { FetchApplicationList, FetchClientData, FetchTransactionDataSuccess } from '../../../store/maker-checker/maker-checker-actions';
import AddApplicationModal from './addApplicationModal/addApplicationModal';
import { useTitle } from '../../../utils/useTitle';

const MakerCheckerApplications = () => {
  useTitle('Maker Checker Application');
  const { Option } = Select;
  const dispatch = useDispatch();
  const { applicationsList, clientData, fetchingClient, processing, allClients, fetchingApplications } = useSelector(state => state.makerChecker);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [showAddApplicationModal, setShowAddApplicationModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [transactionReference, setTransactionReference] = useState('');

  const handlePageClick = data => {
    let selected = data.selected;
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    dispatch(FetchClientData({ type: 'all', pageSize: 10 }));
    dispatch(FetchApplicationList());
    FetchTransactionDataSuccess([]);
  }, []);

  const handleApprove = (e, record) => {
    e.preventDefault();
    setActionType('approve');
    setTransactionReference(record);
  };

  const handleReject = (e, record) => {
    e.preventDefault();
    setActionType('reject');
    setTransactionReference(record);
  };

  const handleItemsPerPage = value => {
    setPerPage(value);
  };

  const handleAddApplication = client => {
    setShowAddApplicationModal(true);
  };

  const handleCloseModal = () => {
    setActionType(null);
    setShowAddApplicationModal(false);
  };

  const search = rows => {
    const columns = ['name', 'code', 'description'];
    if (!selectedRow) {
      return rows.filter(row => columns.some(column => row[column].toString().toLowerCase().indexOf(searchTerm) > -1));
    }
    return rows.filter(row => row[`${selectedRow}`].toString().toLowerCase().indexOf(searchTerm) > -1);
  };

  const lastItemIndex = currentPage * perPage;
  const firstItemIndex = lastItemIndex - perPage;
  const currentItems = allClients?.length ? allClients.slice(firstItemIndex, lastItemIndex) : [];
  return (
    <section className='row transaction-container'>
      <AddApplicationModal openModal={showAddApplicationModal} closeModal={handleCloseModal} applicationsList={applicationsList} processing={processing} fetchingApplications={fetchingApplications} />
      <div className='col'>
        <div className='d-flex flex-column justify-content-center align-items-end  flex-lg-row justify-content-lg-between align-items-lg-center'>
          <div className='sp-disp-heading align-self-start mb-3 mb-lg-0 font-weight-bold'>Applications</div>
          <div className='d-flex flex-column-reverse flex-lg-row justify-content-end align-items-center'>
            <div className='d-flex flex-column flex-lg-row justify-content-end align-items-center'></div>
            <div className='justify-self-end align-self-end align-self-lg-center justify-self-lg-center mt-2 mt-lg-0 mb-2 mb-lg-0'>
              <Button size='sm' color='green' handleClick={handleAddApplication}>
                Add
              </Button>
            </div>
          </div>
        </div>
        <div className='card card-content'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='col-12 d-flex align-items-end justify-content-between mb-3 mb-lg-0 px-0'>
                <div className='sp-section-title'>Applications</div>
                <div className='entry-number d-flex flex-column flex-lg-row align-items-start justify-content-center align-items-lg-center justify-content-lg-end'>
                  <span className='mr-2 showing'>showing:</span>
                  <div className='' style={{ width: 142 }}>
                    <Select showSearch style={{ width: '100%' }} placeholder='Select a Client' optionFilterProp='children' onChange={handleItemsPerPage} value={perPage}>
                      <Option value={5}>5 Entries</Option>
                      <Option value={10}>10 Entries</Option>
                      <Option value={15}>15 Entries</Option>
                      <Option value={20}>20 Entries</Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className='row table-container'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Description</th>
                  </tr>
                </thead>
                {fetchingClient ? (
                  <ShimmerTableRowLoader rows={perPage} columnWidths={['30%', '15%', '55%']} />
                ) : (
                  <tbody>
                    {search(currentItems).length ? (
                      search(currentItems).map((value, i) => {
                        return (
                          <tr key={i}>
                            <td width='30%'>{value.name}</td>
                            <td width='15%'>{value.code}</td>
                            <td width='55%'>{value.description}</td>
                            {/* <td width='30%'>
                              <a href='#/' className='action mr-3' onClick={e => handleApprove(e, value.transactionReference)}>
                                Approve Transaction
                              </a>
                              <a href='#/' className='action d-block d-lg-inline text-danger' onClick={e => handleReject(e, value.transactionReference)}>
                                Reject Transaction
                              </a>
                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan='3' className='font-weight-bold text-center pt-4'>
                          Not data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </Table>
            </div>
            <Paginator currentPage={currentPage} perPage={perPage} pageCount={Math.ceil(allClients?.length / perPage)} totalItems={allClients?.length} handlePageClick={handlePageClick} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MakerCheckerApplications;
