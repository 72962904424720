export const AccessLogsActionTypes = {
    FETCHING_DATA: "FETCHING_ACCESS_LOG",
    FETCHING_ACCESS_LOG_SUCCESS: "FETCHING_ACCESS_LOG_SUCCESS",
    FETCHING_ACCESS_LOG_ERROR: "FETCHING_ACCESS_LOG_ERROR",
    ACCESS_LOG_DETAILS: "ACCESS_LOG_DETAILS",
}

export const fetchAccessLogs = (payload) => ({
    type: AccessLogsActionTypes.FETCHING_DATA,
    payload: payload,
});

export const fetchAccessLogsSuccess = (payload) => ({
    type: AccessLogsActionTypes.FETCHING_ACCESS_LOG_SUCCESS,
    payload: payload,
});

export const accessLogDetails = (payload) => ({
    type: AccessLogsActionTypes.ACCESS_LOG_DETAILS,
    payload: payload,
});
