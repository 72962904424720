import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as moment from "moment";
import { EnableClient, FetchClientById, FetchUserClearance, FetchUserDetails, LockUser, SetActionFor, SetUserTabKey } from "../../../store/user/user-actions";
import { Urls } from "../../../utils/urls";
import { Button } from "../../shared";
import BreadCrumb from "../../shared/breadCrumb/breadCrumb";
import OtpModal from "../../shared/otpModal/otpModal";
import ClaimEditorModal from "./userClaims/claimEditorModal/claimEditorModal";
import ClaimRemoveModal from "./userClaims/claimRemoveModal/claimRemoveModal";
import UserClaims from "./userClaims/userClaims";
import ClearanceEditorModal from "./userClearance/clearanceEditorModal/clearanceEditorModal";
import UserClearance from "./userClearance/userClearance";
import UserGroups from "./userGroups/userGroups";
import UserInfo from "./userInfo/userInfo";
import "./userViewer.scss";
import { useTitle } from "../../../utils/useTitle";
import { PageTitle } from "../../shared/titlePage/titlePage";

const UserViewer = ({ match: { params } }) => {
  useTitle("User Details");
  const dispatch = useDispatch();
  const {
    userDetails,
    userDetails: { Claims },
    clearance,
    vggSuperAdminClaims,
    vggAdminClaims,
    vggUserClaims,
    clientAdminClaims,
    clientUserClaims,
    processing,
    actionFor,
    userTabKey,
  } = useSelector(state => state.user);
  const [clearanceModalShow, setClearanceModalShow] = useState(false);
  const [claimModalShow, setClaimModalShow] = useState(false);
  const [showRemoveClaimsModal, setShowRemoveClaimsModal] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [modalClaims, setModalClaims] = useState([]);
  const [key, setKey] = useState("Details");
  const [clientEnable, setClientEnable] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [itemId, setItemId] = useState(null);
  const history = useHistory();

  const handleAddClearance = () => {
    setClearanceModalShow(true);
  };

  const handleAddClaim = () => {
    setClaimModalShow(true);
  };

  const handleRemoveClaim = () => {
    setShowRemoveClaimsModal(true);
  };

  const handleUnlockClick = () => {
    dispatch(SetActionFor("unlockUser"));
    setShowOtp(true);
  };
  const handleBackClicked = e => {
    e.preventDefault();
    history.push(Urls.Users);
  };

  const handleClearanceEnable = (e, item) => {
    console.log(item);
    setClientEnable(item);
    dispatch(SetActionFor("enableClearance"));
    setShowOtp(true);
  };

  const onValidateClientEnablekOtp = () => {
    console.log(clientEnable);
    setFormValues({
      Id: clientEnable.ClearanceId,
      Userid: params.id,
      ClientKey: clientEnable.ClientKey,
    });
  };

  const onValidateOtp = () => {
    if (actionFor === "unlockUser") {
      dispatch(LockUser({ locked: setLockStatus, UserId: params.id }));
    } else if (actionFor === "enableClearance") {
      onValidateClientEnablekOtp();
    }
  };

  useEffect(() => {
    if (formValues) {
      setItemId(clientEnable.ClearanceId);
      dispatch(EnableClient({ formValues, isEnabled: clientEnable.IsEnabled }));
      setClientEnable(null);
      setFormValues(null);
    }

    return () => {
      setFormValues(null);
    };
  }, [formValues]);

  useEffect(() => {
    dispatch(FetchUserClearance(params.id));
    dispatch(FetchUserDetails(params.id));
    setLockStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Claims && Claims.length) {
      const roleClaims = filterClaims(/role/);
      const applicationClaims = filterClaims(/application/);
      setModalClaims([...roleClaims, ...applicationClaims]);
      filterUserTypeClaims("vgg_superadmin", roleClaims);
      filterUserTypeClaims("vgg_admin", roleClaims);
      filterUserTypeClaims("vgg_user", roleClaims);
      filterUserTypeClaims("clientadmin", roleClaims);
      filterUserTypeClaims("clientuser", roleClaims);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Claims]);

  useEffect(() => {
    if (clientEnable) {
      setShowOtp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientEnable]);

  const filterClaims = pattern => {
    var filtered = [];

    for (let i = 0; i < Claims.length; i++) {
      if (Claims[i].Type.toLowerCase().match(pattern)) {
        filtered.push(Claims[i]);
      }
    }

    return filtered;
  };

  // function to filter claims
  const filterUserTypeClaims = (userType, data) => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].Value.indexOf(userType) !== -1) {
        var roleIndex = data[i].Type.toLowerCase().lastIndexOf(".role");
        var filteredItem = data[i].Type.substring(0, roleIndex);
        dispatch(FetchClientById({ filteredItem, userType }));
      }
    }
  };

  const setLockStatus = () => {
    let isLocked = false;
    if (userDetails) {
      const today = moment().format();
      const lockoutDate = moment(userDetails.LockoutEndDateUtc).format();
      if (userDetails.LockoutEndDateUtc != null && lockoutDate > today) {
        isLocked = true;
      } else {
        isLocked = false;
      }
    }
    return isLocked;
  };

  if (!userDetails || !Claims) {
    history.push(Urls.Users);
    return null;
  }

  return (
    <section className="row user-details-container">
      <OtpModal openModal={showOtp} closeModal={() => setShowOtp(false)} onValidateOtp={onValidateOtp} />
      <ClearanceEditorModal openModal={clearanceModalShow} closeModal={() => setClearanceModalShow(false)} Userid={params.id} userClearance={clearance} />
      <ClaimEditorModal openModal={claimModalShow} closeModal={() => setClaimModalShow(false)} UserId={params.id} />
      <ClaimRemoveModal openModal={showRemoveClaimsModal} closeModal={() => setShowRemoveClaimsModal(false)} claimList={modalClaims} UserId={params.id} />
      <div className="col">
        <BreadCrumb title="Users" titleLink={Urls.Users} sub1="User Details" />
        <div className="content-header d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex justify-content-center align-items-center">
            {/* <a href="!#/" onClick={handleBackClicked} className="mr-2 goback">
              <i className="ri-arrow-left-line"></i>
            </a>{" "}
            <div className="sp-disp-heading font-weight-bold">User Information</div> */}
            <PageTitle url={Urls.Users} title="User Information" showArrow={true} />
          </div>
          <div>
            <Button size="sm" color="green" handleClick={handleAddClearance}>
              Add Clearance
            </Button>
            <Button size="sm" color="green" className="ml-2" handleClick={handleAddClaim}>
              Add Claim
            </Button>
            <Button size="sm" outline={true} color="green" className="ml-2" handleClick={handleUnlockClick}>
              {setLockStatus() ? "Unlock User" : "Lock User"}
            </Button>
            <Button size="sm" color="destructive" className="ml-2" handleClick={handleRemoveClaim}>
              Remove Claim
            </Button>
          </div>
        </div>
        <div className="card card-content mb-3">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col">
                <Tabs id="controlled-tab-example" activeKey={userTabKey} onSelect={k => dispatch(SetUserTabKey(k))}>
                  <Tab eventKey="Details" title="Details">
                    <UserInfo data={userDetails} />
                  </Tab>
                  <Tab eventKey="Claims" title="Claims">
                    <UserClaims data={Claims} />
                  </Tab>
                  <Tab eventKey="Clearance" title="Clearance">
                    <UserClearance clearance={clearance} handleEnable={handleClearanceEnable} processing={processing} itemId={itemId} />
                  </Tab>
                  <Tab eventKey="Groups" title="Groups">
                    <UserGroups
                      data={{
                        vggSuperAdminClaims,
                        vggAdminClaims,
                        vggUserClaims,
                        clientAdminClaims,
                        clientUserClaims,
                      }}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserViewer;
