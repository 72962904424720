import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
 

const Chart =({data, name})=> {
    const payload = data && data.map(val=>{
        return {name:val.key, y: val.value}
    })
   
  return (
      <>
       <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text:name
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Request Rate',
                colorByPoint: true,
                data: payload
            }]
        }}
        />
      </>
  )
}

export default Chart;