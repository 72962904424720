import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchAudit, auditDetails } from '../../store/audit/audit-actions';
import { sectionSelect } from '../shared/auditHelper/auditHelper';
import Datepicker from '../shared/datepicker/datepicker';
import moment from 'moment';
import { ViewAuditInfoPage } from '../../utils/urls';
import { Button, Paginator, ShimmerTableRowLoader } from '../shared';
import { Table } from 'reactstrap';
import { useTitle } from '../../utils/useTitle';

import './audit.scss';

const Audit = () => {
  useTitle('Audit Logs');
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRow, setSelectedRow] = useState('');

  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

  const isFirstUpdate = useRef(true);

  const select = useSelector(state => {
    return {
      audit: state.audit && state.audit.audit.data,
      isLoading: state.audit && state.audit.isLoading,
    };
  });

  const handleDateChange = value => {
    let customDate = '';
    let dateValue = '';

    if (value === 'fromDate') {
      customDate = document.getElementById('from-date-picker-audit');
      dateValue = customDate.value;
      setFromDate(dateValue);
    }

    if (value === 'toDate') {
      customDate = document.getElementById('to-date-picker-audit');
      dateValue = customDate.value;
      setToDate(dateValue);
    }
  };

  const handlePageClick = data => {
    let selected = data.selected + 1;
    setPageNo(selected);
  };

  // search table
  const handleSearch = e => {
    e.preventDefault();
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleSelectRow = e => {
    e.preventDefault();
    const { value } = e.target;
    setSelectedRow(value);
  };

  const search = rows => {
    const columns = ['SectionId', 'Action', 'CreatedBy', 'TimeStamp'];
    if (!selectedRow) {
      return rows.filter(row =>
        columns.some(column => {
          return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
        })
      );
    }
    return rows.filter(row => row[`${selectedRow}`].toString().toLowerCase().indexOf(searchTerm) > -1);
  };

  // search in endpoint
  const searchAudit = () => {
    dispatch(fetchAudit({ pageSize, pageNo, fromDate, toDate }));
  };

  const viewAuditInfo = details => {
    history.push(ViewAuditInfoPage(details.Id));
    dispatch(auditDetails(details));
  };

  // for updating page Number
  useEffect(() => {
    if (!isFirstUpdate.current) {
      searchAudit();
    }
  }, [pageNo]);

  useEffect(() => {
    isFirstUpdate.current = false;
    dispatch(fetchAudit({ pageSize, pageNo, fromDate, toDate }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className='row audit-container'>
      <div className='col'>
        <div className='content-header d-flex justify-content-between'>
          <span>Audit</span>
        </div>

        <div className='card card-content'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='col-lg-4 col-md-12 col-sm-12'>
                <div className='row'>
                  {/* <div className="col-7"> */}
                  <div className='sp-pt--48'>
                    <div className='input'>
                      <input className='input__text' type='text' placeholder='Search' value={searchTerm} onChange={handleSearch} />
                      <button className='input__btn'>
                        <i className='ri-search-line'></i>
                      </button>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div className="col-3">
                                        <select
                                            className="input__text p-0"
                                            onChange={handleSelectRow}
                                            value={selectedRow}
                                        >
                                            <option value="">All</option>
                                            <option value="SectionId">Section</option>
                                            <option value="Action">Action</option>
                                            <option value="CreatedBy">Creator</option>
                                            <option value="TimeStamp">Date</option>
                                        </select>
                                    </div> */}
                </div>
              </div>
              <div className='col-lg-8 col-md-12 col-sm-12 datepicker-div'>
                <Datepicker
                  className='col-md-4 col-sm-5'
                  duration='Start&nbsp;Date:&nbsp;'
                  name='fromDate'
                  mode='single'
                  dateFormat='Y-m-d'
                  maxDate='today'
                  defaultDate={fromDate}
                  id='from-date-picker-audit'
                  onChange={() => handleDateChange('fromDate')}
                />

                <Datepicker
                  className='col-md-4 col-sm-5 adjust-left'
                  duration='End&nbsp;Date:&nbsp;'
                  name='toDate'
                  mode='single'
                  dateFormat='Y-m-d'
                  maxDate='today'
                  minDate={fromDate}
                  id='to-date-picker-audit'
                  defaultDate={fromDate}
                  onChange={() => handleDateChange('toDate')}
                />

                <span className='col-md-4 col-sm-2 btn-div'>
                  <Button size='sm' color='green' handleClick={searchAudit}>
                    Search
                  </Button>
                </span>
              </div>
            </div>

            <div className='row table-container'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Section</th>
                    <th>Action</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {select.isLoading ? (
                  <ShimmerTableRowLoader rows={pageSize} columnWidths={['5%', '15%', '15%', '25%', '25%', '15%']} />
                ) : (
                  <tbody>
                    {select.audit &&
                      (search(select.audit.Data).length ? (
                        search(select.audit.Data).map((value, i) => {
                          return (
                            <tr key={value.Id}>
                              {pageNo > 1 ? <td width='5%'>{(pageNo - 1) * 10 + (i + 1)}</td> : <td width='5%'>{i + 1}</td>}
                              <td width='15%'>{sectionSelect(value.SectionId)}</td>
                              <td width='15%'>{value.Action}</td>
                              <td width='25%'>{value.CreatedBy}</td>
                              <td width='25%'>{value.TimeStamp}</td>
                              <th width='15%'>
                                <span className='green-color cursor mr-2' onClick={() => viewAuditInfo(value)}>
                                  View Details
                                </span>
                              </th>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan='5' className='font-weight-bold text-center pt-4'>
                            No data available
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </Table>

              <Paginator currentPage={pageNo} perPage={pageSize} pageCount={select.audit?.Paging?.PageCount} totalItems={select.audit?.Paging?.TotalRecordCount} handlePageClick={handlePageClick} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Audit;
