import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { Table } from "reactstrap";

import { fetchScopes, scopeDetails, createScope } from "../../store/scopes/scopes-actions";
import { LoadingTableContent } from "../shared/loader/Loader";
import { Urls, ViewScopePage, EditScopePage } from "../../utils/urls";
import { Colors } from "../../utils/colors";
import Button from "../shared/button/Button";
import PreviewScope from "./previewScope/previewScope";
import OtpModal from "../shared/otpModal/otpModal";
import ShowToast from "../../utils/showToast";
import { useTitle } from "../../utils/useTitle";

import "./scopes.scss";

const Scopes = () => {
  useTitle("Scopes");
  const history = useHistory();
  const dispatch = useDispatch();
  const inputFileEl = useRef(null);

  const [modalShow, setModalShow] = useState(false);
  const [preview, setPreview] = useState({});
  const [otpShow, setOtpShow] = useState(false);

  const select = useSelector((state) => {
    return {
      scopes: state.scopes && state.scopes.scopes.data,
      isLoading: state.scopes && state.scopes.isLoading,
    };
  });

  const onSelectFile = () => {
    inputFileEl.current.click();
  };

  const viewScope = (details) => {
    history.push(ViewScopePage(details.Id));
    dispatch(scopeDetails(details));
  };

  const editScope = (details) => {
    history.push(EditScopePage(details.Id));
    dispatch(scopeDetails(details));
  };

  const newScope = () => {
    history.push(Urls.CreateScope);
    dispatch(scopeDetails(null));
  };

  const getScopeData = () => {
    var file = document.querySelector("#import-scope").files[0];
    var reader = new FileReader(); // File reader to read the file

    // This event listener will happen when the reader has read the file
    reader.addEventListener("load", function () {
      var result = JSON.parse(reader.result); // Parse the result into an object
      if (result && result["Name"] && result["Id"]) {
        setPreview({ scopes: result });
        setModalShow(true);
      } else {
        ShowToast({
          type: "error",
          message: "This is not a scope file format",
        });
      }
    });
    reader.readAsText(file); // Read the uploaded file
  };

  useEffect(() => {
    dispatch(fetchScopes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (select.scopes && select.scopes.Response.length > 0) {
      $(document).ready(function () {
        $("#scope-table").DataTable();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.scopes && select.scopes.Response]);

  return (
    <section className="row scope-container">
      {preview && <PreviewScope openModal={modalShow} closeModal={() => setModalShow(false)} select={preview} action={() => setOtpShow(true)} setModalShow={setModalShow} />}
      <OtpModal openModal={otpShow} closeModal={() => setOtpShow(false)} onValidateOtp={() => dispatch(createScope(preview && preview.scopes))} />
      <div className="col">
        <div className="content-header d-flex justify-content-between">
          <span className="mr-3">Scopes</span>
          <div>
            <input type="file" ref={inputFileEl} className="custom-file-input" accept="application/JSON" onChange={getScopeData} id="import-scope" />
            <Button
              size="sm"
              outline={true}
              color={Colors.GREEN}
              handleClick={() => {
                onSelectFile();
              }}
              className="mr-2 button-action"
            >
              Import Scope
            </Button>
            <Button size="sm" color={Colors.GREEN} handleClick={newScope} className="button-action">
              Add New Scope
            </Button>
          </div>
        </div>
        <div className="card card-content">
          <div className="card-body">
            <div className="row table-container">
              <Table responsive id="client-table" id="scope-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Display Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {!select.scopes && select.isLoading && <LoadingTableContent colSpan={5} />}
                <tbody>
                  {select.scopes &&
                    select.scopes.Response.map((value, i) => {
                      return (
                        <tr key={value.Id}>
                          <th>{i + 1}</th>
                          <td>{value.Name}</td>
                          <td>{value.DisplayName}</td>
                          <td>{value.Type}</td>
                          <td>
                            <span className="green-color cursor mr-2" onClick={() => viewScope(value)}>
                              View
                            </span>
                            <span className="green-color cursor" onClick={() => editScope(value)}>
                              Edit
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Scopes;
