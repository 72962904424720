import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ClearanceEditor from "./clearanceEditor/clearanceEditor";
import { Button } from "../../../../shared";

import "./clearanceEditorModal.scss";
import { AddUserClearance, FetchApplicationList, SetActionFor } from "../../../../../store/user/user-actions";
import { Spinner } from "reactstrap";
import OtpModal from "../../../../shared/otpModal/otpModal";
import ShowToast from "../../../../../utils/showToast";

const ClearanceEditorModal = ({ openModal, closeModal, Userid, setTabKey, userClearance }) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});
  const [show, setShow] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [disableSave, setDisableSave] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { fetchingApplicationList, applicationList, processing, actionFor } = useSelector(state => state.user);

  const handleFormValues = (title, myValue) => {
    setFormValues({
      ...formValues,
      [title[0]]: myValue[0],
      [title[1]]: myValue[1],
    });
  };

  const handleSubmitClearance = () => {
    const selected = userClearance.find(c => c.ClientKey === formValues.ClientKeys[0]);
    if (selected) {
      ShowToast({ type: "warning", message: `User already has clearance to ${selected.ClientName}` });
      return;
    }
    dispatch(SetActionFor("clearance"));
    setShowOtpModal(true);
  };

  const onValidateOtp = () => {
    if (actionFor === "clearance") {
      dispatch(AddUserClearance({ formValues, closeModal, setTabKey }));
    }
  };

  useEffect(() => {
    dispatch(FetchApplicationList());
  }, []);

  useEffect(() => {
    openModal ? handleShow() : handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    if (!formValues.Userid) {
      setFormValues({ ...formValues, Userid, Enabled: true });
    }
    setDisableSave(!formValues?.ClientKeys?.length);
  }, [formValues]);

  return (
    <>
      <OtpModal top openModal={showOtpModal} closeModal={() => setShowOtpModal(false)} onValidateOtp={onValidateOtp} />
      <Modal show={show} onHide={closeModal} className="application-modal-container">
        <Modal.Header closeButton>New Clearance</Modal.Header>
        <Modal.Body>
          <ClearanceEditor title="ClientIds" fieldTitle="ClientIds" label="Select Client" value="" handleFormValues={handleFormValues} list={applicationList} loading={fetchingApplicationList} />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button outline={true} color="orange" handleClick={closeModal} className="mr-2">
              Cancel
            </Button>
            <Button color="green" handleClick={handleSubmitClearance} disabled={disableSave}>
              {processing ? (
                <span>
                  Saving... <Spinner size="sm" />
                </span>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClearanceEditorModal;
