import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useDispatch, useSelector } from 'react-redux';

import OtpModal from '../../shared/otpModal/otpModal';
import {updateProfile } from '../../../store/profile/profile-action';
import { Colors } from '../../../utils/colors';
import Button from "../../shared/button/Button";

import './profileForm.scss';

const ProfileForm = ({ openModal, closeModal, details }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [form, setForm] = useState({});
    const [createdStatus, setCreatedStatus] = useState(false);
    const [otpShow, setOtpShow] = useState(false);
    const [payload, setPayload] = React.useState(null);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const select = useSelector((state) => {
        return {
            status: state.profile && state.profile.profileUpdate && state.profile.profileUpdate.status,
            isLoading: state.profile && state.profile.isUpdating,
        }
    })

    const submitForm = (event, value) => {
        setCreatedStatus(true)
        setPayload({...details, ...value});
        setOtpShow(true);
    }

    useEffect(() => {
        openModal ? handleShow() : handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal])

    useEffect(() => {
        if (createdStatus && select.status && select.status === 200) {
            console.log({select12: select})
            setCreatedStatus(false)
            closeModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [select.status])

    return (
        <>
            <OtpModal
                openModal={otpShow}
                closeModal={() => setOtpShow(false)}
                onValidateOtp={() => dispatch(updateProfile(payload))}
            />
            <Modal
                centered
                show={show}
                onHide={closeModal}
                className="application-modal-container">
                <Modal.Header closeButton>
                   Profile Form
                </Modal.Header>
                <Modal.Body>
                    <AvForm onValidSubmit={submitForm} ref={c => (setForm(c))} model={details}>
                        <div className="row">
                            <div className="form-group col-12 otp-field">
                                <label htmlFor="firstName">First Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    name="FirstName"
                                    placeholder="Enter First name"
                                    validate={{
                                        pattern: {value: '^[A-Za-z]+$', errorMessage: 'Your name must be letter'},
                                        required: { value: true, errorMessage: 'Please enter First name' },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-12 otp-field">
                                <label htmlFor="LastName">Last Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    id="LastName"
                                    name="LastName"
                                    placeholder="Enter Last name"
                                    validate={{
                                        pattern: {value: '^[A-Za-z]+$', errorMessage: 'Your name must be letter'},
                                        required: { value: true, errorMessage: 'Please enter Last name' },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-12 otp-field">
                                <label htmlFor="PhoneNumber">Phone number</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    id="PhoneNumber"
                                    name="PhoneNumber"
                                    placeholder="Enter Phone number"
                                    validate={{
                                        pattern: {value: '^[0-9]+$', errorMessage: 'Your name must be numbers'},
                                        required: { value: true, errorMessage: 'Please enter Phone number' },
                                    }}
                                />
                            </div>
                        </div>
                    </AvForm>
                    <div className="d-flex justify-content-end pr-3">
                      <Button
                            size="sm"
                            outline={true}
                            color={Colors.GREEN}
                            handleClick={() => {closeModal()}}
                            className="mr-2"
                        >
                           Cancel
                         </Button>
                        <Button size="sm" color={Colors.GREEN} handleClick={() => form.submit()} loading={select.isLoading}  disabled={select.isLoading}>
                            Create
                        </Button>
                    </div>
                  </Modal.Body>
            </Modal>
        </>
    );
}

export default ProfileForm;