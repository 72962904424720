import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, MakerChip, ProgressBar } from "../../shared";
import "./defineWorkflowSecondStep.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateCheckerLevels,
  CreateMakerClaim,
  CreatingCheckerLevels,
  FetchWorkflowDetails,
  FetchWorkflowLevels,
} from "../../../store/maker-checker/maker-checker-actions";
import Chips from "react-chips/lib/Chips";
import BreadCrumb from "../../shared/breadCrumb/breadCrumb";
import { Urls } from "../../../utils/urls";
import ShowToast from "../../../utils/showToast";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import InfoTip from "../../shared/infoTip/infoTip";
import OtpModal from "../../shared/otpModal/otpModal";
import { useTitle } from "../../../utils/useTitle";

const DefineWorkflowSecondStep = ({ match: { params } }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    claimCreated,
    workflow,
    workflowClaim,
    creatingLevels,
    creatingClaim,
    workflowLevels,
  } = useSelector((state) => state.makerChecker);

  const [claimForm, setClaimForm] = useState([]);
  const [workFlowCode, setWorkflowCode] = useState("");
  const [claimValue, setClaimValue] = useState("");
  const [claimType, setClaimType] = useState("");
  const [chips, setChips] = useState([]);
  const [actionType, setActionType] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [isCheckerLevel, setIsCheckerLevel] = useState(false);
  const submittedClaim = useRef(false);
  useTitle(
    `Maker-Checker ${
      workflowLevels && workflowLevels.length ? "Edit" : "Create"
    } Workflow Step Two`
  );

  // const authorizersInputs = (start, end) => {
  //   return Array(end - start + 1)
  //     .fill()
  //     .map((_, idx) => ({ emails: [], levelNo: start + idx }));
  // };

  const authorizersInputs = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => {
        const levelemails =
          workflowLevels && workflowLevels.length
            ? workflowLevels
                .filter((v) => v.levelNo === start + idx)
                .map((obj) => obj.email)
            : [];

        return { emails: [...levelemails], levelNo: start + idx };
      });
  };

  const checkFormValidity = () => {
    return chips.map((chip) => !!chip.emails.length);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setClaimType(value);
  };

  const handleCreateLevels = (e) => {
    e.preventDefault();
    setIsCheckerLevel(true);
    setShowOtp(true);
  };

  const handleSubmitClaim = () => {
    if (!claimForm.hasError()) {
      setActionType("add");
      setIsCheckerLevel(false);
      setShowOtp(true);
    } else {
      ShowToast({
        type: "error",
        message: `Fill out required fields.`,
      });
    }
  };

  const handleRemoveClaim = () => {
    if (!claimForm.hasError()) {
      setActionType("remove");
      setIsCheckerLevel(false);
      setShowOtp(true);
    } else {
      ShowToast({
        type: "error",
        message: `Fill out required fields.`,
      });
    }
  };

  const onValidateOtp = () => {
    if (isCheckerLevel) {
      onValidateAuthorizersOtp();
    } else {
      claimForm.submit();
    }
  };

  const onValidateAuthorizersOtp = () => {
    const data = {
      workFlowCode,
      authorizers: chips,
    };
    if (!checkFormValidity().includes(false)) {
      dispatch(
        CreateCheckerLevels({
          data,
          history,
          inEditMode: !!workflowLevels,
          applicationCode: params.clientId,
        })
      );
      return;
    }
    ShowToast({
      type: "error",
      message: `Each checker level must have at least one email address.`,
    });
  };

  const submitClaimForm = (e, formData) => {
    console.log("form data", formData);
    console.log("event", e);
    const formValue = { ...formData, workFlowCode };
    dispatch(
      CreateMakerClaim({
        formValue,
        applicationCode: params.clientId,
        actionType,
        claimForm,
      })
    );
  };
  const removeChip = (index) => {
    console.log(index);
  };

  useEffect(() => {
    initialStoreDispatches();
    setWorkflowCode(params.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(CreatingCheckerLevels(false));
    };
  }, []);

  useEffect(() => {
    if (claimCreated) {
      setChips(authorizersInputs(1, workflow?.level));
      submittedClaim.current = claimCreated;
    }
    if (workflowClaim) {
      setClaimValue(workflowClaim.claimValue);
      setClaimType(workflowClaim.claimType);
      if (workflow && workflow.level) {
        setChips(authorizersInputs(1, workflow.level));
      }
      submittedClaim.current = !!workflowClaim;
    } else {
      setClaimValue(null);
      setClaimType(null);
    }
    return () => {
      submittedClaim.current = false;
    };
  }, [claimCreated, workflow, workflowClaim]);

  const initialStoreDispatches = () => {
    dispatch(
      FetchWorkflowDetails({
        workflowCode: params.code,
        applicationCode: params.clientId,
      })
    );
    dispatch(
      FetchWorkflowLevels({
        applicationCode: params.clientId,
        workflowCode: params.code,
      })
    );
    dispatch(CreatingCheckerLevels(false));
  };

  const disableButton = () => {
    return claimForm && claimForm.hasError();
  };
  const updateProgressBar = () => {
    if (submittedClaim.current && workflowLevels && !workflowLevels.length) {
      return 70;
    } else if (submittedClaim.current && !workflowLevels) {
      return 70;
    } else if (
      submittedClaim.current &&
      workflowLevels &&
      workflowLevels.length
    ) {
      return 100;
    }
    return 30;
  };

  const handleEmailsChange = (e, field) => {
    setChips(
      chips.map((item) => {
        if (item.levelNo === field.levelNo) {
          return { ...item, emails: e };
        } else {
          return item;
        }
      })
    );
  };

  const handleBackClicked = (e) => {
    e.preventDefault();
    history.push(Urls.MakerChecker);
  };

  return (
    <section className="row define-second-step-workflow-container">
      <OtpModal
        openModal={showOtp}
        closeModal={() => setShowOtp(false)}
        onValidateOtp={() => onValidateOtp()}
      />
      <div className="col">
        <BreadCrumb
          title="Maker Checker"
          titleLink={Urls.MakerChecker}
          sub1={`${
            workflowLevels && workflowLevels.length ? "Edit" : "Create"
          } Workflow Step 2`}
        />
        <div className="content-header d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <a href="!#/" onClick={handleBackClicked} className="mr-2 goback">
              <i className="ri-arrow-left-line"></i>
            </a>{" "}
            <div className="sp-disp-heading">{`${
              workflowLevels && workflowLevels.length ? "Edit" : "Create"
            } Workflow`}</div>
          </div>
          <div>
            <Button
              size="sm"
              outline={true}
              color="green"
              handleClick={handleBackClicked}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="green"
              handleClick={handleCreateLevels}
              loading={creatingLevels}
            >
              {`Complete ${
                workflowLevels && workflowLevels.length ? "Update" : "Setup"
              }`}
            </Button>
          </div>
        </div>
        <ProgressBar bgColor="#31712D" completed={updateProgressBar()} />
        <div className="card card-content mb-2">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-6">
                <div className="sp-section-title d-inline">Maker Claim</div>
                <InfoTip infoText="Select the specific User claim you want to give authority to initiate this workflow. All Users that possess that claim will henceforth have the ability to initiate transactions." />
              </div>
              <div className="col-6 text-right">
                <Button
                  size="sm"
                  outline={true}
                  loading={creatingClaim}
                  color={claimCreated || !!workflowClaim ? "red" : "green"}
                  // disabled={disableButton()}
                  handleClick={
                    claimCreated || !!workflowClaim
                      ? handleRemoveClaim
                      : handleSubmitClaim
                  }
                >
                  {claimCreated || !!workflowClaim ? "Remove" : "Submit"}
                </Button>
              </div>
            </div>

            <AvForm
              onValidSubmit={submitClaimForm}
              ref={(c) => setClaimForm(c)}
              className="row mb-3"
            >
              <div className="col-sm-12 col-md-6 col-lg-4">
                <label>Type</label>
                {/* <AvField
                  type='select'
                  className='form-control'
                  disabled={claimCreated || !!workflowClaim}
                  name='claimType'
                  id='claimType'
                  onChange={handleInputChange}
                  validate={{
                    required: { value: true, errorMessage: 'Please select one' },
                  }}
                >
                  <option></option>
                  <option value={`${params.clientId}.permission`}>{`${params.clientId}.Permission`}</option>
                  <option value={`${params.clientId}.role`}>{`${params.clientId}.Role`}</option>
                </AvField> */}
                {console.log(workflowClaim)}
                <AvField
                  className="form-control"
                  name="claimType"
                  disabled={claimCreated || !!workflowClaim}
                  id="claimType"
                  value={claimType}
                  // onChange={handleInputChange}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a claim type for maker",
                    },
                  }}
                ></AvField>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <label>Value</label>
                <AvField
                  className="form-control"
                  name="claimValue"
                  disabled={claimCreated || !!workflowClaim}
                  id="claimValue"
                  value={claimValue}
                  // onChange={handleInputChange}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a claim value for maker",
                    },
                  }}
                ></AvField>
              </div>
            </AvForm>
          </div>
        </div>
        {submittedClaim.current && chips.length ? (
          <div className="card card-content mb-3">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-4">
                  <div className="sp-section-title">Checker Levels</div>
                </div>
              </div>
              {authorizersInputs(1, workflow ? workflow.level : 1).map(
                (field, idx) => (
                  <div className="row mb-3" key={field.levelNo}>
                    <div className="col-8">
                      <form>
                        <div className="sp-pt--48">
                          <div
                            className="input"
                            title="use comma, space or enter to separate emails"
                          >
                            <label className="input__label">
                              Level {field.levelNo} Checkers
                            </label>
                            <Chips
                              value={chips[idx]?.emails}
                              onChange={(value) =>
                                handleEmailsChange(value, field)
                              }
                              suggestions={[]}
                              onRemove={removeChip}
                              renderChip={(value, selected, onRemove) => (
                                <MakerChip handleRemove={onRemove}>
                                  {value}
                                </MakerChip>
                              )}
                              createChipKeys={[13, 188, 32]}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default DefineWorkflowSecondStep;
