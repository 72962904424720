import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import _debounce from "lodash.debounce";
import { Select } from "antd";
import "./transactions.scss";
import { Button, Paginator, ShimmerTableRowLoader } from "../../shared";
import { FetchClientData, FetchTransactionData, FetchTransactionDataSuccess, FetchWorkflowList } from "../../../store/maker-checker/maker-checker-actions";
import CommentModal from "./commentModal/commentModal";
import { useTitle } from "../../../utils/useTitle";

const Transactions = () => {
  useTitle("Maker-Checker Transactions");
  const { Option } = Select;
  const dispatch = useDispatch();
  const { transactions, fetchingTransactions, clientData, workflowList, fetchingWorkflowList, fetchingClient, processing } = useSelector((state) => state.makerChecker);
  const [searchTerm, setSearchTerm] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [selectedRow, setSelectedRow] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [transactionReference, setTransactionReference] = useState("");

  const handlePageClick = (data) => {
    let selected = data.selected;
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    dispatch(FetchClientData({ type: "all", pageSize: 10 }));
    FetchTransactionDataSuccess([]);
  }, []);

  const handleApprove = (e, record) => {
    e.preventDefault();
    setActionType("approve");
    setTransactionReference(record);
  };

  const handleReject = (e, record) => {
    e.preventDefault();
    setActionType("reject");
    setTransactionReference(record);
  };

  useEffect(() => {
    if (actionType) {
      setShowCommentModal(true);
    }
  }, [actionType]);

  const handleItemsPerPage = (value) => {
    setPerPage(value);
  };

  const handleClientChange = (client) => {
    setSelectedClient(client);
    dispatch(FetchWorkflowList({ applicationCode: client }));
  };

  const handleWorkflowChange = (workflow) => {
    setSelectedWorkflow(workflow);
  };

  const handleLoadTransactions = () => {
    if (loggedInUser.profile) {
      const email = loggedInUser.profile.email;
      dispatch(
        FetchTransactionData({
          workflowCode: selectedWorkflow,
          email,
          applicationCode: selectedClient,
        })
      );
    }
  };

  const handleCloseModal = () => {
    setActionType(null);
    setShowCommentModal(false);
  };
  const handleFilter = (input, option) => {
    return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const search = (rows) => {
    const columns = ["entityType", "module", "transactionReference", "workflowApprovalStatus"];
    if (!selectedRow) {
      return rows.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf(searchTerm) > -1));
    }
    return rows.filter((row) => row[`${selectedRow}`].toString().toLowerCase().indexOf(searchTerm) > -1);
  };

  const lastItemIndex = currentPage * perPage;
  const firstItemIndex = lastItemIndex - perPage;
  const currentItems = transactions?.length ? transactions.slice(firstItemIndex, lastItemIndex) : [];
  return (
    <section className="row transaction-container">
      <CommentModal
        openModal={showCommentModal}
        closeModal={handleCloseModal}
        currentUser={loggedInUser?.profile}
        transactionReference={transactionReference}
        applicationCode={selectedClient}
        processing={processing}
        approvalType={actionType}
        email={loggedInUser?.profile.email}
        selectedClient={selectedClient}
        selectedWorkflow={selectedWorkflow}
      />
      <div className="col">
        <div className="d-flex flex-column justify-content-center align-items-end  flex-lg-row justify-content-lg-between align-items-lg-center">
          <div className="sp-disp-heading align-self-start mb-3 mb-lg-0 font-weight-bold">Workflow Transactions</div>
          <div className="d-flex flex-column-reverse flex-lg-row justify-content-end align-items-center">
            <div className="d-flex flex-column flex-lg-row justify-content-end align-items-center">
              <div style={{ width: "280px", maxWidth: "280px" }} className="mr-0 mr-lg-2 mb-2 mb-lg-0 client-select">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  loading={fetchingClient}
                  placeholder={fetchingClient ? "Loading Applications..." : "Select Application"}
                  optionFilterProp="children"
                  onChange={handleClientChange}
                  // onSearch={onSearch}
                  filterOption={handleFilter}
                >
                  {clientData &&
                    clientData.map((client, idx) => (
                      <Option key={idx} value={client.value}>
                        {client.label}
                      </Option>
                    ))}
                </Select>
              </div>
              <div style={{ width: "280px", maxWidth: "280px" }} className="mr-0 mr-lg-2 mb-0 workflow-select">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  loading={fetchingWorkflowList}
                  placeholder={fetchingWorkflowList ? "Loading Workflows..." : "Select Workflow"}
                  optionFilterProp="children"
                  onChange={handleWorkflowChange}
                  // onSearch={onSearch}
                  filterOption={handleFilter}
                >
                  {workflowList &&
                    workflowList.map((workflow, idx) => (
                      <Option key={idx} value={workflow.value}>
                        {workflow.label}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
            <div className="justify-self-end align-self-end align-self-lg-center justify-self-lg-center mt-2 mt-lg-0 mb-2 mb-lg-0">
              <Button size="sm" color="green" handleClick={handleLoadTransactions} disabled={!selectedWorkflow}>
                Load Transactions
              </Button>
            </div>
          </div>
        </div>
        <div className="card card-content">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-12 d-flex align-items-end justify-content-between mb-3 mb-lg-0 px-0">
                <div className="sp-section-title">Transactions</div>
                <div className="entry-number d-flex flex-column flex-lg-row align-items-start justify-content-center align-items-lg-center justify-content-lg-end">
                  <span className="mr-2 showing">showing:</span>
                  <div className="" style={{ width: 142 }}>
                    <Select showSearch style={{ width: "100%" }} placeholder="Select a Client" optionFilterProp="children" onChange={handleItemsPerPage} value={perPage}>
                      <Option value={5}>5 Entries</Option>
                      <Option value={10}>10 Entries</Option>
                      <Option value={15}>15 Entries</Option>
                      <Option value={20}>20 Entries</Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row table-container">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Module</th>
                    <th>Reference</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {fetchingTransactions ? (
                  <ShimmerTableRowLoader rows={perPage} columnWidths={["20%", "15%", "25%", "15%", "25%"]} />
                ) : (
                  <tbody>
                    {search(currentItems).length ? (
                      search(currentItems).map((value, i) => {
                        return (
                          <tr key={value.transactionReference}>
                            <td width="20%">{value.entityType}</td>
                            <td width="15%">{value.module}</td>
                            <td width="25%">{value.transactionReference}</td>
                            <td width="10%">{value.transactionStatusDescription}</td>
                            <td width="30%">
                              {value.transactionStatusDescription === "Pending" && (
                                <a href="#/" className="action mr-3" onClick={(e) => handleApprove(e, value.transactionReference)}>
                                  Approve
                                </a>
                              )}
                              {(value.transactionStatusDescription === "Pending" || value.transactionStatusDescription === "Approved") && (
                                <a href="#/" className="action d-block d-lg-inline text-danger" onClick={(e) => handleReject(e, value.transactionReference)}>
                                  Reject
                                </a>
                              )}
                              {value.transactionStatusDescription !== "Pending" && value.transactionStatusDescription !== "Approved" && "-"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="5" className="font-weight-bold text-center pt-4">
                          Not data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </Table>
            </div>
            <Paginator currentPage={currentPage} perPage={perPage} pageCount={Math.ceil(transactions?.length / perPage)} totalItems={transactions?.length} handlePageClick={handlePageClick} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Transactions;
