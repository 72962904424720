export const ProfileActionTypes = {
  FETCH_PROFILE: "FETCH_PROFILE",
  FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  PASSWORD_CHANGE: "PASSWORD_CHANGE",
  PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
};

export const fetchProfile = (action) => ({
  type: ProfileActionTypes.FETCH_PROFILE,
  payload: action,
});

export const fetchProfileSuccess = (payload) => ({
  type: ProfileActionTypes.FETCH_PROFILE_SUCCESS,
  payload: payload,
});

export const updateProfile = (payload) => ({
  type: ProfileActionTypes.UPDATE_PROFILE,
  payload
});

export const updateProfileSuccess = (payload) => ({
  type: ProfileActionTypes.UPDATE_PROFILE_SUCCESS,
  payload: payload,
});

export const passwordChange = (payload) => ({
  type: ProfileActionTypes.PASSWORD_CHANGE,
  payload
});

export const passwordChangeSuccess = (payload) => ({
  type: ProfileActionTypes.PASSWORD_CHANGE_SUCCESS,
  payload: payload,
});
