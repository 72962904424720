export const ScopesActionTypes = {
  FETCHING_DATA: "FETCHING_SCOPES",
  FETCHING_SCOPE_SUCCESS: "FETCHING_SCOPE_SUCCESS",
  FETCHING_SCOPE_ERROR: "FETCHING_SCOPE_ERROR",
  SCOPE_DETAILS: "SCOPE_DETAILS",
  CREATE_SCOPE: "CREATE_SCOPE",
  CREATE_SCOPE_SUCCESS: "CREATE_SCOPE_SUCCESS",
  UPDATE_SCOPE: "UPDATE_SCOPE",
  UPDATE_SCOPE_SUCCESS: "UPDATE_SCOPE_SUCCESS",
  NEW_SCOPE_RESET: "NEW_SCOPE_RESET"
};

export const fetchScopes = () => ({
  type: ScopesActionTypes.FETCHING_DATA,
});

export const fetchScopesSuccess = (payload) => ({
  type: ScopesActionTypes.FETCHING_SCOPE_SUCCESS,
  payload: payload,
});

export const scopeDetails = (payload) => ({
  type: ScopesActionTypes.SCOPE_DETAILS,
  payload: payload,
});

export const createScope = (payload) => ({
  type: ScopesActionTypes.CREATE_SCOPE,
  payload: payload
});

export const createScopeSuccess = (payload) => ({
  type: ScopesActionTypes.CREATE_SCOPE_SUCCESS,
  payload: payload,
});

export const updateScope = (id, payload) => ({
  type: ScopesActionTypes.UPDATE_SCOPE,
  payload: { id, payload }
});

export const updateScopeSuccess = (payload) => ({
  type: ScopesActionTypes.UPDATE_SCOPE_SUCCESS,
  payload: payload,
});

export const resetNewCreatedScope = () => ({
  type: ScopesActionTypes.NEW_SCOPE_RESET
})