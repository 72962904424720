import { all, call, put, takeEvery } from "redux-saga/effects";
import { ssoApi } from "../../axios/interceptor";
import ShowToast from "../../utils/showToast";

import {
  ClientsActionTypes,
  fetchClientsSuccess,
  createClientSuccess,
  updateClientSuccess,
  stopLoader,
  addClientAuthorizationSuccess,
  enableClientAuthorizationSuccess,
  disableClientAuthorizationSuccess,
  listClientAuthorizationSuccess,
} from "./clients-actions";

export function* addClientAuthorizationAsync(action) {
  try {
    const response = yield call(ssoApi.post, "/authorization/add", action.payload);
    if (!response.data.isError) {
      yield ShowToast({
        type: "success",
        message: "Client authorization added successfully",
      });
    }
    yield put(addClientAuthorizationSuccess(response));
  } catch (err) {
    yield put(stopLoader());
  }
}

export function* WatchAddAuthorizationflow() {
  yield takeEvery(ClientsActionTypes.ADD_AUTHORIZATION_PROCESSING, addClientAuthorizationAsync);
}

export function* enabledClientAuthorizationAsync(action) {
  try {
    const response = yield call(ssoApi.post, "/authorization/enable", action.payload);
    if (!response.data.isError) {
      yield ShowToast({
        type: "success",
        message: "Client authorization enabled successfully",
      });
    }
    yield put(enableClientAuthorizationSuccess(response));
  } catch (err) {
    yield put(stopLoader());
  }
}

export function* WatchEnableAuthorizationflow() {
  yield takeEvery(ClientsActionTypes.ENABLE_AUTHORIZATION_PROCESSING, enabledClientAuthorizationAsync);
}

export function* disabledClientAuthorizationAsync(action) {
  try {
    const response = yield call(ssoApi.post, "/authorization/disable", action.payload);
    if (!response.data.isError) {
      yield ShowToast({
        type: "success",
        message: "Client authorization disabled successfully",
      });
    }
    yield put(disableClientAuthorizationSuccess(response));
  } catch (err) {
    yield put(stopLoader());
  }
}

export function* WatchDisableAuthorizationflow() {
  yield takeEvery(ClientsActionTypes.DISABLE_AUTHORIZATION_PROCESSING, disabledClientAuthorizationAsync);
}

export function* listClientAuthorizationAsync(action) {
  try {
    const response = yield call(ssoApi.get, "/authorization/list");
    yield put(listClientAuthorizationSuccess(response));
  } catch (err) {
    yield put(stopLoader());
  }
}

export function* WatchListAuthorizationflow() {
  yield takeEvery(ClientsActionTypes.LIST_AUTHORIZATION_PROCESSING, listClientAuthorizationAsync);
}

export function* FetchClientsAsync(action) {
  try {
    const { PageNo, PageSize, clientName } = action;
    const clientSearch = clientName && clientName !== "" ? `&clientName=${clientName}` : "";
    let response;
    if (PageNo && PageSize) {
      response = yield call(ssoApi.get, `/client/list/v2?pageNo=${PageNo}&pageSize=${PageSize}${clientSearch}`);
    } else {
      response = yield call(ssoApi.get, `/client/list`);
    }

    yield put(fetchClientsSuccess(response));
  } catch (err) {}
}

export function* CreateClientAsync(action) {
  try {
    const response = yield call(ssoApi.post, "/client/create", action.payload);
    if (!response.data.isError) {
      yield ShowToast({
        type: "success",
        message: "Record saved successfully",
      });
    }
    yield put(createClientSuccess(response));
  } catch (err) {
    yield put(stopLoader());
  }
}

export function* WatchFetchClientsflow() {
  yield takeEvery(ClientsActionTypes.FETCHING_DATA, FetchClientsAsync);
}

export function* WatchCreateClientflow() {
  yield takeEvery(ClientsActionTypes.CREATE_CLIENT, CreateClientAsync);
}

export function* UpdateClientAsync(action) {
  try {
    const response = yield call(ssoApi.post, `/client/update?id=${action.payload.id}`, action.payload.payload);
    if (!response.data.isError) {
      yield ShowToast({
        type: "success",
        message: "Record updated successfully",
      });
    }
    yield put(updateClientSuccess(response));
  } catch (err) {}
}

export function* WatchUpdateClientflow() {
  yield takeEvery(ClientsActionTypes.UPDATE_CLIENT, UpdateClientAsync);
}

export default function* clientSagas() {
  yield all([
    call(WatchFetchClientsflow),
    call(WatchCreateClientflow),
    call(WatchUpdateClientflow),
    call(WatchAddAuthorizationflow),
    call(WatchEnableAuthorizationflow),
    call(WatchDisableAuthorizationflow),
    call(WatchListAuthorizationflow),
  ]);
}
