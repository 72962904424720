import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import './claimRemoveModal.scss';
import { Button } from '../../../../shared';
import { RemoveUserClaims, SetActionFor } from '../../../../../store/user/user-actions';
import OtpModal from '../../../../shared/otpModal/otpModal';
import ClaimRemove from './claimRemove/claimRemove';

const ClaimRemoveModal = ({ openModal, closeModal, claimList, UserId }) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});

  const [show, setShow] = useState(false);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { fetchingClaimList, processing, actionFor } = useSelector(state => state.user);

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedClaims(prevState => [...prevState, row]);
    } else {
      setSelectedClaims(prevState => prevState.filter(x => x.Value !== row.Value && x.Type !== row.Type));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const value = rows.map(r => r);
    if (isSelect) {
      setSelectedClaims(prevState => [...prevState, value]);
    } else {
      setSelectedClaims([]);
    }
  };

  const onValidateOtp = () => {
    if (actionFor === 'removeClaim') {
      dispatch(RemoveUserClaims({ formValues, closeModal }));
    }
  };

  const handleFormValues = (title, myValue) => {
    setFormValues({ ...formValues, [title]: myValue });
  };

  const handleRemoveClaimSubmit = () => {
    dispatch(SetActionFor('removeClaim'));
    setShowOtpModal(true);
  };

  useEffect(() => {
    setFormValues({ ...formValues, UserId });
  }, []);

  useEffect(() => {
    openModal ? handleShow() : handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    const formatSelected = selectedClaims.map(({ id, ...v }) => v);
    handleFormValues('Claims', formatSelected);
    if (!formValues.UserId) {
      setFormValues({ ...formValues, UserId });
    }
  }, [selectedClaims]);
  return (
    <>
      <OtpModal top openModal={showOtpModal} closeModal={() => setShowOtpModal(false)} onValidateOtp={onValidateOtp} />
      <Modal size='lg' scrollable show={show} onHide={closeModal} className='claim-remove-modal-container'>
        <Modal.Header closeButton>Remove Claim</Modal.Header>
        <Modal.Body>
          <ClaimRemove claimList={claimList} closeModal={closeModal} loading={fetchingClaimList} handleOnSelect={handleOnSelect} handleOnSelectAll={handleOnSelectAll} />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button outline={true} color='orange' handleClick={closeModal} className='mr-2'>
              Cancel
            </Button>
            <Button
              color='red'
              handleClick={handleRemoveClaimSubmit}
              loading={processing}
              // disabled={!(formValues.ClientIds && formValues.ClientIds.length)}
            >
              Remove
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClaimRemoveModal;
