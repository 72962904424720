import React from 'react';
import { useHistory } from 'react-router-dom';

import './breadCrumb.scss';

const BreadCrumb = (props) => {
    const history = useHistory();
    const { title, titleLink, sub1 } = props;
    return (
        <div className="bread-crumb-container">
            <span className="title" onClick={() => history.push(titleLink)}> {title}</span>/<span className="sub-title">{sub1}</span>
        </div>
    )
}

export default BreadCrumb;