import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { Table } from 'reactstrap';
import BreadCrumb from '../../shared/breadCrumb/breadCrumb';
import { Urls } from '../../../utils/urls';
import { sectionSelect } from '../../shared/auditHelper/auditHelper';
import { useTitle } from '../../../utils/useTitle';

const ViewAuditInfo = () => {
  useTitle('Audit Details');
  const select = useSelector(state => {
    return { audit: state.audit && state.audit.details };
  });

  const handleBackClicked = e => {
    e.preventDefault();
    history.push(Urls.Audit);
  };

  const history = useHistory();
  if (!select.audit) {
    history.push(Urls.Audit);
    return null;
  }

  return (
    <section className='client-detail-container'>
      <div className='col'>
        <BreadCrumb title='Audit' titleLink={Urls.Audit} sub1='Audit Details' />
        <div className='content-header d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex justify-content-center align-items-center'>
            <a href='!#/' onClick={handleBackClicked} className='mr-2 goback'>
              <i className='ri-arrow-left-line'></i>
            </a>{' '}
            <div className='sp-disp-heading'>Audit Information</div>
          </div>
        </div>
        <div className='card card-content mb-3'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='col'>
                <Tabs id='controlled-tab-example' activeKey='Details'>
                  <Tab eventKey='Details' title='Details'>
                    <div className='user-info-container'>
                      <div className='row'>
                        <div className='col-sm-12 col-md-6'>
                          <Table responsive borderless>
                            {select.audit ? (
                              <tbody>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Section:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {sectionSelect(select.audit.SectionId) || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Action:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.audit.Action || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Created By:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.audit.CreatedBy || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Description:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.audit.Description || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Ip Address:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.audit.Ip || '---'}
                                  </td>
                                </tr>
                                <tr>
                                  <td width='30%' className='user-label'>
                                    Time Stamp:
                                  </td>
                                  <td width='70%' className='user-value'>
                                    {select.audit.TimeStamp || '---'}
                                  </td>
                                </tr>
                                {/* <tr>
                                                                    <td width='30%' className='user-label'>
                                                                        Status:
                                                                    </td>
                                                                    {select.accessLogs.IsSuccessful ?
                                                                        <td width='70%' className='user-value'>
                                                                            {select.accessLogs.IsSuccessful ? "Successful" : "Failed"}
                                                                        </td>
                                                                        :
                                                                        <td width='70%' className='user-value'>
                                                                            ---
                                                                    </td>
                                                                    }
                                                                </tr> */}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan='2' className='font-weight-bold text-center pt-4'>
                                    Not data available
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </Table>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewAuditInfo;
